import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
  },
});

// // 요청 전처리
// client.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // 응답 후처리
// client.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export default client;
