import { colors } from "../styles/colors";
import { useEvaluation } from "../hooks/useEvaluation";
import { useAutoForm } from "../hooks/useAutoForm";
import { useEffect, useState } from "react";

export default function SelfAnalysis({ index }) {
  const { jsonData } = useAutoForm();
  const { testQuestion } = useEvaluation();
  const [question, setQuestion] = useState(testQuestion);

  useEffect(() => {
    if (index > -1) setQuestion(jsonData[index].data);
  }, []);

  const CATEGORY_LIST = [
    {
      title: "정보수집방식",
      leftType: {
        alphabet: "I",
        kr: "직관형",
        en: "ntutive type",
        level: question[0]?.filter(
          (item) => item.value === "직관형" && item.answer === "true"
        )?.length,
        totalQuestion: question[0]?.filter((item) => item.value === "직관형")
          ?.length,
      },
      rightType: {
        alphabet: "E",
        kr: "경험형",
        en: "xperiential type",
        level: question[0]?.filter(
          (item) => item.value === "경험형" && item.answer === "true"
        )?.length,
        totalQuestion: question[0]?.filter((item) => item.value === "경험형")
          ?.length,
      },
    },
    {
      title: "능력표현방식",
      leftType: {
        alphabet: "D",
        kr: "설계형",
        en: "esign type",
        level: question[1]?.filter(
          (item) => item.value === "설계형" && item.answer === "true"
        )?.length,
        totalQuestion: question[1]?.filter((item) => item.value === "설계형")
          ?.length,
      },
      rightType: {
        alphabet: "T",
        kr: "기술형",
        en: "echnical type",
        level: question[1]?.filter(
          (item) => item.value === "기술형" && item.answer === "true"
        )?.length,
        totalQuestion: question[1]?.filter((item) => item.value === "기술형")
          ?.length,
      },
    },
    {
      title: "목표실행방식",
      leftType: {
        alphabet: "T",
        kr: "전술형",
        en: "actical type",
        level: question[2]?.filter(
          (item) => item.value === "전술형" && item.answer === "true"
        )?.length,
        totalQuestion: question[2]?.filter((item) => item.value === "전술형")
          ?.length,
      },
      rightType: {
        alphabet: "S",
        kr: "전략형",
        en: "trategic type",
        level: question[2]?.filter(
          (item) => item.value === "전략형" && item.answer === "true"
        )?.length,
        totalQuestion: question[2]?.filter((item) => item.value === "전략형")
          ?.length,
      },
    },
    {
      title: "목적성취방식",
      leftType: {
        alphabet: "T",
        kr: "과제형",
        en: "ask type",
        level: question[3]?.filter(
          (item) => item.value === "과제형" && item.answer === "true"
        )?.length,
        totalQuestion: question[3]?.filter((item) => item.value === "과제형")
          ?.length,
      },
      rightType: {
        alphabet: "R",
        kr: "관계형",
        en: "eleational type",
        level: question[3]?.filter(
          (item) => item.value === "관계형" && item.answer === "true"
        )?.length,
        totalQuestion: question[3]?.filter((item) => item.value === "관계형")
          ?.length,
      },
    },
    {
      title: "의사결정방식",
      leftType: {
        alphabet: "S",
        kr: "혼자형",
        en: "tand-alone type",
        level: question[4]?.filter(
          (item) => item.value === "혼자형" && item.answer === "true"
        )?.length,
        totalQuestion: question[4]?.filter((item) => item.value === "혼자형")
          ?.length,
      },
      rightType: {
        alphabet: "G",
        kr: "그룹형",
        en: "roup type",
        level: question[4]?.filter(
          (item) => item.value === "그룹형" && item.answer === "true"
        )?.length,
        totalQuestion: question[4]?.filter((item) => item.value === "그룹형")
          ?.length,
      },
    },
    {
      title: "자기방어방식",
      leftType: {
        alphabet: "L",
        kr: "논리형",
        en: "ogical type",
        level: question[4]?.filter(
          (item) => item.value === "논리형" && item.answer === "true"
        )?.length,
        totalQuestion: question[4]?.filter((item) => item.value === "논리형")
          ?.length,
      },
      rightType: {
        alphabet: "E",
        kr: "감성형",
        en: "motional type",
        level: question[4]?.filter(
          (item) => item.value === "감성형" && item.answer === "true"
        )?.length,
        totalQuestion: question[4]?.filter((item) => item.value === "감성형")
          ?.length,
      },
    },
  ];

  const Item = ({ title, leftType, rightType, style }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          fontSize: 13,
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <div
            style={{
              height: 40,
              width: 40,
              borderRadius: 999,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {leftType.alphabet}
          </div> */}
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 200,
              }}
            >
              <span style={{ fontWeight: 600, color: colors.gray }}>
                {leftType.kr}
              </span>
              <span style={{ display: "flex", color: colors.gray }}>
                (<span style={{ fontWeight: 600 }}>{leftType.alphabet}</span>
                {leftType.en})
              </span>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                borderRadius: 999,
                border: `1px solid ${colors.primary}`,
                justifyContent: "center",
                position: "relative",
                height: 28,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                  justifyContent: "flex-end",
                  overflow: "hidden",
                  borderTopLeftRadius: 18,
                  borderBottomLeftRadius: 18,
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {Array(4)
                    .fill("")
                    .map((x, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flex: 1,
                          borderRight: `1px solid ${colors.navy}`,
                          height: "50%",
                        }}
                      />
                    ))}
                </div>
                <div
                  style={{
                    display: leftType.level ? "flex" : "hidden",
                    backgroundColor: colors.primary,
                    height: "100%",
                    width: `${
                      (leftType.level / leftType.totalQuestion) * 100
                    }%`,
                  }}
                />
              </div>
              <div
                style={{
                  fontWeight: 600,
                  padding: "0 4px",
                  // borderRight: `1px solid ${colors.primary}`,
                  // borderLeft: `1px solid ${colors.primary}`,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>{title}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                  borderTopRightRadius: 18,
                  borderBottomRightRadius: 18,
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {Array(4)
                    .fill("")
                    .map((x, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flex: 1,
                          borderLeft: `1px solid ${colors.navy}`,
                          height: "50%",
                        }}
                      />
                    ))}
                </div>
                <div
                  style={{
                    display: rightType.level ? "flex" : "hidden",
                    backgroundColor: colors.primary,
                    height: "100%",
                    width: `${
                      (rightType.level / rightType.totalQuestion) * 100
                    }%`,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 200,
              }}
            >
              <span style={{ fontWeight: 600, color: colors.gray }}>
                {rightType.kr}
              </span>
              <span style={{ display: "flex", color: colors.gray }}>
                (<span style={{ fontWeight: 600 }}>{rightType.alphabet}</span>
                {rightType.en})
              </span>
            </div>
          </div>
        </div>
        {/* <div
            style={{
              height: 40,
              width: 40,
              borderRadius: 999,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            {rightType.alphabet}
          </div> */}
      </div>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "0px 20px",
        justifyContent: "space-between",
        boxSizing: "border-box",
      }}
    >
      {CATEGORY_LIST.map((item, i) => (
        <Item key={i} style={{ marginTop: i ? 8 : 0 }} {...item} />
      ))}
    </div>
  );
}
