import { colors } from "../styles/colors";
import EvaluationFormTitle from "./evaluationFormTitle";

export default function Question({ data, onClick, part, parentIndex }) {
  return (
    <div>
      <EvaluationFormTitle part={part} />
      <div
        style={{
          display: "flex",
          fontSize: 14,
          flexDirection: "column",
          backgroundColor: colors.tableBackground,
          padding: 20,
          borderTop: `1px solid ${colors.navy}`,
        }}
      >
        <span style={{ fontSize: 16 }}>
          {`part.${part}의 총 문항 수는 ${data?.length}문항입니다. 문제는 직관적으로 답을 하시도록 권장합니다.`}
        </span>
        {data?.map((item, index) => {
          const name = `question-${parentIndex}-${index}`;
          return (
            <div
              id={name}
              key={index + item?.answer}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                border: `1px solid ${colors.lightGray}`,
                marginTop: 16,
                padding: 16,
                borderRadius: 8,
              }}
            >
              <span style={{ fontWeight: 600, marginBottom: 4 }}>{`${
                index + 1
              }. ${item?.question}`}</span>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="radio"
                    id={`${parentIndex},${index},true`}
                    name={name}
                    value="true"
                    onChange={(e) =>
                      onClick({ parentIndex, index, value: e.target.value })
                    }
                    checked={item?.answer === "true"}
                    style={{
                      accentColor: colors.navy,
                      width: 16,
                      height: 16,
                      margin: "4px 4px 4px 20px",
                    }}
                  />
                  <label htmlFor={`${parentIndex},${index},true`}>그렇다</label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="radio"
                    id={`${parentIndex},${index},false`}
                    name={name}
                    value="false"
                    onChange={(e) =>
                      onClick({ parentIndex, index, value: e.target.value })
                    }
                    checked={item?.answer === "false"}
                    style={{
                      accentColor: colors.navy,
                      width: 16,
                      height: 16,
                      margin: "4px 4px 4px 64px",
                    }}
                  />
                  <label htmlFor={`${parentIndex},${index},false`}>
                    그렇지 않다
                  </label>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
