import { colors } from "../styles/colors";
import { useEvaluation } from "../hooks/useEvaluation";
import { useUser } from "../hooks/useUser";
import { FORM_TYPE_ADULT } from "../utils/consts";

export default function Potential() {
  const { testQuestion } = useEvaluation();
  const { evaluationFormType } = useUser();

  const checkEvaluationFormType = (type) =>
    // type === FORM_TYPE_ADULT ? "false" : "true";
    type === FORM_TYPE_ADULT ? "true" : "true";

  const CATEGORY_LIST = [
    {
      leftType: {
        kr: "정보수집방식",
        level: testQuestion[0]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: testQuestion[0]?.filter(
          (item) => item.value === "잠재역량"
        )?.length,
      },
      rightType: {
        kr: "능력표현방식",
        level: testQuestion[1]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: testQuestion[1]?.filter(
          (item) => item.value === "잠재역량"
        )?.length,
      },
    },
    {
      leftType: {
        kr: "목표실행방식",
        level: testQuestion[2]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: testQuestion[2]?.filter(
          (item) => item.value === "잠재역량"
        )?.length,
      },
      rightType: {
        kr: "목적성취방식",
        level: testQuestion[3]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: testQuestion[3]?.filter(
          (item) => item.value === "잠재역량"
        )?.length,
      },
    },
    {
      leftType: {
        kr: "의사결정방식",
        level: testQuestion[4]?.filter(
          (item) =>
            item.value === "의사결정방식-잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: testQuestion[4]?.filter(
          (item) => item.value === "의사결정방식-잠재역량"
        )?.length,
      },
      rightType: {
        kr: "자기방어방식",
        level: testQuestion[4]?.filter(
          (item) =>
            item.value === "자기방어방식-잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: testQuestion[4]?.filter(
          (item) => item.value === "자기방어방식-잠재역량"
        )?.length,
      },
    },
  ];

  const Item = ({ leftType, rightType, style }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: colors.navy,
          width: "100%",
          fontSize: 13,
          marginTop: 14,
          ...style,
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              height: 40,
              width: 40,
              borderRadius: 999,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: 20,
            }}
          ></div>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                borderRadius: 999,
                border: `1px solid ${colors.primary}`,
                justifyContent: "center",
                position: "relative",
                marginBottom: 4,
                height: 34,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    display: leftType.level ? "flex" : "hidden",
                    backgroundColor: colors.primary,
                    height: "100%",
                    borderTopLeftRadius: 18,
                    borderBottomLeftRadius: 18,
                    width: `${
                      (leftType.level / leftType.totalQuestion) * 100
                    }%`,
                  }}
                />
              </div>
              <div
                style={{
                  fontWeight: 600,
                  padding: "0 4px",
                  borderRight: `1px solid ${colors.primary}`,
                  borderLeft: `1px solid ${colors.primary}`,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 76.45,
                }}
              >
                <span>잠재역량</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: rightType.level ? "flex" : "hidden",
                    backgroundColor: colors.primary,
                    height: "100%",
                    borderTopRightRadius: 18,
                    borderBottomRightRadius: 18,
                    width: `${
                      (rightType.level / rightType.totalQuestion) * 100
                    }%`,
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 600, color: colors.gray }}>
                  {leftType.kr}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: 600, color: colors.gray }}>
                  {rightType.kr}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: 40,
              width: 40,
              borderRadius: 999,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              fontSize: 20,
            }}
          ></div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        fontSize: 16,
        padding: "10px 40px",
      }}
    >
      {CATEGORY_LIST.map((item, index) => (
        <Item key={index} style={{ marginTop: index ? 14 : 0 }} {...item} />
      ))}
    </div>
  );
}
