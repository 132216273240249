import React from "react";
import { colors } from "../styles/colors";

const RoundGraph = ({
  graphTitle,
  outWidth,
  colorSize,
  backgroundColor,
  borderColor,
  borderWidth,
}) => {
  return (
    <>
      {Array(7)
        .fill("")
        .map((x, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                borderRadius: 999,
                // boxSizing:
                //   borderColor === "transparent" ? "content-box" : "border-box",
                border: `${
                  i === colorSize - 1
                    ? borderColor === "transparent"
                      ? 0
                      : 4
                    : borderWidth
                }px solid ${
                  i === 0
                    ? "black"
                    : i === colorSize - 1 && borderColor
                    ? borderColor
                    : colors.grayBorder
                }`,
                width: (outWidth / 7) * (i + 1),
                aspectRatio: 1,
                alignSelf: "center",
                backgroundColor:
                  i < colorSize && backgroundColor ? backgroundColor : "white",
                color: "black",
                fontSize: 40,
                zIndex: 6 - i,
              }}
            >
              {i ? (
                <div
                  style={{
                    position: "absolute",
                    top: colorSize === i + 1 ? -7 : -5,
                    fontSize: 10,
                    alignSelf: "center",
                    backgroundColor:
                      colorSize < i + 1 ? "white" : "transparent",
                    right: outWidth * 0.072 * i + 2,
                    color: colorSize === i + 1 ? "black" : colors.grayBorder,
                    fontWeight: colorSize === i + 1 ? 600 : 500,
                  }}
                >
                  {i}
                </div>
              ) : null}
            </div>
          );
        })}
      <div
        style={{
          position: "absolute",
          borderRadius: 999,
          zIndex: 999,
          color: "black",
          fontSize: (outWidth / 7) * 0.5 || 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "500",
        }}
      >
        <div>{graphTitle}</div>
      </div>
    </>
  );
};

export default RoundGraph;
