import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./useUser";
import { questionList } from "../utils/consts";

const EvaluationContext = createContext();

export const EvaluationProvider = ({ children }) => {
  const { evaluationFormType } = useUser();
  const [testQuestion, setTestQuestion] = useState(
    questionList[evaluationFormType]
  );

  const resetQuestion = () => {
    setTestQuestion(undefined);
  };

  useEffect(() => {
    setTestQuestion(questionList[evaluationFormType]);
  }, [evaluationFormType]);

  return (
    <EvaluationContext.Provider
      value={{
        testQuestion,
        setTestQuestion,
        resetQuestion,
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
};

export const useEvaluation = () => useContext(EvaluationContext);
