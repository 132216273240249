import { useNavigate } from "react-router-dom";
import { colors } from "../styles/colors";
import { useUser } from "../hooks/useUser";
import useScreenSize from "../hooks/useScreenSize";
import PageTitle from "../components/pageTitle";
import Question from "../components/question";
import { useEvaluation } from "../hooks/useEvaluation";
import {
  FORM_TYPE_ADULT,
  FORM_TYPE_CHILD,
  FORM_TYPE_STUDENT,
  FORM_TYPE_BABY,
} from "../utils/consts";

export default function EvaluationForm() {
  const navigate = useNavigate();
  const { width, maxWidth } = useScreenSize();
  const { evaluationFormType } = useUser();
  const { testQuestion, setTestQuestion } = useEvaluation();
  const evaluationFormDesc = {
    [FORM_TYPE_ADULT]: (
      <span>
        성격5요인 이론과 분석심리학 이론을 토대로 팀과 팀원의 4가지의 역량분석과
        기질, 행동, 심리적 욕구의 성격유형을 도출하는 다면적 성격유형검사
        <br />
        <br />
        <br />
        <span>대상</span> | 대학생 및 성인
        <br />
        <span>소요시간</span> | 약 20~25분
        <br />
        <span>문항수</span> | 100문항
        <br />
        <br />
        <br />
        소개
        <br />
        기질적 성격유형과 유전적 성격에 의한 행동방식을 파악함으로써 개인의 자기
        이해와 팀의 구성원 간의 상호이해를 돕기 위한 검사이다. 팀의 업무성과나
        학업성과의 솔루션을 제공한다. 본 검사는 성격 5요인 이론의 취약점인
        성격의 내면적 구조나 역동의 동기를 칼 융의 분석 심리학 이론과 성격유형
        이론으로 보완한 실용적인 검사이다. 개인 및 단체로 검사가 가능하며,
        검사를 통해 상담, 코칭, 리더십 및 경영 개발, 대인관계 및 업무성과,
        학업성과를 위한 솔루션, 갈등 해결, 진로나 직무 계획 및 디브리핑 워크숍
        등의 다양한 상황에서 필요한 정보를 제공받을 수 있다.
      </span>
    ),
    [FORM_TYPE_STUDENT]: (
      <span>
        고등자기보고검사서
        <br />
        다음 문항은 평상시에 자신이 행동하는 방식에 대한 질문입니다. 본
        질문지에서 자신의 행동을 가장 잘 표현한 것을 빠르게 선택하여 답하시면
        됩니다.
        <br />
        <br />
        <br />
        대상 | 중3학년~고3학년에 해당하는 청소년
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        <br />
        소개
        <br />
        기질적 성격에 의한 행동방식, 생존을 위한 행동방식, 정서적 욕구를
        파악함으로써 학습자의 자신의 효과적인 학습방법과 학교생활의 교우 간의
        상호 이해를 돕기 위한 검사이다. 경쟁 관계에서 학업 성과의 솔루션을
        제공한다. 본 검사는 성격 5 이론의 취약점인 성격의 내면적 구조나 역동의
        동기를 칼 융의 분석 심리학 이론과 성격유형 이론으로 보완한 실용적인
        검사이다. 개인 및 단체로 검사가 가능하며, 검사를 통해 상담, 학업 코칭,
        진로 계발, 교우관계 및 학업성취를 위한 솔루션, 학업 스트레스의 원인과
        갈등 해결, 진로나 학업방식및 디브리핑 워크숍 등의 다양한 상황에서 필요한
        정보를 제공받 을 수 있다.
      </span>
    ),
    [FORM_TYPE_CHILD]: (
      <sapn>
        초등관찰검사서
        <br />
        다음 문항은 평상시에 귀하의 자녀가 부모에게 보이는 행동에 관한
        질문입니다. 각 문항을 본 질문지에서 묻는 자녀의 행동을 가장 잘 표현한
        것을 직관적으로 답하시면 됩니다.
        <br />
        <br />
        대상 | 아동의 부모님
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        소개
        <br />
        기질적 성격유형과 유전적 성격에 의한 행동방식을 파악함으로써 개인의 자기
        이해와 팀의 구성원 간의 상호이해를 돕기 위한 검사이다. 팀의 업무성과나
        학업성과의 솔루션을 제공한다. 본 검사는 성격 5요인 이론의 취약점인
        성격의 내면적 구조나 역동의 동기를 칼 융의 분석 심리학 이론과 성격유형
        이론으로 보완한 실용적인 검사이다. 개인 및 단체로 검사가 가능하며,
        검사를 통해 상담, 코칭, 리더십 및 경영 개발, 대인관계 및 업무성과,
        학업성과를 위한 솔루션, 갈등 해결, 진로나 직무 계획 및 디브리핑 워크숍
        등의 다양한 상황에서 필요한 정보를 제공받을 수 있다.
      </sapn>
    ),
    [FORM_TYPE_BABY]: (
      <sapn>
        영유아 관찰검사서
        <br />
        다음 문항은 평상시에 귀하의 자녀가 부모에게 보이는 행동에 관한
        질문입니다. 각 문항을 본 질문지에서 묻는 자녀의 행동을 가장 잘 표현한
        것을 직관적으로 답하시면 됩니다.
        <br />
        <br />
        대상 | 영유아의 부모님
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        소개
        <br />
        기질적 성격유형과 유전적 성격에 의한 행동방식을 파악함으로써 개인의 자기
        이해와 팀의 구성원 간의 상호이해를 돕기 위한 검사이다. 팀의 업무성과나
        학업성과의 솔루션을 제공한다. 본 검사는 성격 5요인 이론의 취약점인
        성격의 내면적 구조나 역동의 동기를 칼 융의 분석 심리학 이론과 성격유형
        이론으로 보완한 실용적인 검사이다. 개인 및 단체로 검사가 가능하며,
        검사를 통해 상담, 코칭, 리더십 및 경영 개발, 대인관계 및 업무성과,
        학업성과를 위한 솔루션, 갈등 해결, 진로나 직무 계획 및 디브리핑 워크숍
        등의 다양한 상황에서 필요한 정보를 제공받을 수 있다.
      </sapn>
    ),
  };

  const handleAnswer = ({ index, value, parentIndex }) => {
    let tempArr = [...testQuestion];
    tempArr[parentIndex][index].answer = value;

    setTestQuestion([...tempArr]);
  };

  const onSubmit = () => {
    const hasEmptyAnswer = testQuestion
      ?.flatMap((group) => group)
      ?.some((question) => question.answer === "");

    if (hasEmptyAnswer) {
      alert("모든 질문에 답변을 입력하세요.");
    } else {
      window.scrollTo(0, 0);
      navigate("/result");
    }
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width, maxWidth, height: "100%", marginTop: 60 }}>
        <PageTitle title="진단" />
        <div
          style={{
            backgroundColor: colors.tableBackground,
            marginTop: 20,
            width: "100%",
            padding: 20,
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              border: `1px solid ${colors.lightGray}`,
              padding: 20,
              borderRadius: 8,
              fontWeight: 500,
              lineHeight: 1.25,
            }}
          >
            {evaluationFormDesc[evaluationFormType]}
          </div>
        </div>
        {testQuestion?.map((item, index) => (
          <Question
            key={index}
            data={item}
            parentIndex={index}
            onClick={handleAnswer}
            part={index + 1}
          />
        ))}
        <div
          style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <button
            onClick={onSubmit}
            type="submit"
            style={{
              background: "none",
              border: "none",
              font: "inherit",
              cursor: "pointer",
              width: 238,
              backgroundColor: colors.navy,
              color: "#fff",
              fontWeight: 700,
              borderRadius: 8,
              height: 36,
              margin: "60px 0",
            }}
          >
            완료
          </button>
        </div>
      </div>
    </div>
  );
}
