import React from "react";
import { Routes, Route } from "react-router-dom";
import Result from "./pages/result";
import Login from "./pages/login";
import { UserProvider } from "./hooks/useUser";
import { EvaluationProvider } from "./hooks/useEvaluation";
import EvaluationForm from "./pages/evaluationForm";
import TempLogin from "./pages/tempLogin";

const App = () => {
  return (
    <UserProvider>
      <EvaluationProvider>
        <div
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <Routes>
            <Route path="/" element={<TempLogin />} />
            <Route path="/info" element={<Login />} />
            <Route path="/form" element={<EvaluationForm />} />
            <Route path="/result" element={<Result />} />
          </Routes>
        </div>
      </EvaluationProvider>
    </UserProvider>
  );
};

export default App;
