import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FORM_TYPE_ADULT } from "../utils/consts";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [customUserType, setCustomUserType] = useState("");
  const [evaluationFormType, setEvaluationFormType] = useState("");
  const [userBirthday, setUserBirthday] = useState("");
  const [gender, setGender] = useState("MALE");
  const [tester, setTester] = useState("");
  const [customTester, setCustomTester] = useState("");

  const navigate = useNavigate();

  const resetUser = () => {
    setUserName("");
    setUserType("");
    setCustomUserType("");
    setEvaluationFormType("");
    setUserBirthday("");
    setGender("MALE");
    setTester("");
  };

  useEffect(() => {
    const pw = sessionStorage.getItem("a");

    if (pw !== "mcc-crata") {
      navigate("/", { replace: true });
    } else if (!userName) {
      navigate("/info", { replace: true });
    }
  }, [userName, navigate]);

  return (
    <UserContext.Provider
      value={{
        userName,
        setUserName,
        userType,
        setUserType,
        customUserType,
        setCustomUserType,
        evaluationFormType,
        setEvaluationFormType,
        userBirthday,
        setUserBirthday,
        gender,
        setGender,
        tester,
        setTester,
        customTester,
        setCustomTester,
        resetUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
