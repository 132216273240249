import { useState } from "react";

export default function TempLogin() {
  const [password, setPassword] = useState();

  const handleSubmit = () => {
    if (password === "mcc-crata") sessionStorage.setItem("a", password);
    else alert("비밀번호를 확인해주세요");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <form
        style={{ display: "flex", flexDirection: "column", width: 200 }}
        onSubmit={handleSubmit}
      >
        <span
          style={{
            textAlign: "left",
          }}
        >
          로그인
        </span>
        <div style={{ display: "flex" }}>
          <input
            style={{ width: 120 }}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">로그인</button>
        </div>
      </form>
    </div>
  );
}
