const auraSomaMap = {
  3: {
    borderColor: "blue",
    backgroundColor: "green",
    desc: "평화로운 소통/진심과 기다림",
    borderDesc:
      "조용하고 평화로운 상황에서 서로의 이야기를 경청하는 상황일 때 자신의 능력이 발현된다. 사람을 설득하고 마음을 풀어주는 능력이 뛰어나다.",
    backgroundDesc:
      "자기의 속도대로 기다려주는 환경일 때 사고하고 판단하는 힘을 발휘한다. 좋은 것과 싫은 것이 분명하고 자신이 좋아하는 것에 집중력과 인내심을 발휘한다.",
  },
  4: {
    borderColor: "yellow",
    backgroundColor: "gold",
    desc: "지식과 지혜의 차이",
    borderDesc:
      "호기심이 많고 배우는 것을 즐긴다. 성공 지향적이고 사회적으로 인정받기를 원한다. 결과와 답이 명확한 일에 집중력을 높여 자신 의 능력을 발휘한다.",
    backgroundDesc:
      "남과 다른 자신만의 것을 찾았을 때 사고와 판단력이 뛰어나다. 안정적인 상태에서 주변의 존경을 받는 인정을 필요로 한다.",
  },
  5: {
    borderColor: "yellow",
    backgroundColor: "red",
    desc: "자신이 나설 때와 물러설 때를 아는 자",
    borderDesc:
      "너무 잘 하려는 욕구가 강하다. 새로운 것을 배우고 습득하는 능력이 뛰어나며 자신이 잘 할 자신이 있는 일은 끝까지 해낸다. 완벽주의자라 스스로 나서려고 하진 않는다. 주어진 것에 최고가 되려 한다.",
    backgroundDesc:
      "자신이 잘 할 수 있는 것에는 열정을 다하고 부지런하다. 될 때까지 해내는 집념이 강하고 움직이면서 판단하는 능력이 뛰어 나다. 한 번에 한 가지씩 인지하고 행동한다. 단순한 상황에 판단이 빠르고 전술에 능하다.",
  },
  6: {
    borderColor: "red",
    backgroundColor: "red",
    desc: "포기를 모르는 열정가",
    borderDesc:
      "단순한 생활을 즐기며 자신의 현실적인 삶에 충실하다. 속과 겉 이 같아서 자신의 감정이나 본능에 충실하며 솔직하다. 해결사의 역할에 뛰어난 능력을 가졌다. 마음이 그대로 얼굴에 드러나며 자신이 목표하거나 목적으로 삼은 것은 목숨을 걸고 이뤄낸다. 체력이 강하다.",
    backgroundDesc:
      "옳고 그름으로 인지하고 복잡한 심리상태를 이해하기 어렵고 단순히 보이는 대로 이해한다. 자기 편한 데로 인지한다. 집중력 이 뛰어나 학습이나 운동 등 경쟁에 강한 정신력을 가지고 있다.",
  },
  7: {
    borderColor: "yellow",
    backgroundColor: "green",
    desc: "세상을 변화시키는 사명자",
    borderDesc:
      "지식에 진심을 담아서 세상을 변화시키는데 사명을 갖는다. 삶에 진지하고 올바른 태도로 인생을 살아가기를 원한다. 올바르지 못한 것으로부터 사람들을 구하려는 의지가 강한 사람이다. 행동에 예의와 명분을 중요하게 여긴다.",
    backgroundDesc:
      "결과 중심으로 인지하고 결과의 잘못을 자신으로부터 찾는다. 아는 것으로부터 힘이 되고 사고와 판단이 분명하다.",
  },
  8: {
    borderColor: "yellow",
    backgroundColor: "blue",
    desc: "탁월한 지식의 전달자",
    borderDesc:
      "지식을 습득하고 지식을 자신의 것으로 이해하고 타인에게 쉽게 전달하는 능력이 뛰어나다. 자신이 가진 지식과 정보를 타인 의 삶이나 사고방식의 변화를 주는 능력이 뛰어 나다. 지식공유를 원하고 그것으로 사람들이 하나가 되고 평화를 이루는 집단지성을 만든다.",
    backgroundDesc:
      "논리에 맞는 지식을 바탕으로 사고한다. 아는 것이 힘이 되는 인지력을 가지고 있다.",
  },
  9: {
    borderColor: "turquoise",
    backgroundColor: "green",
    desc: "독창적인 사고와 집중력으로 새로움을 만든다",
    borderDesc:
      "남다른 정신적 세계관으로 창의적이고 자유로운 영혼으로 자기 주도적으로 행동한다. 스스로 선택하고 싶어 하고 자신의 정체성에 대해 타인의 평가를 인정하지 않는다. 독특함을 인정받는 환경에서 창의적이고 새로운 조합을 만들어 내는 능력을 가졌다.",
    backgroundDesc:
      "자신만의 개성이 인정받는 환경에서 인지와 판단이 뛰어나다. 진지하고 진심으로 자신에게 집중한다.",
  },
  10: {
    borderColor: "green",
    backgroundColor: "green",
    desc: "나여야만 하는 사명감을 가진 자",
    borderDesc:
      "자신이 세상에서 해야 할 사명을 찾아 그것을 이루고 성취하는 능력을 가졌다. 자신이 몰입할 것을 찾으면 섬세하고 꼼꼼하게 단계별로 만들어가는 능력이 탁월하다. 꼭 나여야만 하는 일을 찾고 그것을 찾아 일할 때의 만족감과 성취감을 갖게 되는 사람이다.",
    backgroundDesc:
      "솔직하고 자신이 좋아하는 것과 좋아하지 않는 것이 확실하고 진심으로 사고하고 인지하다 보니 하나를 파고들면 한 가지는 확실하게 만들어낸다.",
  },
  11: {
    borderColor: "clear",
    backgroundColor: "pink",
    desc: "사랑받기 위해 태어난 사람",
    borderDesc:
      "자기애가 강해서 자신을 있는 그대로 사랑해 주길 원한다. 자신을 비난하거나 바꾸려고 들면 반항적이 되고 청개구리처럼 행동한다. 부모에 대한 의존성이 높고 해맑음을 가지고 있다. 또래나 경쟁 집단 속에서는 관심의 중심이 되고 싶어 한다. 질서를 좋아하고 규칙과 정의로움을 추구하지만 명령 받는 것을 싫어한다. 자신이 통제 받는다고 느끼면 무례해지고 멋대로 행동한다.",
    backgroundDesc:
      "자신이 돌봄을 받는 것을 사랑받는다고 느낀다. 세상에 태어난 이유가 돌봄 받고 사랑받기 위해서 태어난다는 것을 깨닫게 되면 자신의 능력과 재능을 발휘하여 타인에게 사랑을 돌려주려는 사랑둥이가 된다.",
  },
  12: {
    borderColor: "clear",
    backgroundColor: "blue",
    desc: "자신을 신뢰하고 믿어주는 만큼 자란다",
    borderDesc:
      "자신을 신뢰하고 믿어주는 환경에서 자신의 능력을 최대한 발휘한다. 주변의 상황에 둔하고 신경 쓰지 않는다. 자신의 속도대로 자연스럽게 흐르듯이 행동하고 여유롭고 예측 가능한 상황일 때 책임감 있는 행동을 한다. 약속한 것은 지키기 때문에 쉽게 약속을 하지 않는다. 현재의 모습에 메이지 않고 과정에서 응원과 무한한 신뢰를 줄 때 성장한다.",
    backgroundDesc:
      "자신의 계획이나 생각대로 진행될 때 안정감을 느끼면 이성적 따뜻함으로 이해하고 소통하는 뛰어난 능력을 발휘한다. 전체를 인지하려 하기 때문에 처음에는 이해력이 느리고 반응도 더디지만 자기이해와 인지가 끝나면 문제 중심적 이성과 사람 중심적 감성 모두를 사용하여 인지하고 소통한다.",
  },
  13: {
    borderColor: "clear",
    backgroundColor: "green",
    desc: "큰 나무가 될 한 알의 씨앗",
    borderDesc:
      "안정이 보장된 자기 공간과 주변의 조건들이 갖춰졌을 때 성장한다. 처음에는 더디지만 자기의 때가 되면 반전이 일어난다. 자신의 성장이 주변을 변화시키고 나누는 역할을 한다. 아낌없이 주는 나무이다.",
    backgroundDesc:
      "자신이 잘하는 것을 찾는 시기가 길다는 것을 스스로 인지하고 자기 믿음이 중요하다. 땅 밑에서 싹이 나고 자라서 열매를 맺는 과정이 필요하고 자연의 이치에서 인식의 성장이 일어나면 뛰어난 집중력과 현실을 기반한 성장 방식을 찾는다.",
  },
  14: {
    borderColor: "clear",
    backgroundColor: "gold",
    desc: "꽂혀야 한다. 그래서 그냥 있는 자",
    borderDesc:
      "자신이 무엇에 꽂히는 가를 찾는 것에 중요하다. 그때에 자신의 진가를 발휘한다. 자신만의 것을 찾지 못하면 수동적으로 행동한다. 부모나 주변에 의존하고 자신의 생각보다는 시켜야만 행동하기 때문에 자신의 한계에서 벗어나지 않는다. 많은 경험과 도전 이 필요하다.",
    backgroundDesc:
      "자신이 어떤 능력을 가졌는지를 알게 되면 존재의 가치를 깨닫고 자존감을 찾는다. 비전이 확실하면 동기부여도 확실해진다. 분별력과 일의 우선순위에 맞추는 사고력으로 일의 성취감이 있다.",
  },
  15: {
    borderColor: "clear",
    backgroundColor: "violet",
    desc: "창의적인 잠재력을 가진 자",
    borderDesc:
      "자신이 필요한 존재임을 증명하려고 백조처럼 보이지 않는 곳 에서 애를 쓴다. 완벽 주의적 성향 때문에 시작할 때 더디고 눈치가 없고 소심한 행동을 한다. 완벽하게 준비된 후에 행동하려는 성향 때문이다. 자신의 내면에는 잠재된 능력을 가진 사람이라서 시간이 지날수록 자신의 새로운 모습을 발견하게 되는 사람이다.",
    backgroundDesc:
      "주변이 자신을 어떻게 보는지가 중요하다. 자신의 평가가 좋은 곳에서는 창의적이고 거침없는 솔직함으로 아이디어와 기쁨을 준다. 남다르게 보이고 싶지만 튀고 싶어 하지 않는다.",
  },
  16: {
    borderColor: "violet",
    backgroundColor: "violet",
    desc: "이상과 현실 사이의 4차원자",
    borderDesc:
      "이상과 현실의 사이에서 갈등한다. 순수하고 솔직하다. 자신의 감정이 그대로 드러난다. 창의적이고 다른 사람들과 다른 4차원적인 생각과 행동을 하지만, 늘 타인에게 이타심을 가지고 있는 사람이다. 남과는 다르고 싶지만 나서고 싶지는 않은 양가적인 마음과 행동으로 타인의 오해를 받거나 주변의 이해를 받기가 어렵다.",
    backgroundDesc:
      "재미있고 즐거움으로 주변을 채우려는 생각이 늘 존재한다. 자신을 필요로 하는 곳에서 자신을 필요로 하는 곳에서 독특한 창의성과 넘치는 흥으로 개그적인 이야기로 주변을 리드한다.",
  },
  17: {
    borderColor: "green",
    backgroundColor: "violet",
    desc: "이상과 현실에서 진실 추구자",
    borderDesc:
      "이상은 즐거움으로 채워진 이타심으로 지금에 충실함과 자신이 지금 할 수 있는 상황이 되지 못하면 현실을 우선으로 선택한다. 이상과 현실이 불일치되면 자기 동굴로 들어간다. 자신의 이야기보다 타인의 이야기에 관심을 가지고 듣는다.",
    backgroundDesc:
      "타인과의 대화중에 자기 보기를 한다. 이상과 현실 사이에서 진짜 내가 원하는 진실이 무엇인지 찾는다. 진짜가 무엇인지를 찾을 때 자유로움과 즐거움을 느끼고 판단력과 결단력이 생긴다.",
  },
  18: {
    borderColor: "yellow",
    backgroundColor: "violet",
    desc: "자기가 이해가 우선인 실행자",
    borderDesc:
      "아는 것이 힘이 된다. 정보의 진위 여부를 찾는 능력이 뛰어나다. 자신이 이해가 되어야 행동하는 사람이다. 타인이 자신을 어떻게 평가하는지를 의식하며 행동한다. 모범적이고 결과를 예측하며 행동하기에 계산이 빠르다.",
    backgroundDesc:
      "현실적인 문제 중 경제적인 부분에 더욱 약해서 불안감으로 판단력이 흐려지고 조급해져서 모든 것이 마비되거나 포커페이스가 안 된다. 자기 통제력에 마비가 온다. 안정적이고 성공적인 상황에서는 고속도로의 스포츠카처럼 사고와 판단이 빠르고 정확하다.",
  },
  19: {
    borderColor: "red",
    backgroundColor: "purple",
    desc: "현실에 집중하고 지금을 사는 자",
    borderDesc:
      "현실에 필요한 것에 집중하고 행동한다. 답이 분명하고 물질적인 것에 에너지와 열정을 발휘한다. 일을 할 때는 목숨을 거는 형이라서 결과 중심적이고 빠르게 답이 나오길 원한다. 승부욕이 강하고 지는 것을 견디지 못한다.",
    backgroundDesc:
      "다 갖춰진 상황에서 선택을 할 수 있고 판단력이 좋아진다. 보상이 확실할 때 자신의 집중력도 높아진다. 미래보다는 지금 현실을 판단하는 능력이 뛰어나다.",
  },
  20: {
    borderColor: "blue",
    backgroundColor: "pink",
    desc: "내 안에 어린아이를 가진 책임가",
    borderDesc:
      "책임감이 강하고 사람과의 소통에서 편안함을 준다. 사람을 있는 그대로 받아주려는 열린 마음으로 사람을 대한다. 자신의 내면에는 어린아이처럼 순수하고 돌봄을 원하는 다른 면을 가지고 있어서 사회에서의 행동과 가정에서의 행동이 다르다. 많이 듣고 상대방 위주로 이해해주지만 반면 자신의 이야기를 들어주고 무조건적인 자기편이 되어 주길 바란다.",
    backgroundDesc:
      "사랑받으면 책임감이 강해지고 표현력이 풍부하고 따뜻하다. 관심과 보호를 받고 있을 때는 머리가 좋고 학습력도 좋아진다. 인내와 끈기로 하기 싫은 것도 사랑받고 관심의 중심이 되었을 때는 참고 끝까지 해낸다. 머리가 뛰어나고 이타심과 효심으로 힘든 상황도 이겨내어 결과를 만들어 낸다.",
  },
  21: {
    borderColor: "green",
    backgroundColor: "pink",
    desc: "외로움을 숙제로 가진 자",
    borderDesc:
      "자신을 알아가는 데 시간이 필요하다. 주변의 응원과 용기를 북 돋아 줄 사람이 필요하다. 의존성이 높아서 스스로의 믿음보다 주변의 인정으로 자신을 알아간다. 그러나 자존심이 강해서 도움을 청하는 것이 어렵다. 주변에서 알아서 챙겨주는 사람이 필요하다. 수동적인 삶을 스스로 선택하는 사람이다. 무조건적인 사랑을 기대하는 성향으로 성인이 되어서도 사랑을 갈망하는 외로움과 고독함을 가진다.",
    backgroundDesc:
      "사고와 분석력이 빠르고 머리가 좋다. 사람의 관계에서 관심에서 멀어지거나 소외당한다고 느끼면 예민해지고 다른 일의 집중이 안 된다.",
  },
  22: {
    borderColor: "yellow",
    backgroundColor: "pink",
    desc: "온전한 사랑을 추구하는 자",
    borderDesc:
      "자신이 세상에서 해야 할 사명을 찾아 그것을 이루고 성취하는 능력을 가졌다. 자신이 몰입할 것을 찾으면 섬세하고 꼼꼼하게 단계별로 만들어가는 능력이 탁월하다. 꼭 나여야만 하는 일을 찾고 그것을 찾아 일할 때의 만족감과 성취감을 위해 스스로 찾는 사람이다.",
    backgroundDesc:
      "솔직하고 자신이 좋아하는 것과 싫어하는 것이 확실하고 진심으로 사고하고 인지하다 보니 하나를 파고들면 한 가지는 확실하게 만들어낸다.",
  },
  23: {
    borderColor: "rose pink",
    backgroundColor: "pink",
    desc: "자기 사랑이 넘쳐서 타인에게로 향하는 자",
    borderDesc:
      "자기가 사랑스러워야 타인도 사랑할 수 있기 때문에 자신을 정확하게 알아가는 시간이 필요하다. 쉽게 상처받고 포기한다. 자기인식이 중요하고 다른 사람에 대한 역지사지가 필요하다. 자신의 진로를 타인에게 의존한다. 능력 있는 부모님에게 의존도가 높아서 주도적인 삶이 어렵다.",
    backgroundDesc:
      "사랑을 받고 싶어 하는 마음이 커서 타인에게 사랑을 요구하다 보니 수동적으로 생각하고 주변사람에게 사랑받으려고 그들이 요구하고 원하는 것을 선택하게 된다. 그러나 사랑을 주는 사람 이 올바르면 그대로 영향을 받아 변화한다.",
  },
  24: {
    borderColor: "violet",
    backgroundColor: "turquoise",
    desc: "자기 철학을 가진 자",
    borderDesc:
      "새로운 것에 수용적이다. 창의적이고 호기심이 많다. 영감이 뛰어나서 주변사람에게도 영감을 준다. 예술적인 감각과 다른 시각을 가지고 있다. 솔직하고 진심으로 이야기하는 편이며 말에 철학을 가지고 있다.",
    backgroundDesc:
      "솔직하게 표현하는 것이 허용되는 환경에서 자신의 표현력과 창의력 그리고 새롭게 해석하는 능력이 나타난다. 남들이 보지 않는 곳에서 노력하고 준비하는 백조 형이다. 잘해내려는 마음이 앞서기 때문에 긴장을 하게 되고 그러다 말실수를 하게 되는 경우도 생긴다.",
  },
  25: {
    borderColor: "purple",
    backgroundColor: "magenta",
    desc: "타인에게 주는 사랑을 하는 자",
    borderDesc:
      "타인에게 봉사하는 것을 사명으로 생각하고 즐거워한다. 자신을 필요로 하는 것에 감사한다. 호기심이 많아서 어릴 적에는 여러 가지 경험을 통해 성장하는 사서 고생 형이다. 자신의 경험을 솔직하게 말하지 않는 편이다. 현재에 충실하며 지나간 과거는 잊고 산다.",
    backgroundDesc:
      "자신의 경험을 바탕으로 이해하고 인지한다. 몸으로 체득해서 자신의 것으로 만들고 실패도 거름으로 여긴다. 고생을 즐기고 낙관적이고 세상에 물들지 않고 빠르게 회복한다.",
  },
  26: {
    borderColor: "orange",
    backgroundColor: "orange",
    desc: "에너자이저, 재미를 추구하는 자",
    borderDesc:
      "에너지가 넘치고 육체적인 재미를 즐긴다. 오감이 발달하여 감각이 빠르고 현실적인 계산도 빠르다. 태양 같은 에너지를 가지고 있어서 주변사람에게 영향력을 미치고 성장시키는 것을 사명으로 생각한다. 연예인 같은 에너지를 가지고 있어서 주변의식을 하 는 편이다. 사람을 좋아하지만 자신을 쉽게 드러내지는 못하는 의심의 소유자이기도 하다.",
    backgroundDesc:
      "오감이 발달하다보니 민감하고 예민하다. 상처도 잘 받아서 스스로 차단하다보면 좋은 감각도 사용하지 못하여 공감능력이 떨어지기도 한다.",
  },
  27: {
    borderColor: "red",
    backgroundColor: "green",
    desc: "안과 밖의 온도차가 큰 자",
    borderDesc:
      "생각과 마음 그리고 행동이 분리되어 있다. 행동은 부지런하고 에너지가 넘치지만 쉽게 지치고 집에서의 행동과 밖에서의 행동의 차이가 크다. 토끼와 거북이가 한 사람에게 함께 존재한다. 사회에서 일을 제대로 잘하고 싶어 하는 승부근성이 있다.",
    backgroundDesc:
      "생각과 마음은 혼란해도 말로 표현을 잘하지 않아서 티가 나지 않는다. 자신의 마음을 표현하지 않기 때문에 타인들은 혼돈스러운 마음이나 생각을 읽어 낼 수가 없다. 자신도 스스로의 변화를 분석하거나 이해하기 어려우며 자기 이해가 어렵고 자신을 오해하는 경우에 쉽게 지치고 판단력이 떨어진다.",
  },
  28: {
    borderColor: "green",
    backgroundColor: "red",
    desc: "좋은 것과 좋아하지 않는 것이 확실한 자",
    borderDesc:
      "좋은 것과 싫은 것이 확실하고 자기의 준비가 되었을 때 행동한다. 자기 페이스를 지키면서 자기 스타일대로 추진해 나간다. 그러나 내면에는 잘하려는 욕구 때문에 실패에 대한 두려움과 문제를 해결할 능력에 대한 불신을 가지고 있다. 양면성을 가지고 있어서 자기 수양과 자기이해에 대한 심리공부에 관심을 가진다.",
    backgroundDesc:
      "문제해결 중심적인 사고를 가지고 있어서 문제해결에 대한 집중력과 끈기를 가지고 최선을 다한다. 지는 것을 무서워하며 겁 을 내고 그것을 숨기기 위해 더 느긋한 척 한다.",
  },
  29: {
    borderColor: "red",
    backgroundColor: "blue",
    desc: "문제해결 능력을 갖춘 책임자",
    borderDesc:
      "현실에 맞춰서 행동한다. 현실의 기반인 자본과 물질을 우선시 하여 계산이 빠르고 정확하다. 명확하고 확실한 것을 원하기 때문에 대화의 상대방은 이 유형의 사람과의 대화에서 차갑고 따진다고 느낀다. 한편으로는 평화로운 관계를 중요하게 생각하고 사람과 함께 성장하고 싶어 한다. 행동과 마음의 차이가 심하여 사람들에게 오해를 받거나 신뢰를 잃는 경우가 생긴다.",
    backgroundDesc:
      "조용하고 사색적인 곳에서 사고력이 커지고 타인과의 대화를 통해 영감을 얻는다. 자신의 능력을 발휘할 수 있는 것에서는 확신과 추진력이 좋다.",
  },
  30: {
    borderColor: "blue",
    backgroundColor: "red",
    desc: "꿈을 현실로 만들어내는 실행자",
    borderDesc:
      "이상적인 생각이나 꿈을 현실적으로 만들어내는 실력자이다. 행동으로는 느리고 느긋한 것 같으나 내면으로는 경쟁심과 일등이 되고자 하는 승부사의 기질을 가졌다. 소통하는 능력과 사람을 모으는 능력을 겸비하고 있다. 책임질 수 있는 부분과 아닌 부분이 확실하고 명확한 계획을 세우고 책임에 대한 역할이 주어진 후 행동한다.",
    backgroundDesc:
      "즉흥적이고 감정적으로 진행되는 것에 힘들고 사고력도 떨어진다. 정해진 계획대로 순차적인 방식을 좋아하고 단순명료한 관계일 때 집중력이 높아진다.",
  },
  31: {
    borderColor: "green",
    backgroundColor: "gold",
    desc: "진심을 다해 자신의 가치를 찾는 자",
    borderDesc:
      "자신이 진심으로 원하는 것이 무엇인지를 중요하게 생각하고 선택의 기준을 삼는다. 진지함과 진중함을 가지고 있으며 자신이 원하지 않는 일은 하지 못하는 고집이 있다. 자신의 길을 찾게 되면 지치지 않는 에너지로 끝까지 완성하는 끈기를 가지고 있다.",
    backgroundDesc:
      "자신의 속도대로 기다려주는 환경이 되면 자신의 능력을 찾아 결과로 나타내는 방법을 찾는다. 사고방식이 자신이 선택한 것에 대한 책임감과 자신의 시간과 열정을 가치 있는 곳에 쓰려는 인지로 가치 있는 삶을 살기 위해 늘 새로움에 도전하고 스스로를 채우려는 욕구를 가지고 있다.",
  },
  32: {
    borderColor: "royal blue",
    backgroundColor: "gold",
    desc: "세상을 가치롭게 변화시키는 개혁자",
    borderDesc:
      "논리적, 이성적 이론을 바탕으로 창조한다. 직관이 발달하여 보지 않아도 상황을 이해하거나 예측하는 능력을 가지고 있다. 남다름을 추구하는 예술적인 능력을 가지고 있지만 현실적으로 최고가 되기 어렵다 판단하여 순수예술가가 되긴 어렵다. 스스로 자신의 길을 선택하고 주어진 것을 따라 가다보니 자신이 가야할 길이 어딘지 늘 고민 하며 살아간다.",
    backgroundDesc:
      "스스로 깨달으면서 전체를 알고 이해가 될 때 자신의 능력을 발휘한다. 전체를 알고 시작하려는 완벽주의자이며 자신이 주도하지만 책임감과 생각이 많아 일인자가 되기는 어렵다. 불합리한 것을 보고 그냥 지나칠 수 없다.",
  },
  33: {
    borderColor: "royal blue",
    backgroundColor: "turquoise",
    desc: "직관력을 가진 완벽주의자",
    borderDesc:
      "논리적이고 명확하고 확실한 것을 좋아하는 사람이다. 직관력이 뛰어나 보거나 경험하지 않아도 알 수 있다. 자신에 대한 믿음이 생길 때까지는 자신의 능력을 쓰지 못한다. 끈기와 인내가 필요한 영역보다는 전체를 보고 예측하고 기획하는 영역에서 자신의 능력을 발휘한다.",
    backgroundDesc:
      "조직이나 가족을 위해 일하지만 개인의 성장과 자유로움이 우선 되어야 사고, 판단력이 좋다. 자신을 믿어주고 소통이 자유로워야 직관력도 발휘된다. 솔직하고 감각적인 공간을 만드는 능력이 좋다.",
  },
  34: {
    borderColor: "pink",
    backgroundColor: "turquoise",
    desc: "사랑은 희생없는 자유로운 행위자",
    borderDesc:
      "자기애가 강하다. 사랑은 받고 주는 것으로 여긴다. 눈에 는 눈, 이에는 이라 행동한다. 받는 것이 좋은 것이든 나쁜 것이든 돌려준다. 억울한 삶을 싫어하고 가족이든 주변인이든 독립적이고 서로의 선택을 존중하며 살아가는 것을 추구한다. 개인적인 삶이 중요하다.",
    backgroundDesc:
      "개인주의 인지방식으로 ‘나는 나’, ‘너는 너’로 부터 시작한다. 가족 중심적으로 생각하고 잘해주려고 고민과 마음 씀씀이를 가지고 있으나 거리가 있고 선을 넘어 오는 것에 대한 저항이 강하다.",
  },
  35: {
    borderColor: "pink",
    backgroundColor: "violet",
    desc: "가족은 나의 삶의 힘이 되는 자",
    borderDesc:
      "가족에게 무조건적인 사랑을 한다. 이타심이 강하고 자신의 희생을 당연하게 여긴다. 자신이 지치고 힘들면 억울함에 자기분노와 자기부정이 일어난다. 자신의 행동을 타인에게 맞추다보니 자기를 위한 선택에 죄책감을 가지며 자기통제를 한다.",
    backgroundDesc:
      "사랑에는 조건이 없다 생각하고 희생과 헌신을 사랑이라 여긴다. 자신의 처함과 현실이 맞지 않을 때 현실을 따르지만 인지적 분열이 일어난다.",
  },
  36: {
    borderColor: "violet",
    backgroundColor: "pink",
    desc: "노블레스 오블리주의 실천자",
    borderDesc:
      "이타심이 강하고 따뜻하게 표현한다. 정의감이 강하고 상호존중과 배려가 중요하다. 사람이 가지고 있는 양가적 감정과 행동에 죄책감을 가진다. 자신의 여러 가지의 모습과 사회적인 모습인 페르소나 사이에서 지킬 앤 하이드처럼 갈등한다.",
    backgroundDesc:
      "한 가지의 감정과 단순함을 추구하지만 스스로가 가지고 있는 양가적인 모습과 감정을 모순으로 여기고 없애려 하면서 자신을 부정하게 된다. 자신이 가지고 있는 따뜻함과 공의로운 존중감을 항상 유지하려 애쓴다. 자신이 가지고 있는 아기처럼 돌봄을 받고 싶은 욕구를 유치하다고 생각하며 더 어른스럽고 강한 사람으로 포장하려 든다.",
  },
  37: {
    borderColor: "violet",
    backgroundColor: "blue",
    desc: "평화로움에서 시작의 힘을 가진 자",
    borderDesc:
      "트러블이나 갈등상황이 되면 회피하거나 외면하는 행동을 한다. 대인관계에서 싸울 일을 만들지 않고 혼자 해결한다. 상식의 수준 이 높은 사람들과 어울리며 사람을 가리게 된다. 명품이나 품위 있는 행동을 선호한다. 관심 받는 건 좋아하지만 튀거나 나서진 않아 서 속을 모르겠는 행동을 한다.",
    backgroundDesc:
      "새로운 것에 호기심이 왕성해서 새로운 것을 접할 때 인지 능력이 커진다. 사람과의 소통을 통해 영감과 아이디어를 얻는다. 사회적 만족도가 높아서 주변의 인기나 인정을 받을 때 자신의 능력이 커진다.",
  },
  38: {
    borderColor: "violet",
    backgroundColor: "green",
    desc: "자아성찰과 깨달음을 얻기 위한 자기관찰자",
    borderDesc:
      "자신의 마음과 행동이 불일치 할 때 갈등과 불안 그리고 자기의문을 가지게 된다. 이유를 찾기 위해 고뇌하고 종교나 철학, 심리에 관심을 가진다. 자신의 불안정함과 우울감, 급격한 감정기복, 체력의 한계에서 오는 변덕 등 자신의 모습에서 싫고 이해가 되지 않는 부분을 찾는다.",
    backgroundDesc:
      "새로운 것에 대한 호기심과 새로운 것에 대한 두려움 등 인식에서 오는 충돌과 부딪침이 생기면 자기불신이 생긴다. 양가감정이나 상반된 행동은 누구에게나 올 수 있음을 아는 것이 중요하다.",
  },
  39: {
    borderColor: "violet",
    backgroundColor: "gold",
    desc: "이상적인 변화를 꿈꾸는 변환자",
    borderDesc:
      '자신의 존재의 가치를 위한 삶도 중요하지만 현실의 개혁을 위한 힘의 필요성도 중요하다. "행동하지 않는 지성은 죽은 지식이다." 라는 행동주의 철학을 가지고 있다. 그래서 귀족적인 삶과 권력적인 힘의 필요성을 알고 준비한다. 세상을 고치고 바꾸기 위한 힘이 필요하고 그러기 위해서는 사람이 변해야하고 그 변화를 만들어 내는 변환자의 역할을 한다.',
    backgroundDesc:
      "세상은 공평하지 않다는 인지를 빠르게 한다. 그래서 힘을 가진 사람의 생각의 변화가 중요하다고 한다. 인지하고 힘을 가지고 있을 때 사고방식과 인지능력이 탁월해진다.",
  },
  40: {
    borderColor: "red",
    backgroundColor: "gold",
    desc: "마음은 괴로운데 배는 고프다",
    borderDesc:
      "현실에 충실하며 주어진 환경에 최선을 다한다. 그러나 내면의 가치추구와 현실에 충실한 삶에서 갈등과 무엇이 가치 있는 삶인 지를 고민하며 행동한다. 현실적인 물질과 보상이 중요하지만 그것만으로는 부족하다. 자신의 존재의 이유와 가치를 찾아 헤맨다.",
    backgroundDesc:
      "현실의 물질적 보상과 자신의 존재에 대한 가치 두 가지를 모두 인정받아야 인지와 사고방식에 변화가 생기고 만족하게 된다.",
  },
  41: {
    borderColor: "gold",
    backgroundColor: "gold",
    desc: "변치않는 가치를 추구하는 삶",
    borderDesc:
      "지혜와 가치를 중요하게 여기며 행동하기 때문에 사람의 지식에서는 만족을 느끼기가 어렵다. 스스로의 기준이 높아 웬만한 것엔 감동하거나 칭찬할 일이 별로 없다. 타인의 평가나 인정이 중요하지 않다. 스스로의 인정과 만족이 있어야 한다. 자존감이 높아서 때로는 외롭고 사람을 스스로 멀리하기도 하는 사람이다.",
    backgroundDesc:
      "행동과 인지가 같다. 기준치는 높지만 단순함을 가지고 있어서 생각과 마음이 다르거나 이율배반적인 상황을 이해하기는 어렵다. 그래서 보이는 것과 속이 다른 것을 인지하고 이해하기에 시간이 필요하다. 옳고 그름으로 인지하기 때문에 사람을 가르치려 는 생각이 강하다.",
  },
  42: {
    borderColor: "yellow",
    backgroundColor: "yellow",
    desc: "결과를 위해 최선을 다하는 모범생",
    borderDesc:
      "결과중심의 행동으로 최선의 결과를 만들어내려고 노력한다. 문제가 생기면 자신에서 해법을 찾는다. 목적과 목표가 뚜렷하고 시간과 노력을 헛되이 보내지 않는다. 자신이 잘하려고 노력하기 때문에 능동적으로 행동하고 겉과 속이 같아서 포커페이스가 안 된다. 거짓말을 못하고 어린 아이처럼 순진한 면이 있어서 타인의 칭찬과 인정에 힘을 얻는 모범생이다. 지적추구가 강하고 리더의 자질을 가졌다.",
    backgroundDesc:
      "실패를 과정이라고 인지하는 환경이 필요하다. 긍정적 인식을 통해 상황을 현실적으로 인지하고 사고할 수 있다. 긴장도가 높고 주변의식이 강해서 결과에 연연하다보면 신경이 예민해서 인지적 실수가 많아지면 자책도 심해진다.",
  },
  43: {
    borderColor: "turquoise",
    backgroundColor: "turquoise",
    desc: "사이다처럼 톡쏘는 솔직함을 가진 크리에이티브",
    borderDesc:
      "거침없는 솔직함과 꾸밈없는 행동의 자유로움을 가졌다. 타고난 창의성과 남다르게 보고 해석하는 크리에이티브다. 아이디어가 남다르고 생각이 한 곳에 머무르지 않기 때문에 새로운 환경적 자극과 새로운 사람들을 만나며 성장한다. 여행에서 좋은 영향을 받고 바람에 이끌리듯이 인생을 살아간다. 보이는 것과는 다른 자기만의 안달복달하는 면과 잘하고 성공지향적인 부분이 어우러져 자신만의 독특함을 찾아간다.",
    backgroundDesc:
      "자신을 있는 그대로 바라봐주고 이끌리듯 사는 삶을 수용해주는 환경에서 독창적이고 자기만의 매력을 발산하게 되고 창의성이 뛰어나다.",
  },
  44: {
    borderColor: "lilac",
    backgroundColor: "pale blue",
    desc: "수호천사같은 자",
    borderDesc:
      "존재감만으로도 주변을 변화시키고 나서지는 않지만 솔선수범으로 주변을 응원하고 힘을 준다. 밝고 소년 같은 귀여움과 귀족 같은 사랑을 주고받는다. 에너지가 넘치고 창의적인 발상도 넘친다.",
    backgroundDesc:
      "평화롭고 안정적인 상황에서 자신의 능력 표현이 뛰어나다. 자연을 사랑하고 강요받지 않는 상황에서 타인을 향한 모성애나 이타심이 발현된다.",
  },
  45: {
    borderColor: "turquoise",
    backgroundColor: "magenta",
    desc: "자신의 존재에 대한 감사와 주어진 현실에서 꿈을 실현하는 자",
    borderDesc:
      "자신의 주어진 현실에서 자신의 정체성을 스스로 찾으며 독립적이다. 자유로운 삶을 살기 위해 준비한다. 이상과 현실이 분리되어 있지 않은 삶을 인정하고 행동으로 옮긴다. 포기가 빠르고 다른 방법을 찾는다.",
    backgroundDesc:
      "자유를 얻기 위해서는 힘을 가져야 함을 인지한다. 그래서 현실적인 판단과 사고를 통해 지금 해야 할 일과 미래에 원하는 것을 얻기 위해 지금을 알아차리는 인지가 빠르다.",
  },
  46: {
    borderColor: "green",
    backgroundColor: "magenta",
    desc: "자신이 원하는 것을 찾기 위한 방랑자",
    borderDesc:
      "자신이 진정으로 원하는 것을 찾기 위해 현재 가지고 있는 사회적 지위나 경제적 안정을 버릴 수 있다. 때로는 집시처럼 방랑을 하기도 한다. 자신의 것을 찾는데 시간이 걸리더라도 찾아내는 끈기와 오기를 가지고 있다. 그러나 찾고 난 뒤는 타인에게 많은 영향을 미치는 사람이다. 책이나 자신만의 채널을 통해 소통하는 커뮤니티를 형성하고 책임감을 갖고 운영한다.",
    backgroundDesc:
      "자신의 마음에서 무엇을 원하는 지를 인지하면 행동으로 실행한다. 감사함과 자기보기가 뛰어나다. 스스로를 객관화하는 인지능력을 가지고 있다.",
  },
  47: {
    borderColor: "royal blue",
    backgroundColor: "lemon",
    desc: "타고난 재능의 활용자",
    borderDesc:
      "논리와 직관력을 바탕으로 행동하고 표현하기 때문에 말에 힘이 있다. 무겁지 않은 진지함으로 청중에게 웃음의 카리스마를 보여 준다. 장소, 때를 분별하여 행동하고 말한다.",
    backgroundDesc:
      "논리에 맞는 지식을 습득하거나 배움의 기회가 왔을 때 자신의 기존의 지식과 융합하고 통섭하는 능력이 뛰어나다. 통찰력으로 활용하고 장소, 때에 맞춰 생각하고 인지한다.",
  },
  48: {
    borderColor: "violet",
    backgroundColor: "clear",
    desc: "순수함을 가진 호기심으로 세상을 창조하는 자",
    borderDesc:
      "세상을 학교라 생각하고 실패를 과정이라 여기며 도전한다. 사람에 대한 호기심과 관심이 높다. 처음에는 주변에서 머물며 현실 에서 한 걸음 떨어져서 관찰자 모드를 유지하지만 관찰이 끝나면 적극적으로 행동하고 솔직하게 표현한다.",
    backgroundDesc:
      "하나씩 단계별로 인지한다. 빠른 속도보다는 천천히 꼼꼼히 인지하다보면 어느 순간에 종합적인 인지능력이 생기면 빠르고 남다르게 보고 이해해서 다른 감각으로 새로운 것을 창조한다.",
  },
  49: {
    borderColor: "turquoise",
    backgroundColor: "violet",
    desc: "새로운 메신저",
    borderDesc:
      '자신의 정체성을 찾는 것을 삶의 목적으로 삼고 "나는 누구인 가"의 답을 찾기 위해 방황을 한다. 답을 찾아가는 과정 중에서 타 인에게 파랑새가 되어준다. 자신의 깨달음이나 질문을 사람들과 공유하고 사람들의 의견을 궁금해 한다. 솔직하게 말하고 진심어린 조언을 구별하는 능력이 있다.',
    backgroundDesc:
      "물질의 풍요를 통해 정신적 여유가 생긴다. 사고방식도 환경적인 영향을 크게 받는다. 일과 여가의 균형이 맞을 때 심리적 안정감도 발달하여 자신을 스스로 돌아 볼 수 있다.",
  },
};

export const convertDateToAuraSoma = (date) => {
  const auraSomaNum = date
    .split("")
    .reduce((sum, digit) => sum + parseInt(digit, 10), 0);
  return auraSomaMap[auraSomaNum];
};
