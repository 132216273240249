import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import A4Layout from "../components/a4Layout";
import Layout from "../components/layout";
import UserInfo from "../components/userInfo";
import PdfTitle from "../components/pdfTitle";
import Content from "../components/content";
import CrataInfo from "../components/crataInfo";
import PersonCategory from "../components/personCategory";
import Graph from "../components/graph";
import BehaviorCategory from "../components/behaviorCategory";
import Explanation from "../components/explanation";
import FriendBehaviorCategory from "../components/friendBehaviorCategory";
import Table, { TD, TH, TR } from "../components/table";
import Icon from "../components/icon";
import { colors } from "../styles/colors";
import Solution from "../components/solution";
import SelfAnalysis from "../components/selfAnalysis";
import { useUser } from "../hooks/useUser";
import useScreenSize from "../hooks/useScreenSize";
import { PDF_PADDING, TYPE_MAP } from "../utils/consts";
import { convertDateToAuraSoma } from "../utils/convertToAuraSoma";
import moment from "moment";
import Potential from "../components/potential";
import client from "../api/client";
import FriendBehaviorExplanation from "../components/friendBehaviorExplanation";

export default function Result() {
  const { width, maxWidth } = useScreenSize();
  const [colorSize, setColorSize] = useState(3);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [bottom, setBottom] = useState(0);
  const { userBirthday, userName, resetUser, gender } = useUser();
  const [sajuData, setSajuData] = useState();
  const [graphTitle, setGraphTitle] = useState("");
  const auraSoma = convertDateToAuraSoma(
    moment(userBirthday).format("YYYYMMDD")
  );
  const DEFAULT_LIST = [
    {
      title: "성과만족방식",
      leftType: { alphabet: "S", kr: "개인성장형", en: "elf-growth type" },
      rightType: { alphabet: "G", kr: "조직성장형", en: "roup growh type" },
      selectedType: [],
    },
    {
      title: "정보수집방식",
      leftType: { alphabet: "I", kr: "직관형", en: "ntutive type" },
      rightType: { alphabet: "E", kr: "경험형", en: "xperiential type" },
      selectedType: [],
    },
    {
      title: "능력표현방식",
      leftType: { alphabet: "D", kr: "설계형", en: "esign type" },
      rightType: { alphabet: "T", kr: "기술형", en: "echnical type" },
      selectedType: [],
    },
    {
      title: "목표실행방식",
      leftType: { alphabet: "T", kr: "전술형", en: "actical type" },
      rightType: { alphabet: "S", kr: "전략형", en: "trategic type" },
      selectedType: [],
    },
    {
      title: "목적성취방식",
      leftType: { alphabet: "T", kr: "과제형", en: "ask type" },
      rightType: { alphabet: "R", kr: "관계형", en: "elational type" },
      selectedType: [],
    },
  ];
  const DEFAULT_FRIEND_BEHAVIOR_LIST = [
    {
      title: "자기결정방식",
      leftType: { alphabet: "S", kr: "혼자형", en: "tand-alone type" },
      rightType: { alphabet: "G", kr: "그룹형", en: "roup type" },
      selectedType: [],
    },
    {
      title: "자기방어방식",
      leftType: { alphabet: "L", kr: "논리형", en: "ogical type" },
      rightType: { alphabet: "E", kr: "감성형", en: "motional type" },
      selectedType: [],
    },
  ];
  const [categoryList, setCategoryList] = useState(DEFAULT_LIST);
  const [friendBehaviorList, setFriendBehaviorList] = useState(
    DEFAULT_FRIEND_BEHAVIOR_LIST
  );

  const fetchSaju = async () => {
    try {
      if (userBirthday) {
        const response = await client.get(
          `${process.env.REACT_APP_API_URL}/manse`,
          {
            params: {
              gender,
              birthdayType: "SOLAR",
              birthday: moment(userBirthday).format("YYYYMMDD"),
            },
          }
        );
        setSajuData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const goHome = () => {
    resetUser();
  };

  const handleDownloadPDF = async () => {
    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule(
      "body > div:last-child img { display: inline-block; }"
    );
    const input = document.getElementById("pdf-content");
    if (!input) {
      console.error("pdf-content element not found");
      return;
    }
    await html2canvas(input, {
      scale: 1.5,
      windowWidth: document.body.scrollWidth,
      windowHeight: document.body.scrollHeight,
    }).then((canvas) => {
      let imgData = canvas.toDataURL("image/png");

      let imgWidth = 210;
      let pageHeight = imgWidth * 1.414;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let pdf = new jsPDF("p", "mm");
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 1) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${userName}.pdf`);
    });
  };

  const graphData = [
    { crataData: sajuData?.saju?.dayGround.tenStar },
    { crataData: sajuData?.saju?.monthGround.tenStar },
    { crataData: sajuData?.saju?.yearGround.tenStar },
    { crataData: sajuData?.saju?.daySky.tenStar },
    { crataData: sajuData?.saju?.monthSky.tenStar },
    { crataData: sajuData?.saju?.yearSky.tenStar },
  ];

  useEffect(() => {
    const topCount = graphData.filter(
      (data) => data.crataData === "정관" || data.crataData === "편관"
    ).length;
    setTop(topCount ? topCount + 1 : 0);
    const rightCount = graphData.filter(
      (data) => data.crataData === "상관" || data.crataData === "식신"
    ).length;
    setRight(rightCount ? rightCount + 1 : 0);
    const bottomCount = graphData.filter(
      (data) => data.crataData === "정재" || data.crataData === "편재"
    ).length;
    setBottom(bottomCount ? bottomCount + 1 : 0);
    const leftCount = graphData.filter(
      (data) => data.crataData === "정인" || data.crataData === "편인"
    ).length;
    setLeft(leftCount ? leftCount + 1 : 0);
    const sizeCount = graphData.filter(
      (data) => data.crataData === "비견" || data.crataData === "겁재"
    ).length;
    setColorSize(sizeCount + 1);
    if (sajuData?.saju?.daySky.korean === "갑") setGraphTitle("IG");
    else if (sajuData?.saju?.daySky.korean === "을") setGraphTitle("EG");
    else if (sajuData?.saju?.daySky.korean === "병") setGraphTitle("ID");
    else if (sajuData?.saju?.daySky.korean === "정") setGraphTitle("ED");
    else if (sajuData?.saju?.daySky.korean === "무") setGraphTitle("IB");
    else if (sajuData?.saju?.daySky.korean === "기") setGraphTitle("EB");
    else if (sajuData?.saju?.daySky.korean === "경") setGraphTitle("IH");
    else if (sajuData?.saju?.daySky.korean === "신") setGraphTitle("EH");
    else if (sajuData?.saju?.daySky.korean === "임") setGraphTitle("IA");
    else if (sajuData?.saju?.daySky.korean === "계") setGraphTitle("EA");
  }, [sajuData]);

  useEffect(() => {
    let tempArr = [...DEFAULT_LIST];

    // monthSky
    if (sajuData?.saju.monthSky.tenStar === "정인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("I");
    } else if (sajuData?.saju.monthSky.tenStar === "편인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("E");
    } else if (sajuData?.saju.monthSky.tenStar === "상관") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("D");
    } else if (sajuData?.saju.monthSky.tenStar === "식신") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.monthSky.tenStar === "정재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.monthSky.tenStar === "편재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("S");
    } else if (sajuData?.saju.monthSky.tenStar === "정관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.monthSky.tenStar === "편관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("R");
    }

    // yearSky
    if (sajuData?.saju.yearSky.tenStar === "정인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("I");
    } else if (sajuData?.saju.yearSky.tenStar === "편인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("E");
    } else if (sajuData?.saju.yearSky.tenStar === "상관") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("D");
    } else if (sajuData?.saju.yearSky.tenStar === "식신") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.yearSky.tenStar === "정재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.yearSky.tenStar === "편재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("S");
    } else if (sajuData?.saju.yearSky.tenStar === "정관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.yearSky.tenStar === "편관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("R");
    }

    // dayGround
    if (sajuData?.saju.dayGround.tenStar === "정인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("I");
    } else if (sajuData?.saju.dayGround.tenStar === "편인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("E");
    } else if (sajuData?.saju.dayGround.tenStar === "상관") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("D");
    } else if (sajuData?.saju.dayGround.tenStar === "식신") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.dayGround.tenStar === "정재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.dayGround.tenStar === "편재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("S");
    } else if (sajuData?.saju.dayGround.tenStar === "정관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.dayGround.tenStar === "편관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("R");
    }

    // monthGround
    if (sajuData?.saju.monthGround.tenStar === "정인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("I");
    } else if (sajuData?.saju.monthGround.tenStar === "편인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("E");
    } else if (sajuData?.saju.monthGround.tenStar === "상관") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("D");
    } else if (sajuData?.saju.monthGround.tenStar === "식신") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.monthGround.tenStar === "정재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.monthGround.tenStar === "편재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("S");
    } else if (sajuData?.saju.monthGround.tenStar === "정관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.monthGround.tenStar === "편관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("R");
    }

    // yearGround
    if (sajuData?.saju.yearGround.tenStar === "정인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("I");
    } else if (sajuData?.saju.yearGround.tenStar === "편인") {
      tempArr
        .find((item) => item.title === "정보수집방식")
        .selectedType.push("E");
    } else if (sajuData?.saju.yearGround.tenStar === "상관") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("D");
    } else if (sajuData?.saju.yearGround.tenStar === "식신") {
      tempArr
        .find((item) => item.title === "능력표현방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.yearGround.tenStar === "정재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.yearGround.tenStar === "편재") {
      tempArr
        .find((item) => item.title === "목표실행방식")
        .selectedType.push("S");
    } else if (sajuData?.saju.yearGround.tenStar === "정관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("T");
    } else if (sajuData?.saju.yearGround.tenStar === "편관") {
      tempArr
        .find((item) => item.title === "목적성취방식")
        .selectedType.push("R");
    }
    if (top || right || bottom || left)
      if (
        (top && right === 0 && bottom === 0 && left === 0) || // top만 있을 때
        (top === 0 && right === 0 && bottom && left === 0) || // bottom만 있을 때
        (top && right === 0 && bottom && left === 0) || // top, bottom만 있을 때
        (top && right && bottom && left === 0) || // left만 없을 때
        (top && right === 0 && bottom && left) // right만 없을 때
      ) {
        tempArr
          .find((item) => item.title === "성과만족방식")
          .selectedType.push("G");
      } else if (
        (top === 0 && right && bottom === 0 && left === 0) || // right만 있을 때
        (top === 0 && right === 0 && bottom === 0 && left) || // left만 있을 때
        (top === 0 && right && bottom === 0 && left) || // left, right만 있을 때
        (top === 0 && right && bottom && left) || // top만 없을 때
        (top && right && bottom === 0 && left) // bottom만 없을 때
      ) {
        tempArr
          .find((item) => item.title === "성과만족방식")
          .selectedType.push("S");
      } else {
        tempArr
          .find((item) => item.title === "성과만족방식")
          .selectedType.push("S");
        tempArr
          .find((item) => item.title === "성과만족방식")
          .selectedType.push("G");
      }

    setCategoryList(tempArr);
  }, [top, right, bottom, left]);

  useEffect(() => {
    if (colorSize) {
      let tempArr = [...DEFAULT_FRIEND_BEHAVIOR_LIST];
      if (colorSize < 4)
        tempArr
          .find((item) => item.title === "자기결정방식")
          .selectedType.push("S");
      else if (colorSize === 4) {
        tempArr
          .find((item) => item.title === "자기결정방식")
          .selectedType.push("S");
        tempArr
          .find((item) => item.title === "자기결정방식")
          .selectedType.push("G");
      } else {
        tempArr
          .find((item) => item.title === "자기결정방식")
          .selectedType.push("G");
      }
      if (colorSize > 1) {
        // monthSky
        if (
          sajuData?.saju.monthSky.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.monthSky.tenStar === "비견"
        ) {
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("L");
        } else if (
          sajuData?.saju.monthSky.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.monthSky.tenStar === "겁재"
        )
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("E");

        // yearSky
        if (
          sajuData?.saju.yearSky.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.yearSky.tenStar === "비견"
        ) {
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("L");
        } else if (
          sajuData?.saju.yearSky.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.yearSky.tenStar === "겁재"
        )
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("E");

        // dayGround
        if (
          sajuData?.saju.dayGround.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.dayGround.tenStar === "비견"
        ) {
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("L");
        } else if (
          sajuData?.saju.dayGround.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.dayGround.tenStar === "겁재"
        )
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("E");

        // monthGround
        if (
          sajuData?.saju.monthGround.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.monthGround.tenStar === "비견"
        ) {
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("L");
        } else if (
          sajuData?.saju.monthGround.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.monthGround.tenStar === "겁재"
        )
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("E");

        // yearGround
        if (
          sajuData?.saju.yearGround.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.yearGround.tenStar === "비견"
        ) {
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("L");
        } else if (
          sajuData?.saju.yearGround.color === sajuData?.saju.daySky.color &&
          sajuData?.saju.yearGround.tenStar === "겁재"
        )
          tempArr
            .find((item) => item.title === "자기방어방식")
            .selectedType.push("E");
      }
      setFriendBehaviorList(tempArr);
    }
  }, [colorSize]);

  useEffect(() => {
    fetchSaju();
  }, []);

  const ExplanationItem = ({ title, explanation, selected }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: selected ? " black" : colors.grayBorder,
          paddingRight: 28,
          // wordBreak: "keep-all",
        }}
      >
        <div
          style={{
            fontSize: 28,
            fontWeight: "500",
            padding: 8,
            width: 70,
            textAlign: "center",
          }}
        >
          {title}
        </div>
        <div style={{ fontSize: 13, lineHeight: 1.25, width: "100%" }}>
          {explanation}
        </div>
      </div>
    );
  };

  if (!sajuData) return null;

  return (
    <Layout
      style={{
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width,
          maxWidth,
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: PDF_PADDING,
          boxSizing: "border-box",
          position: "relative",
          top: 36,
          zIndex: 999,
          marginBottom: 40,
        }}
      >
        <Icon
          iconName={"Home"}
          onClick={goHome}
          iconSize={140}
          height={40}
          style={{ cursor: "pointer", marginRight: 12 }}
        />
        <Icon
          iconName={"Download"}
          onClick={handleDownloadPDF}
          iconSize={140}
          height={40}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div id="pdf-content">
        <Icon
          iconName={"Frontcover"}
          width={maxWidth}
          height={maxWidth * 1.4}
        />
        <A4Layout page={1}>
          <PdfTitle />
          <UserInfo />
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "50px 0",
            }}
          >
            <div
              style={{
                backgroundColor: colors.tableBackground,
                width: "100%",
                color: colors.navy,
                padding: "30px 50px",
                boxSizing: "border-box",
                lineHeight: 1.25,
                marginTop: 20,
              }}
            >
              <span style={{ fontWeight: 600, fontSize: 20 }}>CRATA 검사</span>
              <div>
                <div
                  style={{
                    display: "grid",
                    fontSize: 13,
                    marginTop: 14,
                    gridTemplateColumns: "auto auto",
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    C
                    <span style={{ fontWeight: 400, color: "#000" }}>
                      haracter
                    </span>
                  </div>
                  <span style={{ color: "#000" }}>
                    성격은 주어진 상황에서 어떤 행동을 할 것인가를 예측하게
                    한다.
                    <br />
                    성격을 분석하여 개인마다 고유한 행동방식을 찾아준다.
                  </span>
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    R
                    <span style={{ fontWeight: 400, color: "#000" }}>
                      elationship
                    </span>
                  </div>
                  <span style={{ color: "#000" }}>
                    예측한 행동방식으로 대인관계 분석을 한다. 관계 분석을 통해
                    상호이해와 구성원 간의 기질별 성격유형과 성격에 따른
                    행동유형을 분류하여 조직의 성과를 높이도록 돕는다.
                  </span>
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    A<span style={{ fontWeight: 400, color: "#000" }}>ct</span>
                  </div>
                  <span style={{ color: "#000" }}>
                    개인별 성격에 따른 행동방식의 4가지 역량(중심역량, 핵심역량,
                    성장역량, 잠재역량)을 분석하여 개인 및 조직의 강점과 약점을
                    찾아 준다.
                  </span>
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    T
                    <span style={{ fontWeight: 400, color: "#000" }}>
                      emperament
                    </span>
                  </div>
                  <span style={{ color: "#000" }}>
                    유전적 기질을 분석하여 타고난 직무를 찾아준다.
                  </span>
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    A
                    <span style={{ fontWeight: 400, color: "#000" }}>
                      wareness
                    </span>
                  </div>
                  <span style={{ color: "#000" }}>
                    자신의 에너지를 찾아서 능동적인 라이프 설계를 할 수 있도록
                    돕는다.
                    <br />
                    조직의 에너지의 원동력을 찾아서 강점은 높이고 약점은
                    성장시키는 솔루션 을 제시한다.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </A4Layout>
        <A4Layout page={2}>
          <CrataInfo />
        </A4Layout>
        <A4Layout page={3}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Content title={"개인행동"}>
              <PersonCategory graphTitle={graphTitle} />
            </Content>
            <Content
              title={"개인행동 유형 설명"}
              colored
              style={{ marginTop: -8 }}
            >
              <ExplanationItem
                title={"I"}
                explanation={
                  "자신의 마음이 우선이라 자신의 자신의 마음이 흐르는 대로 행동한다."
                }
                selected={graphTitle[0] === "I"}
              />
              <ExplanationItem
                title={"E"}
                explanation={
                  "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다."
                }
                selected={graphTitle[0] === "E"}
              />
            </Content>
            <Content colored style={{ marginTop: -8, height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <ExplanationItem
                  title={"G"}
                  explanation={
                    "자기중심적인 기질을 가지고 있어서 자신의 발전을 위해 배우고 성장하는 것을 좋아하여 대리만족이 안 된다. 새로운 것을 도전하는 힘과 시작하는 주진력이 강하다. 경쟁심이 강하고 자존심이 강하다."
                  }
                  selected={graphTitle[1] === "G"}
                />
                <ExplanationItem
                  title={"D"}
                  explanation={
                    "자신이 속한 조직과 주변을 성장시키는 기질을 가지고 있어서 자신의 가치를 인정받는 것을 위해 정보와 지식을 나누고 그것으로 도움을 주는 것을 좋아하고 대리만족이 가능하다. 성장하고 키워야하는 조직에서 농력발휘가 뛰어나다."
                  }
                  selected={graphTitle[1] === "D"}
                />
                <ExplanationItem
                  title={"B"}
                  explanation={
                    "한쪽으로 치우치지 않는 객관적인 태도로 스스로 2인자로 돕는 역할을 한다. 자신의 말의 신뢰를 받고 싶어 하고 중간자로 서로를 연결하고 성장을 돕는 능력이 뛰어나다."
                  }
                  selected={graphTitle[1] === "B"}
                />
                <ExplanationItem
                  title={"H"}
                  explanation={
                    "답이 확실하고 현실적인 문제를 적극적으로 해결하고 답을 찾는 눙력이 뛰어나서 해결사 역할을 한다. 현실에서 자신의 능력을 최대한 발휘할 수 있는 상황과 기회를 만들어 낸다."
                  }
                  selected={graphTitle[1] === "H"}
                />
                <ExplanationItem
                  title={"A"}
                  explanation={
                    "자신의 준비가 우선이기 때문에 자기의 스타일과 속도대로 움직이기 때문에 이기적으로 나타나기도 한다. 자신이 아는 것을 창의적이고 감각적으로 활용하는 능력이 뛰어나서 대기만성형이다."
                  }
                  selected={graphTitle[1] === "A"}
                />
              </div>
            </Content>
          </div>
        </A4Layout>
        <A4Layout page={4}>
          <Graph
            graphTitle={graphTitle}
            data={sajuData}
            colorSize={colorSize}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            birthDate={userBirthday}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Content title={"행동유형"} style={{ height: "100%" }}>
              <BehaviorCategory categoryList={categoryList} />
            </Content>
          </div>
        </A4Layout>
        <A4Layout page={5}>
          <Content colored title="해설" style={{ height: "100%" }}>
            <Explanation categoryList={categoryList} />
          </Content>
        </A4Layout>
        <A4Layout page={6}>
          <Graph
            graphTitle={graphTitle}
            data={sajuData}
            colorSize={colorSize}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            birthDate={userBirthday}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Content title="경쟁관계 행동유형">
              <FriendBehaviorCategory categoryList={friendBehaviorList} />
            </Content>
            <Content
              colored
              title="집단 행동 설명"
              style={{ height: "100%", marginTop: -8 }}
            >
              <FriendBehaviorExplanation categoryList={friendBehaviorList} />
            </Content>
          </div>
        </A4Layout>
        <A4Layout page={7}>
          {/* <Graph
            graphTitle={graphTitle}
            data={sajuData}
            colorSize={colorSize}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            birthDate={userBirthday}
          /> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Content
              title="정서적 만족 유형"
              bodyStyle={{
                padding: 0,
                margin: "40px 0",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 140,
                      height: 70,
                      backgroundColor: colors[auraSoma?.borderColor],
                      color:
                        auraSoma?.borderColor === "clear" ? "black" : "white",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      paddingBottom: 8,
                      boxSizing: "border-box",
                      borderTopLeftRadius: 999,
                      borderTopRightRadius: 999,
                      border: `1px solid ${
                        auraSoma?.borderColor === "clear"
                          ? "black"
                          : "transparent"
                      }`,
                    }}
                  >
                    {auraSoma?.borderColor}
                  </div>
                  <div
                    style={{
                      width: 140,
                      height: 70,
                      backgroundColor: colors[auraSoma?.backgroundColor],
                      color:
                        auraSoma?.backgroundColor === "clear"
                          ? "black"
                          : "white",
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      paddingTop: 6,
                      boxSizing: "border-box",
                      borderBottomLeftRadius: 999,
                      borderBottomRightRadius: 999,
                      border: `1px solid ${
                        auraSoma?.backgroundColor === "clear"
                          ? "black"
                          : "transparent"
                      }`,
                    }}
                  >
                    {auraSoma?.backgroundColor}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 13,
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  <Icon
                    iconName={"Icon3"}
                    iconSize={13}
                    style={{ marginRight: 6 }}
                  />
                  {auraSoma?.desc}
                </div>
              </div>
            </Content>
            <div
              style={{
                display: "flex",
                flex: 3,
                marginTop: -8,
              }}
            >
              <Table>
                <thead style={{ border: `1px solid ${colors.primary}` }}>
                  <tr>
                    {["행동컬러", "인지컬러"].map((data, index) => (
                      <TH key={index} index={index}>
                        {data}
                      </TH>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ border: `1px solid ${colors.primary}` }}>
                  <tr>
                    {[
                      `행동 : ${auraSoma?.borderColor}`,
                      `인지 : ${auraSoma?.backgroundColor}`,
                    ].map((data, index) => (
                      <TD key={index} style={{ padding: 14 }} index={index}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 16,
                          }}
                        >
                          <div
                            style={{
                              width: 80,
                              height: 80,
                              borderRadius: "100%",
                              backgroundColor:
                                index === 0
                                  ? colors[auraSoma?.borderColor]
                                  : colors[auraSoma?.backgroundColor],
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: 8,
                              height: 8,
                              marginRight: 2,
                              borderRadius: "100%",
                              backgroundColor: "black",
                            }}
                          />
                          {data}
                        </div>
                        {/* <div style={{ marginTop: 10, lineHeight: 1.25 }}>
                        {index
                          ? auraSoma?.borderDesc
                          : auraSoma?.backgroundDesc}
                      </div> */}
                      </TD>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
            <div
              style={{
                borderBottom: `1px solid ${colors.primary}`,
                borderLeft: `1px solid ${colors.primary}`,
                borderRight: `1px solid ${colors.primary}`,
                padding: 16,
                marginBottom: 16,
                display: "flex",
                flexDirection: "column",
                fontSize: 13,
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flex: 1 }}>빛(긍정적 작용)</div>
              <div style={{ display: "flex", flex: 1 }}>
                그림자(부정적 작용)
              </div>
            </div>
          </div>
        </A4Layout>
        <A4Layout page={8}>
          <Graph
            graphTitle={graphTitle}
            data={sajuData}
            colorSize={colorSize}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            birthDate={userBirthday}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Content
              title="기본 역량 분석"
              style={{ height: "100%" }}
              bodyStyle={{ padding: 0 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Table>
                  <thead>
                    <tr>
                      {["역량", "인지", "행동"].map((data, index) => (
                        <TH key={index} index={index}>
                          {data}
                        </TH>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      [
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: 6,
                            }}
                          >
                            <Icon iconName={"Round"} iconSize={12} />
                            <div style={{ width: 6 }} />
                            <Icon iconName={"RoundFilled"} iconSize={12} />
                          </div>
                          <span style={{ fontWeight: 500 }}>중심 역량</span>
                          <span style={{ fontSize: 12 }}>
                            (자신의 뿌리 역할을 하는 능력)
                          </span>
                          <span
                            style={{
                              fontWeight: 500,
                              marginTop: 4,
                            }}
                          >
                            {`${
                              TYPE_MAP[sajuData.saju.yearSky.tenStar].alphabet
                            } ${
                              TYPE_MAP[sajuData.saju.yearGround.tenStar]
                                .alphabet
                            }`}
                          </span>
                        </div>,
                        <div style={{ display: "flex", color: colors.gray }}>
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.yearSky.tenStar].kr}
                          </span>
                          (
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.yearSky.tenStar].alphabet}
                          </span>
                          {TYPE_MAP[sajuData.saju.yearSky.tenStar].en})
                        </div>,
                        <div style={{ display: "flex", color: colors.gray }}>
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.yearGround.tenStar].kr}
                          </span>
                          (
                          <span style={{ fontWeight: 500 }}>
                            {
                              TYPE_MAP[sajuData.saju.yearGround.tenStar]
                                .alphabet
                            }
                          </span>
                          {TYPE_MAP[sajuData.saju.yearGround.tenStar].en})
                        </div>,
                      ],
                      [
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: 6,
                            }}
                          >
                            <Icon iconName={"Star"} iconSize={12} />
                            <div style={{ width: 6 }} />
                            <Icon iconName={"StarFilled"} iconSize={12} />
                          </div>
                          <span style={{ fontWeight: 500 }}>핵심 역량</span>
                          <span style={{ fontSize: 12 }}>
                            (자신의 강점이 되는 능력)
                          </span>
                          <span
                            style={{
                              fontWeight: 500,
                              marginTop: 4,
                            }}
                          >
                            {`${
                              TYPE_MAP[sajuData.saju.monthSky.tenStar].alphabet
                            } ${
                              TYPE_MAP[sajuData.saju.monthGround.tenStar]
                                .alphabet
                            }`}
                          </span>
                        </div>,
                        <div style={{ display: "flex", color: colors.gray }}>
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.monthSky.tenStar].kr}
                          </span>
                          (
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.monthSky.tenStar].alphabet}
                          </span>
                          {TYPE_MAP[sajuData.saju.monthSky.tenStar].en})
                        </div>,
                        <div style={{ display: "flex", color: colors.gray }}>
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.monthGround.tenStar].kr}
                          </span>
                          (
                          <span style={{ fontWeight: 500 }}>
                            {
                              TYPE_MAP[sajuData.saju.monthGround.tenStar]
                                .alphabet
                            }
                          </span>
                          {TYPE_MAP[sajuData.saju.monthGround.tenStar].en})
                        </div>,
                      ],
                      [
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: 6,
                            }}
                          >
                            <Icon iconName={"Triangle"} iconSize={12} />
                          </div>
                          <span style={{ fontWeight: 500 }}>성장 역량</span>
                          <span style={{ fontSize: 12 }}>
                            (자신을 변화 시키는 능력)
                          </span>
                          <span
                            style={{
                              fontWeight: 500,
                              marginTop: 4,
                            }}
                          >
                            {TYPE_MAP[sajuData.saju.dayGround.tenStar].alphabet}
                          </span>
                        </div>,
                        <div></div>,
                        <div style={{ display: "flex", color: colors.gray }}>
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.dayGround.tenStar].kr}
                          </span>
                          (
                          <span style={{ fontWeight: 500 }}>
                            {TYPE_MAP[sajuData.saju.dayGround.tenStar].alphabet}
                          </span>
                          {TYPE_MAP[sajuData.saju.dayGround.tenStar].en})
                        </div>,
                      ],
                    ].map((rowData, index) => (
                      <TR key={index} index={index} totalRow={3}>
                        {rowData.map((data, index) => (
                          <TD
                            key={index}
                            style={{
                              padding: 6,
                            }}
                            index={index}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              {data}
                            </div>
                          </TD>
                        ))}
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Content>
            <div style={{ display: "flex", marginBottom: 16 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                  border: `1px solid ${colors.primary}`,
                  padding: "34px 0",
                  fontSize: 12,
                }}
              >
                <span style={{ fontSize: 14, fontWeight: 500 }}>잠재 역량</span>
                <span>(무의식에 숨겨진 능력)</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 2,
                  borderTop: `1px solid ${colors.primary}`,
                  borderRight: `1px solid ${colors.primary}`,
                  borderBottom: `1px solid ${colors.primary}`,
                }}
              ></div>
            </div>
          </div>
        </A4Layout>
        <A4Layout page={9}>
          <Graph
            graphTitle={graphTitle}
            data={sajuData}
            colorSize={colorSize}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            birthDate={userBirthday}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Content title={"자가분석"} style={{ flex: 1 }}>
              <SelfAnalysis />
            </Content>
            <Content title={"잠재역량"} style={{ marginTop: -8 }}>
              <Potential />
            </Content>
          </div>
        </A4Layout>
        <A4Layout page={10}>
          <Graph
            graphTitle={graphTitle}
            data={sajuData}
            colorSize={colorSize}
            top={top}
            left={left}
            right={right}
            bottom={bottom}
            birthDate={userBirthday}
          />
          <Content title={"솔루션 (에너지 분석)"}>
            <Solution data={sajuData} />
          </Content>
          <Content title={"Memo"} colored style={{ flex: 1, marginTop: -8 }} />
        </A4Layout>
        <Icon
          iconName={"Backcover"}
          width={maxWidth}
          height={maxWidth * 1.414}
        />
      </div>
    </Layout>
  );
}
