import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./useUser";
import {
  FORM_TYPE_ADULT,
  FORM_TYPE_CHILD,
  FORM_TYPE_STUDENT,
  FORM_TYPE_BABY,
} from "../utils/consts";

const EvaluationContext = createContext();

export const EvaluationProvider = ({ children }) => {
  const { evaluationFormType } = useUser();
  const questionList = {
    [FORM_TYPE_ADULT]: [
      [
        {
          question:
            "나는 새로운 일을 시작하려면 망설여지고 시작 자체에 엄두가 나지 않는다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나는 전문가나 선생님의 조언대로 실행하는 편이다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나는 학습을 할 때 이해가 되어야 암기가 된다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나는 사람들에게 이야기 할 때 비유로 이야기하는 게 편하다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나는 전문가나 선생님의 조언을 참고 할 뿐 시키는 대로 하지 않는다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 사람들에게 이야기 할 때 세부적이고 단계적으로 묘사하듯이 이야기한다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 학습을 할 때 이해가 안 되면 단순히 외워서 시험을 보는 게 가능하다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 새로운 일을 시작하거나 정보를 들을 때 전체를 알아야 이해가 된다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나는 새로운 일을 시작하거나 정보를 들을 때 단계별 순서대로 알려줄 때 행동이 가능하다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 시작을 결정할 때 즉흥적이라는 이야기를 주변사람에게 듣는 일이 종종 있다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나는 산을 볼 때 숲을 보는 형이다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나는 물건을 살 때 비교를 많이 해보고 여기저기 발품을 팔아서 사는 형이다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나는 산을 볼 때 나무를 보는 형이다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 여행지에서 모르는 길을 사람에게 물어보면서 찾는 형이다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 여행지에서 모르는 길은 지도나 지도앱을 보고 찾는 형이다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나는 논리적이고 이치에 맞는 이야기를 좋아한다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나는 경험하거나 직접 해 본 사람의 이야기를 좋아한다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나는 무언가를 선택 할 때 느낌으로 선택하는 편이다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나는 무언가를 선택 할 때 자신의 경험을 바탕으로 선택하는 편이다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나는 스스로 시작하는 것보다는 타인이 시작하면 돕는 형이다.",
          value: "잠재역량",
          answer: "",
        },
      ],
      [
        {
          question: "나는 내가 가지고 싶은 걸 고르는 것이 어렵다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나는 일하는 과정 속에서 일이 계획대로 안 되면 자신의 능력이나 판단에 대한 불신이 생긴다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나는 일을 선택할 때 재미있을 것 같은 일이면 돈이 안 돼도 하는 편이다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나는 일을 할 때 한 가지를 깊게 파고드는 형이다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나는 일을 할 때 전체를 이해하려고 노력하는 편이다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나는 일을 할 때 시스템을 만들고 양식을 만들어서 일하는 편이다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나는 일을 할 때 내가 마음에 들 때까지 하려고 한다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나는 일을 할 때 주변 사람으로부터 인정받고 칭찬받기 위해 일하는 편이다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나는 자신이 선택한 것에 대해 주변 사람들에게 잘했는지 아닌지를 확인받으려 한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나는 일을 할 때 시작하면 누군가의 조언이나 의견을 들으면 오히려 일의 능률이 떨어진다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나는 주변 사람들에게 꼼꼼하다는 이야기를 자주 듣는다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나는 손재주가 있다는 이야기를 자주 듣는다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나는 기술자들에게 창의적인 아이디어를 제공하는 형이다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나는 외국어를 습득하는 데 능력이 있다는 이야기를 자주 듣는다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나는 자신의 감정을 숨기지 못하는 편이다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나는 섭섭한 마음을 이야기하는 편이다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나는 일을 할 때 기술자들을 배려하며 일을 진행하는 편이다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나는 일을 할 때 자신의 스케줄과 스타일에 맞춰 일을 진행하는 편이다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나는 언어(외국어 포함) 구사력과 습득력이 좋은 편이다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나는 손으로 만드는 능력이 좋은 편이다.",
          value: "기술형",
          answer: "",
        },
      ],
      [
        {
          question: "나는 '일단'이라는 말을 자주 사용한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나는 조직에서 선 실행, 후 보고가 가능한 환경을 선호 한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나는 나의 노후를 생각하며 일을 하는 편이다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나는 현재하는 일은 나중에 잘 살기위한 투자라고 생각한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나는 성과가 나지 않는 과정은 쓸데없다고 생각한다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나는 회사에서 승진보다는 결과에 따른 보상으로 주식이나 현장의 실무자가 되는 것을 선호한다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나는 공부를 할 때 답이 명확한 과목(수학, 화학, 물리 등)을 선호한다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나는 계획을 짤 때 타임스케줄을 먼저 정하고 무엇을 할 건지 정하는 편이다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나는 문제 해결을 위해 전체를 이해하고 문제를 해결하는 편이다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나는 일하는 스타일이 현장에서 직접 부딪치는 것을 선호한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나는 노후 준비를 위해 연금과 보험을 준비하는 형이다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나는 일을 할 때 멀리보고 장기적으로 계획을 세워 일하는 편이다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나는 확실한 답을 듣고 움직이는 것을 선호한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나는 결과가 즉각적으로 나오는 것을 선호한다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나는 답을 앉아서 기다리는 것보다 찾아가서 빠르게 듣는 것을 더 선호한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나는 인내와 기다림이 더 큰 결과를 가져다준다고 생각한다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나는 대학을 선택하거나 진로를 선택할 때 점수나 현실에 맞춰서 선택하는 편이다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나는 돈은 명예나 일의 성과에 따라 온다고 생각한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나는 일을 시작할 때 돈이나 현실적인 계산보다는 내가 목적하는 것을 더 중요하다고 생각한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나는 안정된 월급을 받는 일보다 앞으로 성장 가능성이 있는 일을 더 선호한다.",
          value: "전략형",
          answer: "",
        },
      ],
      [
        {
          question:
            "나는 책을 선택할 때 소설책보다는 위인전이나 자서전을 더 선호하는 편이다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나는 나의 전공에 맞는 일을 하려고 노력하는 편이다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나는 이직을 할 때 친한 선배나 신뢰하는 윗사람에게 영향을 받는 경우가 많다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나는 내가 하는 일이 자신과 안 맞을 때 이직을 진지하게 고민하게 된다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나는 조직생활에서 승진이나 권력에 대한 욕구가 없다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나는 어릴 때 나의 꿈이나 하고 싶은 일이 확실한 편이였다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나는 어릴 때 내가 좋아하는 선생님의 과목의 성적을 잘 받았다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나는 내가 맡은 일은 상황이 변해도 직접 마무리하고 싶어 한다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나는 부서이동을 실행하기 전에 선배나 믿을 만한 상사에게 조언을 구하는 편이다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나는 조직에서 라인과 인맥을 중요하게 여긴다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나는 조직에서 자신이 하는 일이 명확하지 않으면 스트레스를 많이 받는 편이다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나는 조직에서 윗사람의 역량에 따라 성과의 차이가 크다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나는 조직에서 윗사람하고의 친밀한 관계형성에 어려움을 자주 느낀다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나는 어릴 때부터 나의 꿈이나 이상형이 없었다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나는 권력이나 인맥을 조직생활에서 1순위로 생각한다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나는 무엇을 할지가 정해져야 다른 것들도 계획을 세울 수가 있다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나는 조직에서 기획력이나 분석력이 좋다는 이야기를 자주 듣는다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나는 일을 열심히 하면 금전적 보상이 따라 온다고 생각한다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나는 일을 할 때, 상사의 응원이나 칭찬이 반드시 필요하다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나는 일을 선택 할 때, 일의 가치가 중요하다.",
          value: "과제형",
          answer: "",
        },
      ],
      [
        {
          question:
            "나는 어릴 때부터 대인관계에서 집착하거나 무관심한 상태로 중간관계가 잘 안 된다.",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
        {
          question:
            "나는 일을 준비할 때, 혼자 하는 것 보다 함께 하는 것이 좋다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나는 일을 준비할 때, 혼자 준비하는 것이 더 편하고 좋다.",
          value: "혼자형",
          answer: "",
        },
        {
          question:
            "나는 주변사람들에게 언제 거절하고 언제 들어주는 것이 좋은지 구별하기가 어렵다.",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
        {
          question: "나는 일을 시작할 때, 주변에 먼저 알리고 준비한다.",
          value: "그룹형",
          answer: "",
        },
        {
          question:
            "나는 주변사람들에게 거절을 못해서 후회할 때가 종종 생긴다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나는 어릴 때 또래집단에서 겉도는 기분이 자주 들었고 친구의 필요성을 못 느꼈다.",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
        {
          question:
            "나는 나보다 나이가 많은 사람들과 일하는 게 어린 사람들과 일하는 것보다 편하다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나는 마음이 내키지 않거나 상황이 안 되면 동료가 부탁해도 거절하는 편이다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나는 주변사람과 나 자신을 비교를 잘하지 않는다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나는 팀에서 팀원들과 성향이 맞지 않아도 신경 안 쓴다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나는 친구가 하자고 하면 일단 함께 하는 편이다.",
          value: "그룹형",
          answer: "",
        },
        {
          question:
            "나는 공부를 하거나 프로젝트를 할 때 그룹으로 여러 사람이 함께하는 게 더 도움이 된다.",
          value: "그룹형",
          answer: "",
        },
        {
          question:
            "나는 대인관계에서 사람들과 자연스럽게 거리유지를 잘하는 편이다.",
          value: "논리형",
          answer: "",
        },
        {
          question:
            "나는 주변 사람이 말하지 않아도 나의 마음을 잘 알아주길 바라는 마음이 크다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나는 친구가 돈을 빌려 달라고 할 때, 마음은 불편하더라도 거절한다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나는 혼자서도 잘 노는 편이다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나는 빌려주는 것보다 차라리 주는 것이 마음이 더 편하다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나는 내 것을 주변에 잘 빌려준다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나는 주변 사람과 어떻게 지내면 좋을지 상황 판단이 어려울 때가 종종 있다.",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
      ],
    ],
    [FORM_TYPE_STUDENT]: [
      [
        { question: "학생성인직관형 질문1", value: "직관형", answer: "" },
        { question: "직관형 질문2", value: "직관형", answer: "" },
        { question: "경험형 질문1", value: "경험형", answer: "" },
        { question: "경험형 질문2", value: "경험형", answer: "" },
        { question: "잠재역량 질문1", value: "잠재역량", answer: "" },
      ],
      [
        { question: "설계형 질문1", value: "설계형", answer: "" },
        { question: "설계형 질문2", value: "설계형", answer: "" },
        { question: "기술형 질문1", value: "기술형", answer: "" },
        { question: "기술형 질문2", value: "기술형", answer: "" },
        { question: "잠재역량 질문1", value: "잠재역량", answer: "" },
      ],
      [
        { question: "전술형 질문1", value: "전술형", answer: "" },
        { question: "전략형 질문1", value: "전략형", answer: "" },
        { question: "잠재역량 질문1", value: "잠재역량", answer: "" },
      ],
      [
        { question: "과제형 질문1", value: "과제형", answer: "" },
        { question: "관계형 질문1", value: "관계형", answer: "" },
        {
          question: "잠재역량 질문1",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "잠재역량 질문1",
          value: "잠재역량",
          answer: "",
        },
      ],
      [
        { question: "혼자형 질문1", value: "혼자형", answer: "" },
        { question: "그룹형 질문1", value: "그룹형", answer: "" },
        { question: "논리형 질문1", value: "논리형", answer: "" },
        { question: "감성형 질문1", value: "감성형", answer: "" },
        {
          question: "잠재역량 질문1",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
        {
          question: "잠재역량 질문1",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
      ],
    ],
    [FORM_TYPE_CHILD]: [
      [
        {
          question:
            "나의 자녀는 재미있는 것이 아니면 새로운 것에 관심을 갖지 않는다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 하지 말라는 행동은 대체로 안하는 편이다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 논리적으로 이야기하면 설득이 된다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 새로운 사람에게 거부감이 없다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 직접 경험하게 해줘야 대화가 잘된다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 새로운 사람에게 낯가림이 있다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 하지 말라고 말해도 자기가 하고 싶으면 하는 편이다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 보호자에게 허락을 받고 행동하는 편이다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 새로운 음식도 잘 먹는다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 새로운 음식은 잘 먹으려 하지 않는다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 읽던 책을 반복해서 읽는다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 여행지에서 모르는 사람에게 먼저 다가가 말을 거는 것을 어려워하지 않는다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 어려워하는 문제를 옆에서 도와주는 사람을 좋아한다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자녀가 해 본 것 위주로 설명하면 말을 잘 듣는다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 어려워하는 문제를 직접 해결해 주는 사람을 믿고 따른다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 해야 할 일을 왜 해야 하는지 이유를 알려주고 납득이 되어야 할 일을 한다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 책으로 학습하는 것 보다는 체험학습이 더 효과적이다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 안 먹는 음식도 건강에 얼마나 좋은 지를 설명해주면 먹는다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 혼날 걸 알면서도 하지 말라는 행동을 종종 한다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 새로운 경험을 시도할 때 보호자에게 의존을 많이 한다.",
          value: "잠재역량",
          answer: "",
        },
      ],
      [
        {
          question:
            "나의 자녀는 주변 사람들에게 칭찬을 받기 위한 행동을 자주 한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 “너가 원하는 게 뭐야”라고 물으면 “모르겠어요”라고 대답하는 경우가 많다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구를 사귈 때 말과 행동을 이쁘게 하는 친구를 사귄다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 이야기를 듣는 중에 궁금한 게 생기면 바로 질문하고 답을 들으려 한다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구나 주변 사람들의 이야기를 끝까지 듣고 자신이 궁금한 것을 묻는다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자기의 기분이나 느낌을 말로 잘 표현한다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 관심 있고 잘하는 것에 대해 이야기를 시작하면 끝이 없다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 평소에 자신이 원하는 걸 결정을 하는데 시간이 오래 걸리거나 선택을 못한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 평소에 “나는 못 하잖아요”라는 말을 자주한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 거짓말을 하면 티가 많이 난다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 언어능력 보다는 손재주가 더 있다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어릴 때부터 자신이 하던 것을 끝까지 마무리하는 형이다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 창의적이고 자신의 아이디어를 그림으로 표현하는 능력이 뛰어나다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 어릴 때부터 다재다능해서 뭐든지 곧잘 한다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 블록 레고 놀이를 좋아한다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구를 다양하게 사귄다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 하고 싶은 게 많다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 과제에 대한 집중력이 좋다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 어른들하고 대화가 가능하다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구를 사귈 때 단짝을 좋아한다.",
          value: "기술형",
          answer: "",
        },
      ],
      [
        {
          question:
            "나의 자녀는 '언제'라는 말을 자주 사용한다. 예시-언제 갈거에요?/언제 해줄거에요?",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 하고 싶은 행동은 하지 못하게 하면 몰래 라도 하는 편이다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어른이 돼서 돈을 많이 벌고 싶다고 종종 이야기 한다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 돈에 관심이 많아 돈 많이 버는 직업을 하겠다고 한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 결과가 나와야 인정하고 받아들인다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 내일 할 일이 있으면 오늘부터 걱정하거나 미리 준비한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 내일보다는 지금에 충실하다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 확실하고 답이 정해진 과목을 잘한다 예시-수학,물리,화학",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 지금보다 나중의 보상이 크다면 끝까지 참는다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 결과를 기다리는 것을 싫어하고 힘들어한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 숙제나 자신의 할 일을 해놓고 논다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 현실 가능한 계획을 세워 실행하는 형이라 걱정이 안된다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어른이 되면 어떻게 살 건지에 대해 이야기를 자주 한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 여행할 때 '언제' 가느냐를 먼저 묻는다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 할지 말지, 갈지 말지 등 대답을 빨리 들으려고 재촉한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 부모의 재력에 관심을 가진다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 작은 목표부터 하나씩 나눠서 주면 어려운 문제도 잘 해낸다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 무언가 하고 싶을 때 부모의 경제력을 확인하거나 눈치를 본다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 주어진 일을 언제까지 끝낼 건지에 대해 물으면 대답을 회피한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 내일 체험을 하러 가려면 전날 준비해놓고 일찍 자는 형이다.",
          value: "전략형",
          answer: "",
        },
      ],
      [
        {
          question: "나의 자녀는 어른들의 말을 잘 듣는 편이다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 규칙을 잘 지키는 편이다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 롤 모델을 정해놓고 행동을 따라 한다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 책임감이 강하다는 이야기를 주변사람들에서 자주 듣는다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 무엇을 해야 할지 미리 알지 못하면 불안해하거나 자꾸 확인한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 약속을 잘 지킨다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 선생님이 좋으면 그 과목의 성적도 높다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신의 생각이 정해지면 행동한다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 신학기가 되면 자신의 담임선생님이 누구인지부터 알고 싶어 한다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 어른들에게 인정받으려고 노력한다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 선생님이나 어른들에게 크게 영향을 받지 않고 행동한다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 리더가 되고 싶어 한다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 해야 하는 일이 무엇인지부터 알고 싶어 한다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 “니 꿈이 뭐야?”라고 물으면 “잘 모르겠어요”라고 대답한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 끌어주는 사람에 따라 생각이나 행동의 변화가 크다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 융통성이 부족한 편이다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 정해진 규칙보다는 자신의 룰을 만들어 행동한다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 맡은 일에 책임을 지려고 노력한다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 위인전을 좋아하고 자주 읽는다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 공부(좋은 직업)를 잘하면 부자가 된다고 생각한다.",
          value: "과제형",
          answer: "",
        },
      ],
      [
        {
          question:
            "나의 자녀는 또래 사이에서 관계를 어떻게 맺는지 모르는 것 같아 걱정이 된다.",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 친구들에게 영향을 많이 받는다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나의 자녀는 신학기 시작 전 사전준비가 필요하다.",
          value: "혼자형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 또래집단에서 언제 거절해야 하는지 모르는 것 같다.",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 친구를 제일 좋아하는 것 같다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나의 자녀는 주변사람에게 거절을 못하고 받아주는 편이다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 또래 사이에서 경쟁하는 법을 잘 모르는 것같이 보인다.",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구가 기분 나쁠까 봐, 하고 싶은 이야기를 못하는 경우가 자주 있다.",
          value: "감성형",
          answer: "",
        },
        {
          question: "나의 자녀는 어리지만 또래사이에서 관계를 잘하는 편이다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 자녀는 혼자서 생각할 때가 종종 있다.",
          value: "혼자형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구와 맞지 않아도 신경을 별로 안 쓰는 것처럼 보인다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구들하고 있을 때 더 적극적이고 활기가 있다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구사이에 승부욕이 있는 편이다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나의 자녀는 상황을 논리적으로 이해하고 결정하는 형이다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 자녀는 억울해하거나 속상하면 잘 운다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구가 부탁을 해도 자기가 내키지 않으면 거절한다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 자녀는 혼자서도 잘 논다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구에게 먼저 다가가는 사교성이 좋은 편이다.",
          value: "논리형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 또래사이에서 자신의 의견을 솔직하게 표현하지 못하는 편이다.",
          value: "감성형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구들과 함께 협동할 때 소극적으로 행동한다.",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
      ],
    ],
    [FORM_TYPE_BABY]: [
      [
        {
          question:
            "나의 자녀를 새로운 환경에 적응시킬 때 부모나 양육자가 항상 따라가야만 한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀를 새로운 환경에 적응시킬 때 자신이 이해되도록 설명해주어야 한다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 부모나 양육자의 기분이나 표정을 살핀다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 실내 활동이나 이야기하면서 노는 것을 좋아한다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 놀이터나 놀이공간에서 놀 때 여러 가지 기구를 모두 타고 논다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀를 새로운 환경에 적응시키려면 부모나 보호자가 따라 가주면 그 이후에는 잘 적응한다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 경험해 본 것 위주로 노는 것을 좋아한다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 부모나 양육자의 지시나 설명을 잘 따른다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 직접 경험해야 적극적으로 놀이에 참여한다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 도전적인 활동이 처음이라도 시도해본다.(예:높이 올라가기, 외다리건너기)",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 첫 도전이라도 겁내지 않고 쉽게 도전한다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 부모나 양육자의 감정에 영향을 잘 받지 않는다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 놀 때 에너지가 넘친다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 첫 도전을 어려워하지만 나중에는 괜찮아진다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 새로운 사람에게 두려움이 없는 편이다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 이야기하는 것을 좋아한다.",
          value: "직관형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 낯선 사람을 보면 처음에는 경계가 높지만 시간이 지나면 좋아진다.",
          value: "경험형",
          answer: "",
        },
        {
          question: "나의 자녀는 선생님을 가리지 않는다.",
          value: "직관형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신감이 생기면 그 이후에는 알아서 잘한다.",
          value: "경험형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 낯선 사람에게 항상 경계가 심하고 잘 변화되지 않는다.",
          value: "잠재역량",
          answer: "",
        },
      ],
      [
        {
          question:
            "나의 자녀는 원하는대로 안되었을 때 짜증을 내거나 울면서 이야기 할 때가 자주있다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 원하는 것에 대해 요구하는 게 별로 없고 해주는 대로 잘 따른다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 원하는대로 안되었을 때 자신의 요구사항을 잘 표현한다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 원하는대로 안되었을 때 자신이 원하는대로 될 때까지 계속해서 요구한다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 원하는 것이 여러 가지이고 작은 부분까지 해주기를 바란다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신의 컨디션이 나쁠 때도 감정 기복이 그렇게 심하지 않다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자기가 원하는 것이 단순하고 명확하다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 마트에 가서 갖고 싶은 걸 고르라고 하면 망설이다가 결국 선택을 못한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 혼나는 상황에서 왜 그런 행동을 했는지 물으면 대답을 안 하고 침묵한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 한 친구와 꾸준히 노는 편이다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 레고나 조립식 장난감을 좋아한다.",
          value: "기술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구들과 놀 때 자기와 성향이 비슷한 친구하고만 논다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구들과 놀 때 잘 웃고 즐겁게 활동한다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 미술수업 중에서 그리기를 좋아한다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신이 원하는 것을 정확하게 요구한다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구과 놀 때 친구들을 잘 설득한다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구들과 놀 때 다양한 친구들과 함께 노는 것을 좋아한다.",
          value: "설계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신의 감정을 참았다가 폭발하듯 표현한다.",
          value: "기술형",
          answer: "",
        },
        {
          question: "나의 자녀는 새로운 친구에게 쉽게 다가가서 먼저 말을 건다.",
          value: "설계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 원하는 것만 제공하면 대체로 키우기가 수월하다.",
          value: "기술형",
          answer: "",
        },
      ],
      [
        {
          question:
            "나의 자녀는 심부름을 시킬 때 돈을 주거나 자기가 원하는 것을 주어야 한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 활동체험을 할 때 스티커 북 채우기를 좋아한다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 원하는 것을 얻기 위해 참고 기다릴 수 있다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 안 풀리는 문제도 풀릴 때까지 계속 반복해서 문제를 푼다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어떻게 하면 자신이 원하는 것을 빠르게 얻을 수 있을지 계산이 빠르다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 빨리 어른이 되고 싶다고 말한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 한글공부보다 숫자공부를 더 좋아하고 잘한다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 지금 안 되는 일에 대해 빠르게 포기한다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 선물을 고르라고 하면 자기가 원하는 것이 없을 때 나중에 사달라고 한다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 지금 안 되는 일이 언제까지 기다리면 해 줄 수 있는지 묻고 기다린다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 소풍이나 여행을 가기 전에 미리 자신의 가방을 챙겨놓는다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 과제를 끝내고 보상받는 것을 좋아한다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어느 곳에 가도 잘 살 것 같다는 생각이 들게 행동한다.",
          value: "전략형",
          answer: "",
        },
        {
          question: "나의 자녀는 어른들 일에 관심이 많다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 보상이 즉시 주어졌을 때 주어진 과제를 끝까지 해낸다.",
          value: "전술형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 앞으로 엘리트교육(국제학교 등)이 필요할 것 같다.",
          value: "전략형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 선물을 고르라고 하면 당장 살 수 있는 것을 고른다.",
          value: "전술형",
          answer: "",
        },
        {
          question: "나의 자녀는 어른들 일에 관심을 갖지 않는다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 숫자개념에 관심이 없다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 많은 경제적 지원이 필요할 것 같다.",
          value: "전략형",
          answer: "",
        },
      ],
      [
        {
          question: "나의 자녀는 고집을 피울 때 말로 조율이 가능하다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어른들의 말보다는 자신의 주장대로 행동하는 편이다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 좋아하는 어른이 하지 말라고 하면 안한다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자기 고집대로 행동한다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신의 생각을 잘 이야기하지 못한다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 하겠다고 한 약속은 반드시 지키려고 한다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자기가 좋아하는 어른과 좋아하지 않는 어른에게 하는 행동이 많이 다르다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어른들이 자신에게 한 약속을 잊지 않고 기억한다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 위인전 같은 인물이 나오는 동화책을 좋아한다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신이 좋아하는 어른들의 행동을 따라한다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 과학책이나 사람의 신체등 사실적인 지식 책을 좋아한다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신의 생각과 다를 때 조율이 가능하다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신의 생각과 다를 때 조율이 잘 안 된다.",
          value: "과제형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 나이가 많은 어른들을 어려워하거나 불편해 하지 않는다.",
          value: "잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 하겠다고 했어도 자신이 믿고 좋아하는 어른의 말은 듣는다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 하겠다고 한 것에 대해서는 누구말도 잘 안 듣는다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 롤모델이 필요한 아이인 것 같다.",
          value: "관계형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 자신이 맞다고 주장하면 설득해도 소용이 없을 때가 많다.",
          value: "과제형",
          answer: "",
        },
        {
          question: "나의 자녀는 어른들에게 예의가 바르고 잘 따른다.",
          value: "관계형",
          answer: "",
        },
        {
          question: "나의 자녀는 어른들을 어려워한다.",
          value: "과제형",
          answer: "",
        },
      ],
      [
        {
          question: "나의 자녀는 모든 친구에게 다 똑같이 잘 한다.",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 외로움을 잘 느끼고 혼자 있는 것을 싫어한다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나의 자녀는 혼자서 깊게 생각한 후 이야기를 한다.",
          value: "혼자형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구나 형제가 혼나는 것을 봐도 자신의 행동에 별다른 변화가 없다.",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
        {
          question: "나의 자녀는 친구들과 함께 있을 때 도전을 더 잘 한다.",
          value: "그룹형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구나 형제가 혼나는 것을 보면 자기가 먼저 운다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구가 자신 때문에 스트레스 받는 것을 이해하지 못한다.",
          value: "자기방어방식-잠재역량",
          answer: "",
        },
        {
          question:
            "나의 자녀는 불쌍한 동물을 보면 자신의 일을 미루고도 돌봐주거나 도움을 주는 행동을 한다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구나 형제가 혼나는 것을 보면 자기는 혼나지 않으려고 행동한다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 자녀는 혼자서도 재밌게 잘 논다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나의 자녀는 혼자서 무엇을 하는지 무언가를 늘 한다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구를 너무 좋아한다.",
          value: "그룹형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 친구들이 자신에게 한 이야기에 신경을 많이 쓴다.",
          value: "그룹형",
          answer: "",
        },
        {
          question: "나의 자녀는 게임이나 경쟁에서 지면 화를 심하게 낸다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 자녀는 같이 놀자고 하는 친구를 끼워준다.",
          value: "감성형",
          answer: "",
        },
        {
          question:
            "나의 자녀는 어른들에게도 자신이 싫으면 싫다고 표현을 잘하는 편이다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신이 잘 아는 게임은 친구들과 함께 한다.",
          value: "혼자형",
          answer: "",
        },
        {
          question: "나의 자녀는 자신이 하기 싫으면 친구의 부탁도 거절한다.",
          value: "논리형",
          answer: "",
        },
        {
          question: "나의 친구는 친구나 형제가 부탁하면 거절을 못한다.",
          value: "감성형",
          answer: "",
        },
        {
          question: "나의 자녀는 친구나 형제 때문에 스트레스를 많이 받는다.",
          value: "의사결정방식-잠재역량",
          answer: "",
        },
      ],
    ],
  };

  const [testQuestion, setTestQuestion] = useState(
    questionList[evaluationFormType]
  );

  const resetQuestion = () => {
    setTestQuestion(undefined);
  };

  useEffect(() => {
    setTestQuestion(questionList[evaluationFormType]);
  }, [evaluationFormType]);

  return (
    <EvaluationContext.Provider
      value={{
        testQuestion,
        setTestQuestion,
        resetQuestion,
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
};

export const useEvaluation = () => useContext(EvaluationContext);
