import { useEffect, useState } from "react";

export default function FriendBehaviorExplanation({ categoryList }) {
  const DEFAULT_LIST = [
    {
      title: "자기결정방식",
      desc: "",
    },
    {
      title: "자기방어방식",
      desc: "",
    },
  ];

  const [explanation, setExplanation] = useState(DEFAULT_LIST);

  useEffect(() => {
    setExplanation((prev) => {
      if ([...new Set(categoryList[0].selectedType)].length === 2)
        prev[0].desc = (
          <span>
            상황에 따라 혼자형과 그룹형 두 가지 행동방식을 사용한다.
            <br />
            - 혼자형(Stand-alone type) <br />
            자신의 성장과 그것을 준비하는 과정에서 스스로 찾고 스스로에게 물으며
            답을 찾는 과정을 혼자 하는 형이며 준비가 된 후 주변의 도움을 받거나
            함께 해 나갈 때 효과적이고 시너지가 높아진다.
            <br />- 그룹형(Group type)
            <br />
            자신의 성장과 그것을 준비하는 과정에서 함께 찾고 함께 경쟁하며 그
            과정 중에서 그룹으로 함께하는 형이며 상호관계사이에서 인사이트를
            얻고 성장하며 시너지가 높아진다.
          </span>
        );
      else if (categoryList[0].selectedType.includes("S"))
        prev[0].desc =
          "자신의 성장과 그것을 준비하는 과정에서 스스로 찾고 스스로에게 물으며 답을 찾는 과정을 혼자 하는 형이며 준비가 된 후 주변의 도움을 받거나 함께 해 나갈 때 효과적이고 시너지가 높아진다.";
      else if (categoryList[0].selectedType.includes("G"))
        prev[0].desc =
          "자신의 성장과 그것을 준비하는 과정에서 함께 찾고 함께 경쟁하며 그 과정 중에서 그룹으로 함께하는 형이며 상호관계사이에서 인사이트를 얻고 성장하며 시너지가 높아진다.";
      if ([...new Set(categoryList[1].selectedType)].length === 2)
        prev[1].desc = (
          <span>
            - 논리형(Logical type)
            <br />
            현실적인 상황을 기반으로 외적자극에 민감하기 때문에 관계에서 자신의
            상황을 우선으로 생각하고 거절이 가능하다.
            <br />
            -감성형(Emotional type)
            <br />
            마음의 상태를 기반으로 내적자극에 민감하다. 자신이나 타인의 마음을
            우선시하는 등 관계를 우선시하기에 거절이 어렵다.
          </span>
        );
      else if (categoryList[1].selectedType.includes("L"))
        prev[1].desc =
          "현실적인 상황을 기반으로 외적자극에 민감하기 때문에 관계에서 자신의 상황을 우선으로 생각하고 거절이 가능하다.";
      else if (categoryList[1].selectedType.includes("E"))
        prev[1].desc =
          "마음의 상태를 기반으로 내적자극에 민감하다. 자신이나 타인의 마음을 우선시하는 등 관계를 우선시하기에 거절이 어렵다.";

      return [...prev];
    });
  }, [categoryList]);

  return (
    <div
      style={{
        fontSize: 13,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {explanation?.map(({ title, desc }) => (
        <div
          key={title}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            lineHeight: 1.25,
          }}
        >
          <div>· {title} :</div>
          <div style={{ marginLeft: 10, marginTop: 4 }}>{desc}</div>
        </div>
      ))}
    </div>
  );
}
