import React from "react";
import { Routes, Route } from "react-router-dom";
import Result from "./pages/result";
import Login from "./pages/login";
import { UserProvider } from "./hooks/useUser";
import { EvaluationProvider } from "./hooks/useEvaluation";
import EvaluationForm from "./pages/evaluationForm";
import TempLogin from "./pages/tempLogin";
import { AutoFormProvider } from "./hooks/useAutoForm";
import AutoResult from "./pages/autoResult";

const App = () => {
  return (
    <AutoFormProvider>
      <UserProvider>
        <EvaluationProvider>
          <div
            style={{
              width: "100%",
              height: "100vh",
            }}
          >
            <Routes>
              <Route path="/" element={<TempLogin />} />
              <Route path="/info" element={<Login />} />
              <Route path="/form" element={<EvaluationForm />} />
              <Route path="/result" element={<Result />} />
              <Route path="/auto-result" element={<AutoResult />} />
            </Routes>
          </div>
        </EvaluationProvider>
      </UserProvider>
    </AutoFormProvider>
  );
};

export default App;
