import { colors } from "../styles/colors";

export default function TopLeftLine({ width, outWidth, top, left }) {
  return (
    <div
      style={{
        position: "absolute",
        top: width / 2 - (top * outWidth) / 14,
        left: width / 2 - (left * outWidth) / 14,
        zIndex: 30,
      }}
    >
      <svg
        width={(left * outWidth) / 14}
        height={(top * outWidth) / 14}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${(left * outWidth) / 14} ${(top * outWidth) / 14}`}
      >
        <line
          x1={(left * outWidth) / 14}
          y1="0"
          x2="0"
          y2={(top * outWidth) / 14}
          stroke={colors.graphLine}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
