export const MAX_SCREEN_WIDTH = 720;
export const PDF_PADDING = 60;
export const DATE_FORMAT = "YYYY.MM.DD.";
export const BE_DATE_FORMAT = "YYYY.MM.DD.";
export const FORM_TYPE_ADULT1 = "대학생&성인용";
export const FORM_TYPE_ADULT = "대학생&기업용";
export const FORM_TYPE_STUDENT = "중 · 고등학생용";
export const FORM_TYPE_YOUTH = "초4학년 ~ 중2학년";
export const FORM_TYPE_CHILD1 = "초등저학년용";
export const FORM_TYPE_CHILD = "만5세 ~ 초3학년";
export const FORM_TYPE_BABY1 = "영유아용 ";
export const FORM_TYPE_BABY = "영유아용";
export const TYPE_MAP = {
  정인: { alphabet: "I", kr: "직관형", en: "ntutive type" },
  편인: { alphabet: "E", kr: "경험형", en: "xperiential type" },
  상관: { alphabet: "D", kr: "설계형", en: "esign type" },
  식신: { alphabet: "T", kr: "기술형", en: "echnical type" },
  정재: { alphabet: "T", kr: "전술형", en: "actical type" },
  편재: { alphabet: "S", kr: "전략형", en: "trategic type" },
  정관: { alphabet: "T", kr: "과제형", en: "ask type" },
  편관: { alphabet: "R", kr: "관계형", en: "elational type" },
  비견: { alphabet: "L", kr: "논리형", en: "ogical type" },
  겁재: { alphabet: "E", kr: "감성형", en: "motional type" },
};
export const PERSON_TYPE_MAP = {
  IG: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 자기중심적인 기질을 가지고 있어서 자신의 발전을 위해 배우고 성장하는 것을 좋아하여 대리만족이 안 된다. 새로운 것을 도전하는 힘과 시작하는 추진력이 강하다. 경쟁심이 강하고 자존심이 강하다.",
  EG: "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 자기중심적인 기질을 가지고 있어서 자신의 발전을 위해 배우고 성장하는 것을 좋아하고 주변에 인정받고자하는 욕구가 강하고 대리만족은 어렵다. 주변사람들에게 영향을 많이 받는다.",
  ID: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 자신이 속한 조직과 주변을 성장시키는 기질을 가지고 있어서 자신의 가치를 인정받는 것을 위해 정보와 지식을 나누고 그것으로 도움을 주는 것을 좋아하고 대리만족이 가능하다. 성장하고 키워야하는 조직에서 능력발휘가 뛰어나다.",
  ED: "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 자신이 속한 조직과 주변을 성장시키는 기질을 가지고 있어서 자신의 가치를 인정받는 것을 위해 정보와 지식을 나누고 그것으로 도움을 주는 것을 좋아하고 대리만족이 가능하다. 어려움이나 개척이 필요로 하는 조직에서 능력발휘가 뛰어나다.",
  IB: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 한쪽으로 치우치지 않는 객관적인 태도로 스스로 2인자로 돕는 역할을 한다. 자신의 말의 신뢰를 받고 싶어 하고 중간자로 서로를 연결하고 성장을 돕는 능력이 뛰어나다.",
  EB: "자신에게 주어진 상황이 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 관계를 중요하게 생각하고 함께 시너지를 내는 협업을 이끌어 낸다. 서로간의 다리 역할을 하면서 2인자로 자신이 드러나는 것을 좋아하지 않는다.",
  IH: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 결과중심적이고 명확하고 확실한 것을 선호한다. 현실적으로 눈으로 보이는 것을 중요하게 여기고 문제해결 능력이 뛰어나서 자신이 잘하는 것을 찾아 현실적으로 활용하는 능력이 뛰어나다.",
  EH: "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 답이 확실하고 현실적인 문제를 적극적으로 해결하고 답을 찾는 능력이 뛰어나서 해결사 역할을 한다. 현실에서 자신의 능력을 최대한 발휘할 수 있는 상황과 기회를 만들어 낸다.",
  IA: "자신의 마음이 우선이라 자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 자신의 준비가 우선이기 때문에 자기의 스타일과 속도대로 움직이기 때문에 이기적으로 나타나기도 한다. 자신이 아는 것을 창의적이고 감각적으로 활용하는 능력이 뛰어나서 대기만성형이다.",
  EA: "자신에게 주어진 상황이 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 자신의 준비가 되어야 밖으로 나타내지는데 자기의 스타일과 속도를 이해하고 움직여야 자신의 능력이 나온다. 천천히 드러내기 때문에 처음에는 수동적으로 보일 수 있지만 점차 자신이 원하는 것이 무엇인지 찾는 인생을 자신을 찾는 여정으로 여긴다.",
};
export const questionList = {
  [FORM_TYPE_ADULT1]: [
    [
      {
        question:
          "나는 새로운 일을 시작하려면 망설여지고 시작 자체에 엄두가 나지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 전문가나 선생님의 조언대로 실행하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 학습을 할 때 이해가 되어야 암기가 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 사람들에게 이야기 할 때 비유로 이야기하는 게 편하다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 전문가나 선생님의 조언을 참고 할 뿐 시키는 대로 하지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 사람들에게 이야기 할 때 세부적이고 단계적으로 묘사하듯이 이야기한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 학습을 할 때 이해가 안 되면 단순히 외워서 시험을 보는 게 가능하다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 새로운 일을 시작하거나 정보를 들을 때 전체를 알아야 이해가 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 새로운 일을 시작하거나 정보를 들을 때 단계별 순서대로 알려줄 때 행동이 가능하다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 시작을 결정할 때 즉흥적이라는 이야기를 주변사람에게 듣는 일이 종종 있다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 산을 볼 때 숲을 보는 형이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 물건을 살 때 비교를 많이 해보고 여기저기 발품을 팔아서 사는 형이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 산을 볼 때 나무를 보는 형이다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 여행지에서 모르는 길을 사람에게 물어보면서 찾는 형이다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 여행지에서 모르는 길은 지도나 지도앱을 보고 찾는 형이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 논리적이고 이치에 맞는 이야기를 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 경험하거나 직접 해 본 사람의 이야기를 좋아한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 무언가를 선택 할 때 느낌으로 선택하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 무언가를 선택 할 때 자신의 경험을 바탕으로 선택하는 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 스스로 시작하는 것보다는 타인이 시작하면 돕는 형이다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question: "나는 내가 가지고 싶은 걸 고르는 것이 어렵다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일하는 과정 속에서 일이 계획대로 안 되면 자신의 능력이나 판단에 대한 불신이 생긴다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일을 선택할 때 재미있을 것 같은 일이면 돈이 안 돼도 하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 한 가지를 깊게 파고드는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 전체를 이해하려고 노력하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 시스템을 만들고 양식을 만들어서 일하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 내가 마음에 들 때까지 하려고 한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 주변 사람으로부터 인정받고 칭찬받기 위해 일하는 편이다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 자신이 선택한 것에 대해 주변 사람들에게 잘했는지 아닌지를 확인받으려 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 시작하면 누군가의 조언이나 의견을 들으면 오히려 일의 능률이 떨어진다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 주변 사람들에게 꼼꼼하다는 이야기를 자주 듣는다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 손재주가 있다는 이야기를 자주 듣는다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 기술자들에게 창의적인 아이디어를 제공하는 형이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 외국어를 습득하는 데 능력이 있다는 이야기를 자주 듣는다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 자신의 감정을 숨기지 못하는 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 섭섭한 마음을 이야기하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 기술자들을 배려하며 일을 진행하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 자신의 스케줄과 스타일에 맞춰 일을 진행하는 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 언어(외국어 포함) 구사력과 습득력이 좋은 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 손으로 만드는 능력이 좋은 편이다.",
        value: "기술형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 '일단'이라는 말을 자주 사용한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 조직에서 선 실행, 후 보고가 가능한 환경을 선호 한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 나의 노후를 생각하며 일을 하는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 현재하는 일은 나중에 잘 살기위한 투자라고 생각한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 성과가 나지 않는 과정은 쓸데없다고 생각한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 회사에서 승진보다는 결과에 따른 보상으로 주식이나 현장의 실무자가 되는 것을 선호한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 공부를 할 때 답이 명확한 과목(수학, 화학, 물리 등)을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 계획을 짤 때 타임스케줄을 먼저 정하고 무엇을 할 건지 정하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 문제 해결을 위해 전체를 이해하고 문제를 해결하는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 일하는 스타일이 현장에서 직접 부딪치는 것을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 노후 준비를 위해 연금과 보험을 준비하는 형이다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 멀리보고 장기적으로 계획을 세워 일하는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 확실한 답을 듣고 움직이는 것을 선호한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 결과가 즉각적으로 나오는 것을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 답을 앉아서 기다리는 것보다 찾아가서 빠르게 듣는 것을 더 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 인내와 기다림이 더 큰 결과를 가져다준다고 생각한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 대학을 선택하거나 진로를 선택할 때 점수나 현실에 맞춰서 선택하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 돈은 명예나 일의 성과에 따라 온다고 생각한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일을 시작할 때 돈이나 현실적인 계산보다는 내가 목적하는 것을 더 중요하다고 생각한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 안정된 월급을 받는 일보다 앞으로 성장 가능성이 있는 일을 더 선호한다.",
        value: "전략형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나는 책을 선택할 때 소설책보다는 위인전이나 자서전을 더 선호하는 편이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 나의 전공에 맞는 일을 하려고 노력하는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 이직을 할 때 친한 선배나 신뢰하는 윗사람에게 영향을 받는 경우가 많다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 내가 하는 일이 자신과 안 맞을 때 이직을 진지하게 고민하게 된다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 조직생활에서 승진이나 권력에 대한 욕구가 없다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 어릴 때 나의 꿈이나 하고 싶은 일이 확실한 편이였다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 어릴 때 내가 좋아하는 선생님의 과목의 성적을 잘 받았다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 내가 맡은 일은 상황이 변해도 직접 마무리하고 싶어 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 부서이동을 실행하기 전에 선배나 믿을 만한 상사에게 조언을 구하는 편이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 조직에서 라인과 인맥을 중요하게 여긴다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 자신이 하는 일이 명확하지 않으면 스트레스를 많이 받는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 조직에서 윗사람의 역량에 따라 성과의 차이가 크다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 윗사람하고의 친밀한 관계형성에 어려움을 자주 느낀다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 나의 꿈이나 이상형이 없었다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 권력이나 인맥을 조직생활에서 1순위로 생각한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 무엇을 할지가 정해져야 다른 것들도 계획을 세울 수가 있다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 기획력이나 분석력이 좋다는 이야기를 자주 듣는다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 일을 열심히 하면 금전적 보상이 따라 온다고 생각한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 일을 할 때, 상사의 응원이나 칭찬이 반드시 필요하다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 일을 선택 할 때, 일의 가치가 중요하다.",
        value: "과제형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나는 어릴 때부터 대인관계에서 집착하거나 무관심한 상태로 중간관계가 잘 안 된다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 일을 준비할 때, 혼자 하는 것 보다 함께 하는 것이 좋다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 일을 준비할 때, 혼자 준비하는 것이 더 편하고 좋다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나는 주변사람들에게 언제 거절하고 언제 들어주는 것이 좋은지 구별하기가 어렵다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 일을 시작할 때, 주변에 먼저 알리고 준비한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 주변사람들에게 거절을 못해서 후회할 때가 종종 생긴다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 어릴 때 또래집단에서 겉도는 기분이 자주 들었고 친구의 필요성을 못 느꼈다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 나보다 나이가 많은 사람들과 일하는 게 어린 사람들과 일하는 것보다 편하다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 마음이 내키지 않거나 상황이 안 되면 동료가 부탁해도 거절하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 주변사람과 나 자신을 비교를 잘하지 않는다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 팀에서 팀원들과 성향이 맞지 않아도 신경 안 쓴다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구가 하자고 하면 일단 함께 하는 편이다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 공부를 하거나 프로젝트를 할 때 그룹으로 여러 사람이 함께하는 게 더 도움이 된다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 대인관계에서 사람들과 자연스럽게 거리유지를 잘하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람이 말하지 않아도 나의 마음을 잘 알아주길 바라는 마음이 크다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 친구가 돈을 빌려 달라고 할 때, 마음은 불편하더라도 거절한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 혼자서도 잘 노는 편이다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 빌려주는 것보다 차라리 주는 것이 마음이 더 편하다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 내 것을 주변에 잘 빌려준다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람과 어떻게 지내면 좋을지 상황 판단이 어려울 때가 종종 있다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
    ],
  ],
  [FORM_TYPE_CHILD1]: [
    [
      {
        question:
          "나의 자녀는 재미있는 것이 아니면 새로운 것에 관심을 갖지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 하지 말라는 행동은 대체로 안하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 논리적으로 이야기하면 설득이 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 사람에게 거부감이 없다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 직접 경험하게 해줘야 대화가 잘된다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 사람에게 낯가림이 있다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 하지 말라고 말해도 자기가 하고 싶으면 하는 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 보호자에게 허락을 받고 행동하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 음식도 잘 먹는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 음식은 잘 먹으려 하지 않는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 읽던 책을 반복해서 읽는다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 여행지에서 모르는 사람에게 먼저 다가가 말을 거는 것을 어려워하지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 어려워하는 문제를 옆에서 도와주는 사람을 좋아한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 자녀가 해 본 것 위주로 설명하면 말을 잘 듣는다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 어려워하는 문제를 직접 해결해 주는 사람을 믿고 따른다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 해야 할 일을 왜 해야 하는지 이유를 알려주고 납득이 되어야 할 일을 한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 책으로 학습하는 것 보다는 체험학습이 더 효과적이다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 안 먹는 음식도 건강에 얼마나 좋은 지를 설명해주면 먹는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼날 걸 알면서도 하지 말라는 행동을 종종 한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 새로운 경험을 시도할 때 보호자에게 의존을 많이 한다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question:
          "나의 자녀는 주변 사람들에게 칭찬을 받기 위한 행동을 자주 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 “너가 원하는 게 뭐야”라고 물으면 “모르겠어요”라고 대답하는 경우가 많다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구를 사귈 때 말과 행동을 이쁘게 하는 친구를 사귄다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 이야기를 듣는 중에 궁금한 게 생기면 바로 질문하고 답을 들으려 한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구나 주변 사람들의 이야기를 끝까지 듣고 자신이 궁금한 것을 묻는다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기의 기분이나 느낌을 말로 잘 표현한다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 관심 있고 잘하는 것에 대해 이야기를 시작하면 끝이 없다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 평소에 자신이 원하는 걸 결정을 하는데 시간이 오래 걸리거나 선택을 못한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 평소에 “나는 못 하잖아요”라는 말을 자주한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 거짓말을 하면 티가 많이 난다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 언어능력 보다는 손재주가 더 있다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어릴 때부터 자신이 하던 것을 끝까지 마무리하는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 창의적이고 자신의 아이디어를 그림으로 표현하는 능력이 뛰어나다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어릴 때부터 다재다능해서 뭐든지 곧잘 한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 블록 레고 놀이를 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구를 다양하게 사귄다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 하고 싶은 게 많다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 과제에 대한 집중력이 좋다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들하고 대화가 가능하다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구를 사귈 때 단짝을 좋아한다.",
        value: "기술형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나의 자녀는 '언제'라는 말을 자주 사용한다. 예시-언제 갈거에요?/언제 해줄거에요?",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 하고 싶은 행동은 하지 못하게 하면 몰래 라도 하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어른이 돼서 돈을 많이 벌고 싶다고 종종 이야기 한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 돈에 관심이 많아 돈 많이 버는 직업을 하겠다고 한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 결과가 나와야 인정하고 받아들인다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 내일 할 일이 있으면 오늘부터 걱정하거나 미리 준비한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 내일보다는 지금에 충실하다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 확실하고 답이 정해진 과목을 잘한다. 예시-수학,물리,화학",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 지금보다 나중의 보상이 크다면 끝까지 참는다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 결과를 기다리는 것을 싫어하고 힘들어한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 숙제나 자신의 할 일을 해놓고 논다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 현실 가능한 계획을 세워 실행하는 형이라 걱정이 안된다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어른이 되면 어떻게 살 건지에 대해 이야기를 자주 한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 여행할 때 '언제' 가느냐를 먼저 묻는다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 할지 말지, 갈지 말지 등 대답을 빨리 들으려고 재촉한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모의 재력에 관심을 가진다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 작은 목표부터 하나씩 나눠서 주면 어려운 문제도 잘 해낸다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 무언가 하고 싶을 때 부모의 경제력을 확인하거나 눈치를 본다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 주어진 일을 언제까지 끝낼 건지에 대해 물으면 대답을 회피한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 내일 체험을 하러 가려면 전날 준비해놓고 일찍 자는 형이다.",
        value: "전략형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 어른들의 말을 잘 듣는 편이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 규칙을 잘 지키는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 롤 모델을 정해놓고 행동을 따라 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 책임감이 강하다는 이야기를 주변사람들에서 자주 듣는다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 무엇을 해야 할지 미리 알지 못하면 불안해하거나 자꾸 확인한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 약속을 잘 지킨다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 선생님이 좋으면 그 과목의 성적도 높다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각이 정해지면 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 신학기가 되면 자신의 담임선생님이 누구인지부터 알고 싶어 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들에게 인정받으려고 노력한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 선생님이나 어른들에게 크게 영향을 받지 않고 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 리더가 되고 싶어 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 해야 하는 일이 무엇인지부터 알고 싶어 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 “니 꿈이 뭐야?”라고 물으면 “잘 모르겠어요”라고 대답한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 끌어주는 사람에 따라 생각이나 행동의 변화가 크다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 융통성이 부족한 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 정해진 규칙보다는 자신의 룰을 만들어 행동한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 맡은 일에 책임을 지려고 노력한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 위인전을 좋아하고 자주 읽는다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 공부(좋은 직업)를 잘하면 부자가 된다고 생각한다.",
        value: "과제형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나의 자녀는 또래 사이에서 관계를 어떻게 맺는지 모르는 것 같아 걱정이 된다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들에게 영향을 많이 받는다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 신학기 시작 전 사전준비가 필요하다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 또래집단에서 언제 거절해야 하는지 모르는 것 같다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 친구를 제일 좋아하는 것 같다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 주변사람에게 거절을 못하고 받아주는 편이다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 또래 사이에서 경쟁하는 법을 잘 모르는 것같이 보인다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구가 기분 나쁠까 봐, 하고 싶은 이야기를 못하는 경우가 자주 있다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 어리지만 또래사이에서 관계를 잘하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서 생각할 때가 종종 있다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구와 맞지 않아도 신경을 별로 안 쓰는 것처럼 보인다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들하고 있을 때 더 적극적이고 활기가 있다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구사이에 승부욕이 있는 편이다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 상황을 논리적으로 이해하고 결정하는 형이다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 억울해하거나 속상하면 잘 운다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구가 부탁을 해도 자기가 내키지 않으면 거절한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서도 잘 논다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구에게 먼저 다가가는 사교성이 좋은 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 또래사이에서 자신의 의견을 솔직하게 표현하지 못하는 편이다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들과 함께 협동할 때 소극적으로 행동한다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
    ],
  ],
  [FORM_TYPE_BABY1]: [
    [
      {
        question:
          "나의 자녀를 새로운 환경에 적응시킬 때 부모나 양육자가 항상 따라가야만 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀를 새로운 환경에 적응시킬 때 자신이 이해되도록 설명해주어야 한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모나 양육자의 기분이나 표정을 살핀다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 실내 활동이나 이야기하면서 노는 것을 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 놀이터나 놀이공간에서 놀 때 여러 가지 기구를 모두 타고 논다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀를 새로운 환경에 적응시키려면 부모나 보호자가 따라 가주면 그 이후에는 잘 적응한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 경험해 본 것 위주로 노는 것을 좋아한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모나 양육자의 지시나 설명을 잘 따른다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 직접 경험해야 적극적으로 놀이에 참여한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 도전적인 활동이 처음이라도 시도해본다.(예:높이 올라가기, 외다리건너기)",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 첫 도전이라도 겁내지 않고 쉽게 도전한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모나 양육자의 감정에 영향을 잘 받지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 놀 때 에너지가 넘친다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 첫 도전을 어려워하지만 나중에는 괜찮아진다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 사람에게 두려움이 없는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 이야기하는 것을 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 낯선 사람을 보면 처음에는 경계가 높지만 시간이 지나면 좋아진다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 선생님을 가리지 않는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신감이 생기면 그 이후에는 알아서 잘한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 낯선 사람에게 항상 경계가 심하고 잘 변화되지 않는다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question:
          "나의 자녀는 원하는대로 안되었을 때 짜증을 내거나 울면서 이야기 할 때가 자주있다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 원하는 것에 대해 요구하는 게 별로 없고 해주는 대로 잘 따른다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 원하는대로 안되었을 때 자신의 요구사항을 잘 표현한다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 원하는대로 안되었을 때 자신이 원하는대로 될 때까지 계속해서 요구한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 원하는 것이 여러 가지이고 작은 부분까지 해주기를 바란다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신의 컨디션이 나쁠 때도 감정 기복이 그렇게 심하지 않다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 원하는 것이 단순하고 명확하다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 마트에 가서 갖고 싶은 걸 고르라고 하면 망설이다가 결국 선택을 못한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 혼나는 상황에서 왜 그런 행동을 했는지 물으면 대답을 안 하고 침묵한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 한 친구와 꾸준히 노는 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 레고나 조립식 장난감을 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구들과 놀 때 자기와 성향이 비슷한 친구하고만 논다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들과 놀 때 잘 웃고 즐겁게 활동한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 미술수업 중에서 그리기를 좋아한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 원하는 것을 정확하게 요구한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구과 놀 때 친구들을 잘 설득한다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구들과 놀 때 다양한 친구들과 함께 노는 것을 좋아한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 감정을 참았다가 폭발하듯 표현한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 친구에게 쉽게 다가가서 먼저 말을 건다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 원하는 것만 제공하면 대체로 키우기가 수월하다.",
        value: "기술형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나의 자녀는 심부름을 시킬 때 돈을 주거나 자기가 원하는 것을 주어야 한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 활동체험을 할 때 스티커 북 채우기를 좋아한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 원하는 것을 얻기 위해 참고 기다릴 수 있다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 안 풀리는 문제도 풀릴 때까지 계속 반복해서 문제를 푼다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어떻게 하면 자신이 원하는 것을 빠르게 얻을 수 있을지 계산이 빠르다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 빨리 어른이 되고 싶다고 말한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 한글공부보다 숫자공부를 더 좋아하고 잘한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 지금 안 되는 일에 대해 빠르게 포기한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 선물을 고르라고 하면 자기가 원하는 것이 없을 때 나중에 사달라고 한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 지금 안 되는 일이 언제까지 기다리면 해 줄 수 있는지 묻고 기다린다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 소풍이나 여행을 가기 전에 미리 자신의 가방을 챙겨놓는다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 과제를 끝내고 보상받는 것을 좋아한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어느 곳에 가도 잘 살 것 같다는 생각이 들게 행동한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들 일에 관심이 많다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 보상이 즉시 주어졌을 때 주어진 과제를 끝까지 해낸다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 앞으로 엘리트교육(국제학교 등)이 필요할 것 같다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 선물을 고르라고 하면 당장 살 수 있는 것을 고른다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들 일에 관심을 갖지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 숫자개념에 관심이 없다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 많은 경제적 지원이 필요할 것 같다.",
        value: "전략형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 고집을 피울 때 말로 조율이 가능하다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어른들의 말보다는 자신의 주장대로 행동하는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 좋아하는 어른이 하지 말라고 하면 안한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기 고집대로 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각을 잘 이야기하지 못한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 하겠다고 한 약속은 반드시 지키려고 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 좋아하는 어른과 좋아하지 않는 어른에게 하는 행동이 많이 다르다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들이 자신에게 한 약속을 잊지 않고 기억한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 위인전 같은 인물이 나오는 동화책을 좋아한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 좋아하는 어른들의 행동을 따라한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 과학책이나 사람의 신체등 사실적인 지식 책을 좋아한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각과 다를 때 조율이 가능하다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각과 다를 때 조율이 잘 안 된다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 나이가 많은 어른들을 어려워하거나 불편해 하지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 하겠다고 했어도 자신이 믿고 좋아하는 어른의 말은 듣는다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 하겠다고 한 것에 대해서는 누구말도 잘 안 듣는다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 롤모델이 필요한 아이인 것 같다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 맞다고 주장하면 설득해도 소용이 없을 때가 많다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들에게 예의가 바르고 잘 따른다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들을 어려워한다.",
        value: "과제형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 모든 친구에게 다 똑같이 잘 한다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 외로움을 잘 느끼고 혼자 있는 것을 싫어한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서 깊게 생각한 후 이야기를 한다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구나 형제가 혼나는 것을 봐도 자신의 행동에 별다른 변화가 없다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들과 함께 있을 때 도전을 더 잘 한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구나 형제가 혼나는 것을 보면 자기가 먼저 운다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구가 자신 때문에 스트레스 받는 것을 이해하지 못한다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 불쌍한 동물을 보면 자신의 일을 미루고도 돌봐주거나 도움을 주는 행동을 한다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구나 형제가 혼나는 것을 보면 자기는 혼나지 않으려고 행동한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서도 재밌게 잘 논다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서 무엇을 하는지 무언가를 늘 한다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구를 너무 좋아한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들이 자신에게 한 이야기에 신경을 많이 쓴다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 게임이나 경쟁에서 지면 화를 심하게 낸다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 같이 놀자고 하는 친구를 끼워준다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어른들에게도 자신이 싫으면 싫다고 표현을 잘하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 잘 아는 게임은 친구들과 함께 한다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 하기 싫으면 친구의 부탁도 거절한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 친구는 친구나 형제가 부탁하면 거절을 못한다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구나 형제 때문에 스트레스를 많이 받는다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
    ],
  ],
  //여기부터 최신
  [FORM_TYPE_ADULT]: [
    [
      {
        question:
          "나는 새로운 일을 시작하려면 망설여지고 시작 자체에 엄두가 나지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 전문가나 선생님의 조언대로 실행하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 학습을 할 때 이해가 되어야 암기가 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 사람들에게 이야기할 때 비유로 이야기하는 게 편하다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 전문가나 선생님의 조언을 참고할 뿐 시키는 대로 하지는 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 사람들에게 이야기할 때 세부적이고 단계적으로 묘사하듯이 이야기한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 학습을 할 때 이해가 안 돼도 단순히 외워서라도 시험을 보는 게 가능하다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 새로운 정보를 활용하려면 논리적이고 원론적으로 공식을 알려주는 것을 선호한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 새로운 일을 해야 할 때 순차적으로 하나씩 알려주는 것을 선호한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 무엇을 결정할 때 빠르게 하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 일을 시작하기 전에 일의 프로세스를 알고 행동할 때와 아닐 때의 성과 차이가 크다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 물건을 살 때 비교를 많이 해보고 여기저기 발품을 팔아서 선택하는 형이다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 결정을 할 때 직접 보고 경험한 후에 확신이 생기면 그때 결정하는 형이다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 여행지에서 모르는 길을 사람에게 물어보면서 찾는 형이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 여행지에서 모르는 길을 자신의 직감대로 찾는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 논리적이고 이치에 맞는 이야기는 자신의 경험과 달라도 수용하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 논리적이고 이치에 맞아도 자신의 경험과 다르면 받아들이기에 어려움을 느낀다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 무언가를 선택할 때 느낌이나 감으로 선택하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 무언가를 선택할 때 자신의 경험을 바탕으로 선택하는 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 스스로 시작하는 것보다는 타인이 시작하면 돕는 형이다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question:
          "나는 내가 원하는 걸 선택하는 것에 별 관심이 없고 뭘 선택하든 별로 중요하지 않다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일하는 과정 속에서 일이 잘 안 되면 자신의 능력이나 판단에 대한 불신이 생긴다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 일을 선택할 때 재미있는 일은 돈이 안 돼도 하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 꽂히면 깊게 파고드는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 전체를 이해하려고 노력하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 시스템을 만들고 양식을 만들어서 일하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 주변에서 됐다고 해도 내 마음에 들 때까지 일하는 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 주변 사람으로부터 인정과 칭찬을 받아야 안심이 되고 스스로도 잘했다고 느낀다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 자신의 능력과 아이디어를 다른 사람에게 조리 있게 이야기하는 것이 어렵다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일하는 중에 누군가의 조언이나 의견을 들으면 오히려 일의 능률이 떨어진다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 주변 사람들에게 꼼꼼하다는 이야기를 자주 듣는다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 손재주가 있다는 이야기를 자주 듣는다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 기술자들에게 창의적인 아이디어를 제공하는 형이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 외국어를 습득하는데 능력이 있다는 이야기를 자주 듣는다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 자신의 감정을 숨기질 못하는 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 섭섭한 마음을 이야기하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 일을 할 때 기술자들을 배려하며 일을 진행하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 자신의 스케줄과 스타일에 맞춰 일을 진행하는 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나는 팀원들과 역할을 나누고 노는 사람 없이 함께 일하는 환경을 만들어 가는 형이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람들이 안 따라주면 그냥 혼자서 일을 마무리하고 책임지는 형이다.",
        value: "기술형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 '일단'이라는 말을 자주 사용한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 조직에서 선 실행, 후 보고가 가능한 환경을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 나의 노후를 생각하며 일을 하는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 현재 하는 일은 나중에 잘 살기 위한 투자라고 생각한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 성과가 보장된 일을 하는 것을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 회사에서 승진보다는 연금이나 퇴직금 같은 안정된 복지를 선호한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 공부를 할 때 답이 명확한 과목(수학, 화학, 물리 등)을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 계획을 정할 때 스케줄을 먼저 정하고 무엇을 할 건지 정하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 문제 해결을 위해 전체를 이해하고 다음에 같은 문제가 생기지 않도록 해결하는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 일하는 스타일이 현장에서 직접 부딪치는 것을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 노후 준비를 위해 연금과 보험을 준비하는 형이다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 일을 할 때 멀리 보고 장기적으로 계획을 세워 일하는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 중요한 일을 앞두고 있으면 그 일이 끝날 때까지 다른 일들을 미루는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 결과가 즉각적으로 나오는 것을 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 답을 앉아서 기다리는 것보다 찾아가서 빠르게 듣는 것을 더 선호한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 인내와 기다림이 더 큰 결과를 가져다준다고 생각한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 대학을 선택하거나 진로를 선택할 때 점수나 현실에 맞춰서 선택하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 돈은 명예나 일에 따라서 온다고 생각한다.",
        value: "목표실행방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 일을 시작할 때 돈이나 현실적인 계산보다는 일의 가치나 방향성이 더 중요하다고 생각한다.",
        value: "목표실행방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 안정된 월급을 받는 일보다 앞으로 성장 가능성이 있는 일을 더 선호한다.",
        value: "전략형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나는 책을 선택할 때 소설책보다는 위인전이나 자서전을 더 선호하는 편이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 나의 전공에 맞는 일을 하려고 노력하는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 이직을 할 때 친한 선배나 신뢰하는 윗사람에게 영향을 받는 경우가 많다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 내가 하는 일이 자신과 안 맞을 때 이직을 진지하게 고민하게 된다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 조직 생활에서 승진이나 권력에 대한 관심보다는 주어진 일에 충실하게 하는 형이다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 어릴 때 나의 꿈이나 하고 싶은 일이 확실한 편이었다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 어릴 때 내가 좋아하는 선생님이 가르치는 과목의 성적을 잘 받았다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 내가 맡은 일은 상황이 변해도 잘 마무리하고 싶어 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 부서(전공)이동을 할 때 부서(전공)의 리더(교수님)의 성향을 중요하게 생각하여 선택한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 나와 윗사람과의 신뢰를 바탕으로 충성있는 행동을 선호한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 자신이 하는 일이 명확하지 않으면 스트레스를 많이 받는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 조직에서 윗사람의 역량에 따라 성과의 차이가 크다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 윗사람하고의 친밀한 관계 형성에 어려움을 자주 느낀다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 나의 꿈이나 이상형이 없었다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 윗사람과의 관계나 인맥을 조직 생활에서 1순위로 생각한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 무엇을 할지가 정해져야 다른 것들도 계획을 세울 수가 있다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 조직에서 윗사람에게 믿고 일을 맡길 수 있다는 이야기를 자주 듣는다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 일을 잘하고 실력이 있으면 금전적 보상이 따라온다고 생각한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 일을 할 때, 상사의 응원이나 칭찬이 반드시 필요하다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 일을 선택할 때, 일의 가치나 '내가 잘할 수 있는 일인가'가 중요하다.",
        value: "과제형",
        answer: "",
      },
    ],
    [
      {
        question:
          "나는 어릴 때부터 또래 관계에서 경쟁하거나 비교하는 행동을 하지 않았다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 일을 준비할 때, 혼자 하는 것보다 함께 할 때 더 잘한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 일을 준비할 때, 혼자 준비하는 것이 더 편하고 좋다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람들에게 언제 거절하고 언제 들어주는 것이 좋은지 구별하기가 어렵다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 결정할 일이 있을 때, 주변 사람과 의논하는 편이다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람에게 거절을 못해서 손해를 보고 후회하는 경우가 자주 있다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람과의 관계를 사회생활을 통해 관계를 어떻게 해야 하는지 배우면서 행동한다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 나보다 나이가 많은 사람들과 일하는 게 어린 사람들과 일하는 것보다 편하다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 마음이 내키지 않거나 상황이 안 되면 동료가 부탁해도 거절하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 자신의 문제를 가까운 사람이 아는 것이 불편하다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나는 주변의 친구나 가족들로부터 “다 정해놓고 왜 물어보냐”는 말을 자주 듣는다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구가 하자고 하면 일단 하는 편이다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 의사결정을 할 때 여러 사람이 함께 집단지성을 활용하는 게 더 도움이 된다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 대인관계에서 사람들과 자연스럽게 거리 유지를 하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람에게 표현하지 않아도 나를 알아주길 바라는 마음이 크다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 친구가 돈을 빌려 달라고 할 때, 마음은 불편하더라도 거절하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나는 결정을 해야 할 때 가까운 사람보다는 나를 모르는 사람이나 전문가의 의견을 묻는 편이다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나는 나와 수준이 비슷한 경쟁자(친구,동료)와 함께 일하는 것이 편하다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나는 나보다 수준이 높은 사람들과 함께 일하면서 보고 배우는 것을 좋아한다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 어릴 때 또래 집단에서 혼자 놀거나 친구에게 관심이 없었다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
    ],
  ],
  [FORM_TYPE_STUDENT]: [
    [
      {
        question: "나는 무엇을 선택할 때 빠르게 선택한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 다니던 학원을 바꾸거나 새로운 도전을 할 때마다 선택하기 힘들고 내키지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 선생님이나 어머니가 하지 말라는 행동은 대체로 안하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 나의 생각과 달라도 논리에 맞으면 인정하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 학습을 할 때 이해가 되어야 암기가 되는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 선택을 할 때 내가 경험해보고 확신이 생긴 후 결정하는 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나는 주변의 조언도 자신이 경험하고 스스로 판단한 후에 받아들이는 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 부모님의 허락을 받고 행동하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 꽂히지 않으면 시작을 하지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 새로운 음식도 잘 먹는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 새로운 음식은 잘 먹으려 하지 않는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 책을 천천히 자세히 읽는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 여행지에서 누군가에게 물어보는 것을 어려워하지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 비유나 느낌으로 상황이나 사람을 표현한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 이해가 되지 않는 부분은 암기라도 해서 시험을 보는게 가능하다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 논리적으로 이해가 되어야 암기가 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 자세하고 세세하게 묘사하듯이 이야기를 한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 주변에서 고집이 세다는 이야기를 자주 듣는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 직접 확인하고 몸으로 겪어야 습득이 된다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 혼날 걸 알면서도 행동을 할 때가 종종 있다.",
        value: "경험형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 나의 느낌이나 감정을 표현하는 게 잘 안된다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 내가 잘하는 것이 무엇인지 항상 찾는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 하나에 꽂히면 그것만 파고드는 행동을 하는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 음식도 좋아하는 종류만 먹는 스타일이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 창의성을 가진 아이디어를 많이 가지고 있는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 언어습득 능력이 좋은 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 한 가지에 빠지면 싫증 날 때까지 해야 직성이 풀린다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 친구들의 고민 상담을 잘 해준다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 창의적이라는 이야기를 자주 듣는다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 공부를 할 때 전체적으로 훑어보고 다시 패턴으로 묶어서 공부하는 형이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 손재주가 좋은 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 여러 분야에 관심과 재주를 가지고 있다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 한 가지에 집중하는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 시키는 것마다 잘하는 형이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 내가 원하는 것이 무엇인지 확실히 알고 있다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 친구를 다양하게 사귄다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 친구를 사귈 때 단짝을 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 기술자나 엔지니어를 진로로 생각해 본 적이 있다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 화가 나면 말을 하지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 시작은 잘하는데 중간에 싫증이 빠르고 포기가 빠른 편이다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question: "나는 '일단'라는 말을 자주 사용한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 나중에 나이 먹어서 잘살고 싶어 공부를 열심히 한다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 일단 해보고 결과가 나오면 그 때 무엇을 할지 결정하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 결과가 나와야 인정하고 받아들인다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 내일 할 일이 있으면 오늘 편하게 놀지 못한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 내일 할 일이 있으면 미리 준비한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 내일보다는 지금에 충실하다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 확실하고 답이 정해진 과목을 잘한다. 예시-수학,물리,화학",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나는 미래의 보상을 위해 자신의 실력을 갈고 닦고 준비해야 편해진다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 숙제는 해놓고 놀아야 마음이 편한 형이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 무엇을 해야 할지 정해져야 행동을 할 수 있다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 기다리는 것을 싫어하고 어려워한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 현실 가능한 계획을 세워 착실히 준비하는 안전형이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 일을 시작할 때 스케줄부터 정하고 시작하는 형이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 결과가 확실하게 계산이 되어져야 행동을 할 수 있다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 점수를 받고 나서 그 다음에 진로를 정하는 형이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 돈에 관심이 없고 개념이 부족하다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 부모님의 경제력에 관심을 가진다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 나중보다는 지금이 중요하다고 생각한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 돈이 안되는 직업에는 관심이 가지 않는다.",
        value: "전술형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 전문직을 계획하고 있다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 생각이 정리되어야 행동의 시작을 할 수가 있다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 시간약속을 잘 지킨다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 책임감이 강하다는 이야기를 자주 듣는다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 맡은 일의 기한을 잘 지킨다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 나의 미래를 계획하고 생각이 정리된 후 실행한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 롤모델이 생기면 열심을 다해 보고 배우는 형이다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 가르치는 선생님에게 크게 영향을 받지 않고 자기 주도적으로 학습한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 좋아하는 선생님의 과목을 훨씬 잘한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 내가 선택한 것에 후회를 안 하는 편이다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 내가 맡은 일은 끝까지 책임지려고 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 나를 믿어주는 사람에게 신의를 지키기 위해 더욱 노력하는 형이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 사회생활에서 정해진 규칙을 잘 지킨다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 정해진 규칙보다는 자신의 룰을 만들어 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 대표나 리더의 역할을 할 때 어울리고 즐긴다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 반장이나 학생회장을 할 때 더 자신감과 성취감을 가지게 된다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 주어진 일은 잘 해낸다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 어릴 때부터 주변 사람들에게 융통성이 부족하다는 이야기를 종종 들었다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 어릴 적부터 꿈이나 전공을 정하는 데 어려움을 느낀다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 좋은 친구와 선배를 따르는 형이다.",
        value: "관계형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 신학기 시작 전 사전 준비가 필요하다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 상황을 논리적으로 이해하고 해결하는 형이다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나는 친구들 사이에서 자신의 의사를 어떻게 전달해야 하는지 어렵게 느껴진다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 친구들과 거리를 유지하며 크게 의지하지 않는다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 학교생활에서 교우관계를 제일 우선으로 생각한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 거절을 못해서 후회할 때가 종종 생긴다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나는 자신의 일이 있어도 친구의 부탁을 거절하지 못 할 때가 자주 있다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나는 친구들에게 자신의 의견을 표현할 때 눈치가 보인다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 친구가 학원에 가지 말자고 해도 자신의 상황을 설명하고 자신의 책임을 다한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 주변과 비교하지 않는다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나는 조에서 조원들과 성향이 맞지 않아도 그다지 신경 안 쓴다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구들 사이에서 공감을 언제 해야 할 지를 잘 모르겠다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 친구들의 부탁을 어디까지 들어줘야 하는지 헷갈릴 때가 자주 생긴다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 또래관계에서 친구가 다가올 때까지 기다리는 형이다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 주변 사람이 나의 상황을 알아주지 않으면 섭섭하다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 친구가 돈을 빌려 달라고 할 때, 마음은 불편하더라도 거절한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 혼자서도 잘 논다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구들과 경쟁할 때가 더 좋은 결과가 나온다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 주변 사람에게 자신의 어려운 상황을 솔직하게 표현하지 못하는 편이다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나는 거절을 당해도 개의치 않는다.",
        value: "논리형",
        answer: "",
      },
    ],
  ],
  [FORM_TYPE_YOUTH]: [
    [
      {
        question: "나는 무엇을 선택할 때 빠르게 선택한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 다니던 학원을 바꾸는 것이 마음이 불편하고 힘들다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 선생님이 하지 말라는 행동은 안 하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 소설책보다 과학책을 더 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 공부를 할 때 이해가 되어야 암기가 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 결정을 할 때 직접 경험해보고 확신이 서면 한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 어릴 적부터 고집이 세다는 이야기를 자주 들었다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 부모님의 허락을 받고 행동하는 것이 편하다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 꽂히지 않으면 시작을 하지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 새로운 음식도 잘 먹는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 새로운 음식은 잘 먹지 않는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 책을 천천히 자세히 읽는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 모르는 길을 낯선 사람에게 물어보는 것을 어려워하지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 모르는 길을 자기 느낌으로 잘 찾는다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나는 이해가 되지 않는 부분은 암기라도 해서 시험을 보는 게 가능하다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 시 쓰기를 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나는 호기심이 생기면 꼭 확인해 본다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 주변에서 고집이 세다는 이야기를 자주 듣는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 결정하는데 시간이 오래 걸린다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나는 혼날 걸 알면서도 행동을 할 때가 종종 있다.",
        value: "경험형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 나의 느낌이나 감정을 표현하는 게 잘 안된다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 내가 잘하는 것이 무엇인지 항상 궁금해하고 찾는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 하나에 꽂히면 파고드는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 좋아하는 음식만 먹는 스타일이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 창의적인 생각을 많이 한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 외국어를 잘하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 한 가지에 빠지면 싫증 날 때까지 해야 직성이 풀린다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 친구들의 고민 상담을 잘 해준다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 창의적이라는 이야기를 자주 듣는다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나는 공부를 할 때 전체적으로 훑어보고 다시 패턴으로 묶어서 공부하는 형이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 만들기를 좋아하고 잘한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 여러 분야에 관심과 재주가 있다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 한 가지에 집중하는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 음악이나 미술을 잘한다는 말을 종종 들었다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 내가 원하는 것이 무엇인지 확실히 알고 있다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 친구를 다양하게 사귄다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나는 친구를 사귈 때 단짝을 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 기술자나 엔지니어를 진로로 생각해 본 적이 있다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나는 화가 나면 말을 하지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나는 시작은 잘하는데 중간에 싫증이 빠르고 포기가 빠른 편이다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question: "나는 돈에 관심이 많다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 어른이 되어 잘살고 싶어 공부를 열심히 한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 일단 해보고 결과가 나오면 무엇을 할지 정한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 결과가 나와야 인정하고 받아들인다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 내일 숙제나 과제가 있으면 오늘 편하게 놀지 못한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 미리 준비하지 않으면 불안하다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 오늘 할 일을 끝내야 마음이 편하다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 확실하고 답이 정해진 과목을 잘한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 어릴 때부터 어른스럽다는 이야기를 자주 들었다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 숙제는 해놓고 놀아야 마음이 편하다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 무엇을 해야 할지 정해져야 행동을 할 수 있다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 기다리는 것을 싫어하고 어려워한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 문제가 있으면 바로 해결해야 마음이 편하다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 공부를 할 때 스케줄을 짜고 시작하는 게 편하다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나는 시험공부를 할 때 벼락치기를 하는 게 성적이 더 잘 나온다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 시험을 보기 위해 미리 준비한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 돈에 관심이 없다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 용돈은 부모님이 주시는 대로 쓴다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나는 공부를 하면 바로 성적이 오르는 공부방식을 좋아한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나는 돈이 안되는 직업에는 관심이 가지 않는다.",
        value: "전술형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 나의 꿈을 정하기 위해 진로에 관한 책을 읽는다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 무엇을 해야 하는지 정확히 알려주면 책임을 다한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 시간약속을 잘 지킨다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 책임감이 강하다는 이야기를 자주 듣는다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 어른과 약속한 일은 지키려고 노력한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 내가 커서 무엇을 해야 하는지 고민을 자주 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 내가 존경할 수 있는 사람을 보고 배우는 것을 좋아한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 선생님에게 크게 영향을 받지 않고 자기 주도적으로 학습한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 좋아하는 선생님의 과목을 훨씬 잘한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 내가 선택한 것에 후회를 안 하는 편이다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 내가 맡은 일은 끝까지 책임지려고 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 나를 믿어주는 사람이 있으면 못하는 것도 해보려고 노력한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 선생님과 정한 규칙을 잘 지킨다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 자기와의 약속을 잘 지킨다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 형(오빠)이나 누나(언니)하고 노는 것이 더 즐겁다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나는 반장이나 학생회장을 할 때 더 자신감과 성취감을 가지게 된다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나는 주어진 일은 잘 해낸다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나는 어릴 때부터 주변 사람들에게 융통성이 부족하다는 이야기를 종종 들었다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나는 나의 꿈이 뭔지 정하는 게 어렵다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나는 좋은 친구와 선배를 따르는 형이다.",
        value: "관계형",
        answer: "",
      },
    ],
    [
      {
        question: "나는 신학기 시작 전 사전 준비가 필요하다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구들과 함께 축구 차기를 좋아한다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나는 친구들 사이에서 자신의 의사를 어떻게 전달해야 하는지 어렵게 느껴진다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 친구에게 크게 의지하지 않는다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 학교생활에서 교우관계를 제일 우선으로 생각한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 친구의 부탁을 거절하는 게 어렵다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나는 친구와 다투는 것이 싫어서 그냥 참는다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나는 친구에게 나의 생각을 이야기하는 걸 좋아하지 않는다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 친구가 학원을 빠지자고 해도 나는 학원을 간다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 고민이 있을 때 혼자서 고민한다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구와 성향이 맞지 않아도 별로 신경 안 쓴다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구에서 공감을 언제 해야 할 지를 잘 모르겠다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나는 친구들의 부탁을 어디까지 들어줘야 하는지 헷갈릴 때가 자주 생긴다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나는 또래 관계에서 친구가 다가올 때까지 기다리는 형이다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 고민이 있을 때 친구와 의논하고 결정한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나는 친구가 돈을 빌려 달라고 할 때, 마음은 불편하더라도 거절한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나는 혼자서도 잘 논다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나는 친구들과 경쟁할 때가 더 좋은 결과가 나온다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나는 친구에게 나의 어려운 상황을 솔직하게 표현하지 못한다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나는 다양한 친구를 사귄다.",
        value: "논리형",
        answer: "",
      },
    ],
  ],
  [FORM_TYPE_CHILD]: [
    [
      {
        question: "나의 자녀는 직접 경험하게 해줘야 말을 알아 듣는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 재미있는 게 생겨야 공부를 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 하지 말라고 하면 하던 행동도 멈춘다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 논리적으로 이야기하면 설득이 되어 행동이 변한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 사람에 대한 거부감이 없는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 사람에게 낯가람이 심한 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 하지 말라고 해도 행동을 잘 멈추지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 나에게 허락을 받고 행동하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 새로운 것을 해야 할 때는 보호자에게 의존하려고 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 음식도 잘 먹는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 음식은 잘 먹으려 하지 않는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 읽던 책을 반복해서 읽는 것을 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 여행지에서 낯선 사람에게 다가가는 것을 어려워하지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 궁금한 건 이해가 될 때까지 물어본다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 궁금하면 직접 만져보고 경험해 보려 한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 이유를 설명해 주면 고집을 부리지 않는다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 결정할 때까지 기다려줘야 한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모와 이야기가 된다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 책으로 학습하는 것 보다는 체험학습이 더 효과적인 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼날 걸 알면서도 자기가 하고 싶은 행동은 한다.",
        value: "경험형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 관심이 가는 것을 실제로 만져보는 것을 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 “무엇을 좋아하는지”에 대한 질문에 “모르겠어요”라고 대답하는 경우가 많다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 블록 게임인 레고 놀이를 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구를 사귈 때도 외모나 바른 태도를 중요하게 생각하고 사귄다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 과제에 대한 집중력이 좋다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기의 감정을 말로 잘 표현한다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 잘 아는 것에 대해 이야기 하기 시작하면 끝이 없다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구나 주변 사람들의 말을 잘 들어 준다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 창의적으로 자기의 생각을 그림으로 표현하는 능력이 뛰어나다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 거짓말을 하면 티가 많이 난다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 말 재주 보다는 손재주가 더 있다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 하고 싶은 게 많다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어릴 때부터 한 가지에 집중하는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 어릴 때부터 다재다능해서 뭐든지 곧잘 한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 전체를 다 듣고 답을 한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구를 다양하게 사귄다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구를 사귈 때 단짝을 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 인정받거나 칭찬 받기 위해 행동하는 일들이 종종 있다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 평소에 자신이 원하는 것을 결정하는 걸 어려워한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 평소에 “나는 못한다”라는 말을 자주 한다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 융통성이 부족하고 정해진대로 행동하는 형이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 늘 무엇을 할지 미리 정해야 안심이 되는 형이다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 어른하고의 시간약속을 잘 지킨다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 책임감이 강하다는 이야기를 자주 듣는다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 약속한 일은 잘 지킨다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 해야 할 일이 정해주면 잘 해낸다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 위인전 읽기를 좋아한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 스스로 알아서 학습한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 좋아하는 선생님의 과목을 훨씬 잘한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신의 꿈이나 진로에 대해 물으면 “모르겠다”라고 대답한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 무엇을 해야 하는지부터 알고 싶어 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 신학기에 자신의 담임선생님이 누구인지부터 알고 싶어한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 사회생활에서 정해진 규칙을 잘 지킨다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 좋아하는 어른과의 약속은 꼭 지키려고 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들을 도우려고 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 반장을 하고 싶어 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 주어진 일은 잘 해낸다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 공부(명예)를 잘해서 부자가 되겠다고 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 이끌어주는대로 잘 따라온다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 생각이 어른스러울 때가 많다.",
        value: "관계형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 어른들의 말을 잘 듣는 편이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 규칙을 잘 지키는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 롤 모델을 정해놓고 행동을 따라 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 책임감이 강하다는 이야기를 주변사람들에서 자주 듣는다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 무엇을 해야 할지 미리 알지 못하면 불안해하거나 자꾸 확인한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 약속을 잘 지킨다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 선생님이 좋으면 그 과목의 성적도 높다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각이 정해지면 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 신학기가 되면 자신의 담임선생님이 누구인지부터 알고 싶어 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들에게 인정받으려고 노력한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 선생님이나 어른들에게 크게 영향을 받지 않고 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 리더가 되고 싶어 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 해야 하는 일이 무엇인지부터 알고 싶어 한다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 “니 꿈이 뭐야?”라고 물으면 “잘 모르겠어요”라고 대답한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 끌어주는 사람에 따라 생각이나 행동의 변화가 크다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 융통성이 부족한 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 정해진 규칙보다는 자신의 룰을 만들어 행동한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 맡은 일에 책임을 지려고 노력한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 위인전을 좋아하고 자주 읽는다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 공부(좋은 직업)를 잘하면 부자가 된다고 생각한다.",
        value: "과제형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 친구에게 관심을 보이지 않는다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 친구에게 자신의 의견 전달을 못한다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 관심 있는 친구에게 먼저 다가간다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들에게 크게 영향을 받지 않는다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 학교생활에서 친구를 가장 중요하게 생각한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구의 부탁을 거절 못한다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 불쌍한 동물을 보면 지나치질 못한다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구의 기분을 잘 모른다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 친구의 부탁에 거절을 잘 하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 자주 억울해하거나 속상하면 잘 운다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구와 성향이 맞지 않아도 신경을 별로 안 쓴다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들과 함께 결정하는 걸 좋아한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서 고민할 때가 종종 보인다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신의 의견을 친구에게 설득하는 것을 하려 하지 않는다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 주변 사람이 자신의 마음을 알아주지 않으면 많이 속상해 한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구가 부탁을 해도 자기가 싫으면 거절한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서도 잘 논다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 또래들과 경쟁할 때 결과가 더 잘 나온다.",
        value: "그룹형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 또래 사이에서 자신의 의견을 솔직하게 이야기를 잘 못한다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 어려도 친구 관계를 잘한다.",
        value: "논리형",
        answer: "",
      },
    ],
  ],
  [FORM_TYPE_BABY]: [
    [
      {
        question:
          "나의 자녀를 새로운 환경에 적응시킬 때 부모나 양육자가 항상 따라가야 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀를 새로운 환경에 적응시킬 때 자신이 이해되도록 설명해주어야 한다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모나 양육자의 기분이나 표정을 살핀다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 실내 활동이나 이야기하면서 노는 것을 좋아한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 놀이터나 놀이공간에서 놀 때 여러 가지 기구를 모두 타고 논다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀를 새로운 환경에 적응시키려면 직접 경험하도록 옆에서 지켜봐주어야 한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 바깥 활동을 좋아한다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모나 양육자의 지시나 설명을 잘 따른다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 활동적인 놀이에 적극적으로 참여한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 도전적인 활동이라도 선생님이 먼저 보여주면 따라한다.",
        value: "직관형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 논리적으로 이야기하면 어른들의 상황도 잘 이해하는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모나 양육자의 감정에 영향을 잘 받지 않는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 놀 때 에너지가 넘친다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 신체놀이를 잘하고 운동신경이 좋은 편이다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 사람에게 두려움이 없는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 선생님을 잘 따른다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 궁금하면 못 참는다.",
        value: "경험형",
        answer: "",
      },
      {
        question: "나의 자녀는 얌전하게 노는 편이다.",
        value: "직관형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신감이 생기면 그 이후에는 알아서 잘한다.",
        value: "경험형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 낯선 사람에게 항상 경계가 심하고 잘 변화되지 않는다.",
        value: "잠재역량",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 자신의 감정을 폭발하듯 표현한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 원하는 대로 안되었을 때 짜증을 심하게 내거나 울면서 이야기할 때가 자주 있다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 원하는 것을 조리 있게 이야기한다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 원하는 것을 줄 때까지 계속해서 요구한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 언어습득력이 좋은 거 같다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 원하는 것이 있을 때 필요한 이유를 설명할 줄 안다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 원하는 것이 단순하고 명확한 편이다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 원하는 것을 선택하는데 망설이고 어려워하는 편이다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 감정 표현을 잘 못하는 것 같아 보인다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 조용히 자기가 하고 싶은 것에 잘 빠지는 형이다.",
        value: "기술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 갖고 싶은 걸 고르라면 항상 같은 종류만 선택한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 레고나 조립식 장난감을 좋아한다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 다양한 친구들과 노는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구들을 대신해 줄 때가 많다.",
        value: "설계형",
        answer: "",
      },
      {
        question: "나의 자녀는 한 가지에 빠지면 집중력이 뛰어나다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 새로운 친구에게 먼저 다가가서 말을 건다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구들과 놀 때 다양한 친구들과 함께 노는 것을 좋아한다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구들과 놀 때 자기와 성향이 비슷한 친구하고만 논다.",
        value: "기술형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 원하는 것을 잘 알고 요구하는 편이다.",
        value: "설계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 원하는 것만 제공하면 대체로 키우기가 수월하다.",
        value: "기술형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 돈에 대한 개념이 있다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 스티커 북 채우기를 좋아한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 원하는 것을 얻기 위해 참고 기다릴 줄 안다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 나중에 해 준다고 하면 기다리는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 빨리 어른이 되고 싶다고 말한다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어떻게 하면 자신이 원하는 것을 얻을 수 있을지 계산이 빠르다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 한글 공부보다 숫자 공부를 더 좋아하고 잘한다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 안 되는 일은 빠르게 포기하는 편이다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 할 일을 하고 노는 편이다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 여행을 갈 때 언제 갈 건지 확인하는 형이다.",
        value: "전술형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 소풍이나 여행을 가기 전에 미리 자신의 가방을 챙겨놓는다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 모범생 같은 아이다.",
        value: "전략형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어느 곳에 가도 잘 살 것 같다는 생각이 들게 행동한다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들 일에 관심이 많다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 보상을 주었을 때 주어진 과제를 잘 해낸다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 앞으로 엘리트 교육(사립초)이 필요할 거 같다.",
        value: "전략형",
        answer: "",
      },
      {
        question: "나의 자녀는 선물을 고르라고 하면 기회를 놓치지 않고 고른다.",
        value: "전술형",
        answer: "",
      },
      {
        question: "나의 자녀는 돈에 대해 관심이 없다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 약속을 잘 안 하려고 한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 행동이 어른스럽다.",
        value: "전략형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 고집을 피울 때 말로 조율이 가능하다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 어른들의 말보다는 자신의 주장대로 행동하는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 좋아하는 어른이 하지 말라는 말을 잘 듣는 편이다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자기 고집대로 행동한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각을 잘 이야기하지 못한다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 자기가 하겠다고 한 약속은 잘 지키는 편이다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기가 좋아하는 어른과 좋아하지 않는 어른에게 하는 행동이 많이 다르다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들이 자신에게 한 약속을 잊지 않고 기억한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 위인전 같은 인물이 나오는 동화책을 좋아한다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 좋아하는 어른의 행동을 따라 한다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 과학책이나 사람의 신체등 사실적인 지식 책을 좋아한다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각과 다를 때 조율이 가능하다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신의 생각과 다를 때 조율이 잘 안 된다.",
        value: "과제형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 나이가 많은 어른들을 어려워하거나 불편해하지 않는다.",
        value: "잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 어른이 주는 것을 잘 받는다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 자존심이 강한 아이다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 롤모델이 필요한 아이인 것 같다.",
        value: "관계형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자신이 맞다고 주장하면 설득해도 소용이 없을 때가 많다.",
        value: "과제형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들에게 예의가 바르고 잘 따른다.",
        value: "관계형",
        answer: "",
      },
      {
        question: "나의 자녀는 어른들을 어려워한다.",
        value: "과제형",
        answer: "",
      },
    ],
    [
      {
        question: "나의 자녀는 친구들에게 별 관심이 없다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 외로움을 잘 느끼고 혼자 있는 것을 싫어한다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서 고민하는 형이다.",
        value: "혼자형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구나 형제가 혼나는 것을 봐도 자신의 행동에 별다른 변화가 없다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question: "나의 자녀는 친구와 함께 있을 때 에너지가 더 좋다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 형제가 혼나는 것을 보면 자기도 함께 운다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구가 자기 때문에 스트레스 받는 것을 잘 이해하지 못한다.",
        value: "자기방어방식-잠재역량",
        answer: "",
      },
      {
        question:
          "나의 자녀는 불쌍한 동물을 보면 돌봐주거나 도움을 주는 행동을 한다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 친구나 형제가 혼나는 것을 보면 자기는 혼나지 않으려고 행동한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서도 재밌게 잘 논다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 혼자서 무언가를 늘 한다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 부모보다 친구를 더 좋아하고 영향을 받는다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 친구가 하는 말을 잘 듣는다.",
        value: "그룹형",
        answer: "",
      },
      {
        question: "나의 자녀는 게임이나 운동경기에 경쟁심이 강하다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 같이 놀자고 하는 친구를 끼워준다.",
        value: "감성형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 자기와 수준이 비슷한 친구와 어울리는 걸 좋아한다.",
        value: "논리형",
        answer: "",
      },
      {
        question: "나의 자녀는 무언가를 결정할 때는 신중한 편이다.",
        value: "혼자형",
        answer: "",
      },
      {
        question: "나의 자녀는 자신이 하기 싫으면 하기 싫다고 거절하는 편이다.",
        value: "논리형",
        answer: "",
      },
      {
        question:
          "나의 자녀는 형제나 친구와 장남감을 함께 나눠서 놀기를 즐긴다.",
        value: "감성형",
        answer: "",
      },
      {
        question: "나의 자녀는 형제나 친구에게 경쟁을 하거나 비교하지 않는다.",
        value: "의사결정방식-잠재역량",
        answer: "",
      },
    ],
  ],
};
