export const colors = {
  clear: "transparent",
  red: "#e60012",
  "rose pink": "#e48384",
  pink: "#f8cccc",
  orange: "#f8b62d",
  yellow: "#fff100",
  gold: "#d0a912",
  lemon: "#f3ec96",
  green: "#22ac38",
  blue: "#036eb8",
  turquoise: "#afdde1",
  "pale blue": "#aecbe3",
  "royal blue": "#172a88",
  violet: "#601986",
  purple: "#920783",
  magenta: "#e4007f",
  lilac: "#c4b4d7",
  navy: "#0b3878",
  gray: "#5a636c",
  tableBackground: "#e6ecf7",
  primary: "#b3c6e6",
  gradient1: "#cdd7e9",
  gradient2: "#8393be",
  graphLine: "#d8df00",
  lightGray: "#a9a9a9",
  grayBorder: "#aeabab",
};
