import { useEffect, useState } from "react";

export default function Explanation({ categoryList }) {
  const DEFAULT_LIST = [
    {
      title: "성과만족방식",
      desc: "",
    },
    {
      title: "정보수집방식",
      desc: "",
    },
    {
      title: "능력표현방식",
      desc: "",
    },
    {
      title: "목표실행방식",
      desc: "",
    },
    {
      title: "목적성취방식",
      desc: "",
    },
  ];

  const [explanation, setExplanation] = useState(DEFAULT_LIST);

  useEffect(() => {
    setExplanation((prev) => {
      if ([...new Set(categoryList[0].selectedType)].length === 2)
        prev[0].desc =
          "개인의 성장과 조직의 성장 두 성장을 동시에 만족하고 명예와 개인의 행복 두 가지의 균형과 조화를 이루기 위해 상호의존성을 가진다.";
      else if (categoryList[0].selectedType.includes("S"))
        prev[0].desc =
          "조직에서의 성공이나 명예나 돈보다는 개인의 행복이나 발전과 성장에 관심을 가지며 선택의 기준이 된다.";
      else if (categoryList[0].selectedType.includes("G"))
        prev[0].desc =
          "개인의 성공이나 행복보다는 조직의 성장과 조직 안에서 자신의 성공이나 승진 직위와 명예를 이룰 때 성취감을 가진다.";
      if ([...new Set(categoryList[1].selectedType)].length === 2)
        prev[1].desc = (
          <span>
            - 직관형(Intuitive type) 직감을 사용하여 정보를 받아들이고 활용하는
            행동방식으로 순간적 판단과 결정을 내린다. 미래의 가능성을
            중요시한다. 아이디어와 상상력이 풍부하다.
            <br />
            - 경험형(Experiential type)
            <br />
            오감을 사용하여 정보를 받아들이고 활용하는 행동방식으로 자신의
            경험을 바탕으로 판단하고 결정을 내린다. 현실을 중요시한다.
          </span>
        );
      else if (categoryList[1].selectedType.includes("I"))
        prev[1].desc =
          "직감을 사용하여 정보를 받아들이고 활용하는 행동방식으로 순간적 판단과 결정을 내린다. 미래의 가능성을 중요시한다. 아이디어와 상상력이 풍부하다.";
      else if (categoryList[1].selectedType.includes("E"))
        prev[1].desc =
          "오감을 사용하여 정보를 받아들이고 활용하는 행동방식으로 자신의 경험을 바탕으로 판단하고 결정을 내린다. 현실을 중요시한다.";
      if ([...new Set(categoryList[2].selectedType)].length === 2)
        prev[2].desc = (
          <span>
            - 설계형(Design type)
            <br />
            자신의 감정을 인지 할 때 원인과 결과를 알고 싶어 하며 능력표현의
            행동방식 또한 전체를 살피고 기획하고 구상하고 그것을 전달하는 언어
            구사력을 갖추었다.
            <br />- 기술형(Technical type)
            <br />
            자신의 감정을 인지할 때 결과에 초점을 맞추고 능력표현의 행동방식은
            한 곳에 집중하고 그것을 현실로 만들어 내는 능력이 있다.
          </span>
        );
      else if (categoryList[2].selectedType.includes("D"))
        prev[2].desc =
          "자신의 감정을 인지 할 때 원인과 결과를 알고 싶어 하며 능력표현의 행동방식 또한 전체를 살피고 기획하고 구상하고 그것을 전달하는 언어 구사력을 갖추었다.";
      else if (categoryList[2].selectedType.includes("T"))
        prev[2].desc =
          "자신의 감정을 인지할 때 결과에 초점을 맞추고 능력표현의 행동방식은 한 곳에 집중하고 그것을 현실로 만들어 내는 능력이 있다.";
      if ([...new Set(categoryList[3].selectedType)].length === 2)
        prev[3].desc = (
          <span>
            - 전술형(Tactical type)
            <br />
            자신의 현실적인 문제를 지금의 상황에 초점을 맞추어 인지하고 행동하는
            방식을 말한다. 사실적이고 구체적이고 단기적인 답을 원한다.
            <br />
            - 전략형(Strategic type)
            <br />
            자신의 현실적인 문제를 미래와 나중에 초점을 맞춰서 인지하고 행동하는
            방식을 말한다. 전체적이고 포괄적이고 장기적인 답을 원한다.
          </span>
        );
      else if (categoryList[3].selectedType.includes("T"))
        prev[3].desc =
          "자신의 현실적인 문제를 지금의 상황에 초점을 맞추어 인지하고 행동하는 방식을 말한다. 사실적이고 구체적이고 단기적인 답을 원한다.";
      else if (categoryList[3].selectedType.includes("S"))
        prev[3].desc =
          "자신의 현실적인 문제를 미래와 나중에 초점을 맞춰서 인지하고 행동하는 방식을 말한다. 전체적이고 포괄적이고 장기적인 답을 원한다.";
      if ([...new Set(categoryList[4].selectedType)].length === 2)
        prev[4].desc = (
          <span>
            - 과제형(Task type)
            <br />
            일의 중심을 과제에 두고 인지하고 행동하는 방식을 말한다. 과제를
            완수하는 것을 자신의 책임감으로 인지하고 과제 중심적으로 행동한다.
            <br />
            - 관계형(Relational type)
            <br />
            일의 중심을 상하관계에 두고 인지하고 행동하는 방식을 말한다. 일을
            완수하는 것은 자신을 믿어주고 맡겨 준 사람에 대한 충성이라 인지하고
            행동한다.
            <br />
          </span>
        );
      else if (categoryList[4].selectedType.includes("T"))
        prev[4].desc =
          "일의 중심을 과제에 두고 인지하고 행동하는 방식을 말한다. 과제를 완수하는 것을 자신의 책임감으로 인지하고 과제 중심적으로 행동한다.";
      else if (categoryList[4].selectedType.includes("R"))
        prev[4].desc =
          "일의 중심을 상하관계에 두고 인지하고 행동하는 방식을 말한다. 일을 완수하는 것은 자신을 믿어주고 맡겨 준 사람에 대한 충성이라 인지하고 행동한다.";
      return [...prev];
    });
  }, [categoryList]);

  return (
    <div
      style={{
        fontSize: 13,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {explanation?.map(({ title, desc }) => (
        <div
          key={title}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            lineHeight: 1.25,
          }}
        >
          <div>· {title} :</div>
          <div style={{ marginLeft: 10, marginTop: 4 }}>{desc}</div>
        </div>
      ))}
    </div>
  );
}
