import useScreenSize from "../hooks/useScreenSize";
import moment from "moment";
import { colors } from "../styles/colors";
import RoundGraph from "./roundGraph";
import { convertDateToAuraSoma } from "../utils/convertToAuraSoma";
import { PDF_PADDING } from "../utils/consts";
import Icon from "./icon";
import TopLeftLine from "./topLeftLine";
import TopRightLine from "./topRightLine";
import BottomRightLine from "./bottomRightLine";
import BottomLeftLine from "./bottomLeftLine";
import { useEffect, useState } from "react";

export default function Graph({
  graphTitle,
  data,
  colorSize,
  top,
  left,
  right,
  bottom,
  birthDate,
}) {
  const { width: screenWidth } = useScreenSize();
  const width = (screenWidth - PDF_PADDING * 2) * 0.35;
  const outWidth = width * 0.85;
  const borderWidth = 1;
  const auraSoma = convertDateToAuraSoma(moment(birthDate).format("YYYYMMDD"));
  const [iconList, setIconList] = useState([]);

  useEffect(() => {
    let tempList = [
      {
        type: "목적성취방식",
        icons: [],
      },
      {
        type: "능력표현방식",
        icons: [],
      },
      {
        type: "목표실행방식",
        icons: [],
      },
      {
        type: "정보수집방식",
        icons: [],
      },
      {
        type: "자기방어방식",
        icons: [],
      },
    ];
    // yearSky: Round
    if (
      data?.saju.yearSky.tenStar === "정관" ||
      data?.saju.yearSky.tenStar === "편관"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목적성취방식")
      ].icons.push("Round");
    if (
      data?.saju.yearSky.tenStar === "상관" ||
      data?.saju.yearSky.tenStar === "식신"
    )
      tempList[
        tempList.findIndex((item) => item.type === "능력표현방식")
      ].icons.push("Round");
    if (
      data?.saju.yearSky.tenStar === "정재" ||
      data?.saju.yearSky.tenStar === "편재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목표실행방식")
      ].icons.push("Round");
    if (
      data?.saju.yearSky.tenStar === "정인" ||
      data?.saju.yearSky.tenStar === "편인"
    )
      tempList[
        tempList.findIndex((item) => item.type === "정보수집방식")
      ].icons.push("Round");
    if (
      data?.saju.yearSky.tenStar === "비견" ||
      data?.saju.yearSky.tenStar === "겁재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "자기방어방식")
      ].icons.push("Round");

    // yearGround: RoundFilled
    if (
      data?.saju.yearGround.tenStar === "정관" ||
      data?.saju.yearGround.tenStar === "편관"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목적성취방식")
      ].icons.push("RoundFilled");
    if (
      data?.saju.yearGround.tenStar === "상관" ||
      data?.saju.yearGround.tenStar === "식신"
    )
      tempList[
        tempList.findIndex((item) => item.type === "능력표현방식")
      ].icons.push("RoundFilled");
    if (
      data?.saju.yearGround.tenStar === "정재" ||
      data?.saju.yearGround.tenStar === "편재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목표실행방식")
      ].icons.push("RoundFilled");
    if (
      data?.saju.yearGround.tenStar === "정인" ||
      data?.saju.yearGround.tenStar === "편인"
    )
      tempList[
        tempList.findIndex((item) => item.type === "정보수집방식")
      ].icons.push("RoundFilled");
    if (
      data?.saju.yearGround.tenStar === "비견" ||
      data?.saju.yearGround.tenStar === "겁재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "자기방어방식")
      ].icons.push("RoundFilled");

    // monthSky: Star
    if (
      data?.saju.monthSky.tenStar === "정관" ||
      data?.saju.monthSky.tenStar === "편관"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목적성취방식")
      ].icons.push("Star");
    if (
      data?.saju.monthSky.tenStar === "상관" ||
      data?.saju.monthSky.tenStar === "식신"
    )
      tempList[
        tempList.findIndex((item) => item.type === "능력표현방식")
      ].icons.push("Star");
    if (
      data?.saju.monthSky.tenStar === "정재" ||
      data?.saju.monthSky.tenStar === "편재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목표실행방식")
      ].icons.push("Star");
    if (
      data?.saju.monthSky.tenStar === "정인" ||
      data?.saju.monthSky.tenStar === "편인"
    )
      tempList[
        tempList.findIndex((item) => item.type === "정보수집방식")
      ].icons.push("Star");
    if (
      data?.saju.monthSky.tenStar === "비견" ||
      data?.saju.monthSky.tenStar === "겁재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "자기방어방식")
      ].icons.push("Star");

    // monthGround: StarFilled
    if (
      data?.saju.monthGround.tenStar === "정관" ||
      data?.saju.monthGround.tenStar === "편관"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목적성취방식")
      ].icons.push("StarFilled");
    if (
      data?.saju.monthGround.tenStar === "상관" ||
      data?.saju.monthGround.tenStar === "식신"
    )
      tempList[
        tempList.findIndex((item) => item.type === "능력표현방식")
      ].icons.push("StarFilled");
    if (
      data?.saju.monthGround.tenStar === "정재" ||
      data?.saju.monthGround.tenStar === "편재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목표실행방식")
      ].icons.push("StarFilled");
    if (
      data?.saju.monthGround.tenStar === "정인" ||
      data?.saju.monthGround.tenStar === "편인"
    )
      tempList[
        tempList.findIndex((item) => item.type === "정보수집방식")
      ].icons.push("StarFilled");
    if (
      data?.saju.monthGround.tenStar === "비견" ||
      data?.saju.monthGround.tenStar === "겁재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "자기방어방식")
      ].icons.push("StarFilled");

    // dayGround: Triangle
    if (
      data?.saju.dayGround.tenStar === "정관" ||
      data?.saju.dayGround.tenStar === "편관"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목적성취방식")
      ].icons.push("Triangle");
    if (
      data?.saju.dayGround.tenStar === "상관" ||
      data?.saju.dayGround.tenStar === "식신"
    )
      tempList[
        tempList.findIndex((item) => item.type === "능력표현방식")
      ].icons.push("Triangle");
    if (
      data?.saju.dayGround.tenStar === "정재" ||
      data?.saju.dayGround.tenStar === "편재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "목표실행방식")
      ].icons.push("Triangle");
    if (
      data?.saju.dayGround.tenStar === "정인" ||
      data?.saju.dayGround.tenStar === "편인"
    )
      tempList[
        tempList.findIndex((item) => item.type === "정보수집방식")
      ].icons.push("Triangle");
    if (
      data?.saju.dayGround.tenStar === "비견" ||
      data?.saju.dayGround.tenStar === "겁재"
    )
      tempList[
        tempList.findIndex((item) => item.type === "자기방어방식")
      ].icons.push("Triangle");

    setIconList([...tempList]);
  }, [data]);

  const Icons = ({ icons }) => {
    if (icons)
      return (
        <>
          {icons?.map((icon) => (
            <Icon
              key={icon}
              iconName={icon}
              iconSize={10}
              style={{ marginRight: 2 }}
            />
          ))}
        </>
      );
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        fontSize: 12,
        margin: "8px 0",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icons
          icons={
            iconList.filter((item) => item.type === "정보수집방식")[0]?.icons
          }
        />
        <span>정보수집방식</span>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 4 }}>목적성취방식</span>
          <Icons
            icons={
              iconList.filter((item) => item.type === "목적성취방식")[0]?.icons
            }
          />
        </div>
        <div style={{ padding: 6 }}>
          <div
            style={{
              display: "flex",
              width,
              height: width,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width,
                height: width,
                zIndex: 6,
                position: "absolute",
              }}
            >
              <Icon iconName={"Axis"} iconSize={width} />
            </div>
            <RoundGraph
              graphTitle={graphTitle}
              outWidth={outWidth}
              colorSize={colorSize}
              borderWidth={borderWidth}
              borderColor={colors[auraSoma?.borderColor]}
              backgroundColor={colors[auraSoma?.backgroundColor]}
            />
            {Boolean(top) && Boolean(left) ? (
              <TopLeftLine
                width={width}
                outWidth={outWidth}
                top={top}
                left={left}
              />
            ) : Boolean(left) ? (
              <div
                style={{
                  width: (left * outWidth) / 14,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - (left * outWidth) / 14,
                  zIndex: 30,
                }}
              />
            ) : (
              <div
                style={{
                  width: 2,
                  height: (top * outWidth) / 14,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  top: top ? width / 2 - (top * outWidth) / 14 : 2,
                  zIndex: 30,
                }}
              />
            )}
            {Boolean(top) && Boolean(right) ? (
              <TopRightLine
                width={width}
                outWidth={outWidth}
                top={top}
                right={right}
              />
            ) : Boolean(right) ? (
              <div
                style={{
                  width: (right * outWidth) / 14 + 2 / 2,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - 2 / 2,
                  zIndex: 30,
                }}
              />
            ) : (
              Boolean(top) && (
                <div
                  style={{
                    width: 2,
                    height: (top * outWidth) / 14 + 2 / 2,
                    backgroundColor: colors.graphLine,
                    position: "absolute",
                    top: top ? width / 2 - (top * outWidth) / 14 : 2,
                    zIndex: 30,
                  }}
                />
              )
            )}
            {Boolean(bottom) && Boolean(right) ? (
              <BottomRightLine
                width={width}
                outWidth={outWidth}
                bottom={bottom}
                right={right}
                strokeWidth={2}
              />
            ) : Boolean(right) ? (
              <div
                style={{
                  width: (right * outWidth) / 14 + 2 / 2,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - 2 / 2,
                  zIndex: 30,
                }}
              />
            ) : (
              Boolean(bottom) && (
                <div
                  style={{
                    width: 2,
                    height: (bottom * outWidth) / 14 + 2 / 2,
                    backgroundColor: colors.graphLine,
                    position: "absolute",
                    top: width / 2 - 2 / 2,
                    zIndex: 30,
                  }}
                />
              )
            )}
            {Boolean(bottom) && Boolean(left) ? (
              <BottomLeftLine
                width={width}
                outWidth={outWidth}
                bottom={bottom}
                left={left}
              />
            ) : Boolean(left) ? (
              <div
                style={{
                  width: (left * outWidth) / 14 + 2 / 2,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - (left * outWidth) / 14,
                  zIndex: 30,
                }}
              />
            ) : (
              <div
                style={{
                  width: 2,
                  height: (bottom * outWidth) / 14,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  top: width / 2,
                  zIndex: 30,
                }}
              />
            )}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 4 }}>목표실행방식</span>
          <Icons
            icons={
              iconList.filter((item) => item.type === "목표실행방식")[0]?.icons
            }
          />
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            position: "absolute",
            bottom: outWidth * 0.35,
            left: -45,
          }}
        >
          <Icon iconName="CurvedArrow" iconSize={50} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginLeft: 6,
            }}
          >
            <span>자기결정방식</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  marginRight: 4,
                  whiteSpace: "nowrap",
                }}
              >
                자기방어방식
              </span>
              <Icons
                icons={
                  iconList.filter((item) => item.type === "자기방어방식")[0]
                    ?.icons
                }
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 4 }}>능력표현방식</span>
          <Icons
            icons={
              iconList.filter((item) => item.type === "능력표현방식")[0]?.icons
            }
          />
        </div>
      </div>
    </div>
  );
}
