import { colors } from "../styles/colors";

export default function TopRightLine({ width, outWidth, top, right }) {
  return (
    <div
      style={{
        position: "absolute",
        top: width / 2 - (top * outWidth) / 14,
        left: width / 2,
        zIndex: 30,
      }}
    >
      <svg
        width={(right * outWidth) / 14}
        height={(top * outWidth) / 14}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${(right * outWidth) / 14} ${(top * outWidth) / 14}`}
      >
        <line
          x1="0"
          y1="0"
          x2={(right * outWidth) / 14}
          y2={(top * outWidth) / 14}
          stroke={colors.graphLine}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}
