import { colors } from "../styles/colors";

export default function FriendBehaviorExplanation({ categoryList }) {
  return (
    <div
      style={{
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", lineHeight: 1.2 }}
      >
        <div style={{ marginLeft: 10 }}>
          <div>
            <span>
              · 자기결정방식 :{" "}
              {[...new Set(categoryList[0].selectedType)].length === 2 &&
                "상황에 따라 혼자형과 그룹형 두 가지 행동방식을 사용한다."}
            </span>
            <div
              style={{
                display: "flex",
                marginTop: 8,
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: categoryList[0].selectedType.includes("S")
                    ? "black"
                    : colors.grayBorder,
                  borderRight: `1px solid ${colors.primary}`,
                  padding: "10px 16px 10px 0",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    justifyContent: "center",
                    padding: 8,
                    marginRight: 16,
                  }}
                >
                  혼자형
                </div>
                <span>
                  자신의 성장과 그것을 준비하는 과정에서 스스로 찾고 스스로에게
                  물으며 답을 찾는 과정을 혼자 하는 형이며 준비가 된 후 주변의
                  도움을 받거나 함께 해 나갈 때 효과적이고 시너지가 높아진다.
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 8,
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: categoryList[0].selectedType.includes("G")
                      ? "black"
                      : colors.grayBorder,
                    padding: "0px 16px 10px 16px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      justifyContent: "center",
                      padding: 8,
                      marginRight: 16,
                    }}
                  >
                    그룹형
                  </div>
                  <span>
                    자신의 성장과 그것을 준비하는 과정에서 함께 찾고 함께
                    경쟁하며 그 과정 중에서 그룹으로 함께하는 형이며
                    상호관계사이에서 인사이트를 얻고 성장하며 시너지가 높아진다.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: 10 }}>
          <div>
            <span>· 자기방어방식 : </span>
            <div
              style={{
                display: "flex",
                marginTop: 8,
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: categoryList[1].selectedType.includes("L")
                    ? "black"
                    : colors.grayBorder,
                  borderRight: `1px solid ${colors.primary}`,
                  padding: "10px 16px 10px 0",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    justifyContent: "center",
                    padding: 8,
                    marginRight: 16,
                  }}
                >
                  논리형
                </div>
                <span>
                  현실적인 상황을 기반으로 외적자극에 민감하기 때문에 관계에서
                  자신의 상황을 우선으로 생각하고 거절이 가능하다.
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: 8,
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: categoryList[1].selectedType.includes("E")
                      ? "black"
                      : colors.grayBorder,
                    padding: "0px px16px 8px 16px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      justifyContent: "center",
                      padding: 8,
                      marginRight: 16,
                    }}
                  >
                    감성형
                  </div>
                  <span>
                    마음의 상태를 기반으로 내적자극에 민감하다. 자신이나 타인의
                    마음을 우선시하는 등 관계를 우선시하기에 거절이 어렵다.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
