import { colors } from "../styles/colors";
import { useUser } from "../hooks/useUser";

export default function EvaluationFormTitle({ part }) {
  const { evaluationFormType } = useUser();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: 80,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: colors.navy,
            color: "#fff",
            fontWeight: 600,
            fontSize: 24,
            width: 90,
            height: 50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            marginRight: 16,
          }}
        >
          <span>{`part.${part}`}</span>
        </div>
        <div style={{ color: colors.navy, fontSize: 24 }}>
          <span style={{ fontWeight: 700 }}>CRATA</span>
          <span>진단 문항</span>
        </div>
      </div>
      <span style={{ color: colors.navy, fontSize: 14, fontWeight: 500 }}>
        {evaluationFormType}
      </span>
    </div>
  );
}
