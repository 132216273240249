import moment from "moment";
import { colors } from "../styles/colors";
import Icon from "./icon";
import { calcAge } from "../utils/utils";
import { DATE_FORMAT } from "../utils/consts";
import { useUser } from "../hooks/useUser";

export default function UserInfo() {
  const {
    userName,
    userType,
    customUserType,
    userBirthday,
    tester,
    customTester,
    gender,
  } = useUser();

  const borderStyle = `1px solid ${colors.primary}`;

  const Head = ({ children, last }) => {
    return (
      <div
        style={{
          backgroundColor: colors.tableBackground,
          padding: 2,
          borderTop: borderStyle,
          borderBottom: last ? borderStyle : "none",
          fontSize: 13,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          color: colors.navy,
          fontWeight: 600,
        }}
      >
        {children}
      </div>
    );
  };

  const Body = ({ children, last }) => {
    return (
      <div
        style={{
          borderTop: borderStyle,
          padding: "4px 10px",
          borderBottom: last ? borderStyle : "none",
          fontSize: 13,
        }}
      >
        {children}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", marginBottom: 8, alignItems: "center" }}>
        <Icon iconName={"Icon3"} iconSize={18} />
        <div style={{ marginLeft: 6, fontSize: 16, fontWeight: 600 }}>
          작성자 정보
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "120px 1fr 120px 1fr",
        }}
      >
        <Head>대상자 이름</Head>
        <Body>{userName}</Body>
        <Head>규준집단</Head>
        <Body>{customUserType || userType}</Body>
        <Head>개인고유번호</Head>
        <Body>{`${moment(userBirthday).format(DATE_FORMAT)}(${
          gender === "MALE" ? "남" : "여"
        })`}</Body>
        <Head>연령</Head>
        <Body>{`${calcAge(userBirthday)}세`}</Body>
        <Head last>검사일</Head>
        <Body last>{moment().format(DATE_FORMAT)}</Body>
        <Head last>검사자</Head>
        <Body last>{customTester || tester}</Body>
      </div>
    </div>
  );
}
