import { useUser } from "../hooks/useUser";
import { colors } from "../styles/colors";

export default function PageTitle({ style, title }) {
  const { evaluationFormType } = useUser();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        color: colors.primary,
        borderTop: `1px solid ${colors.primary}`,
        borderBottom: `1px solid ${colors.primary}`,
        padding: 6,
        boxSizing: "border-box",
      }}
    >
      <span
        style={{
          fontSize: 36,
          fontWeight: "bold",
        }}
      >
        {"CRATA "}
        <span style={{ fontSize: 24, fontWeight: 500 }}>{title}</span>
      </span>
      <span style={{ fontSize: 14, fontWeight: 500 }}>
        {evaluationFormType}
      </span>
    </div>
  );
}
