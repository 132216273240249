import moment from "moment";
import { colors } from "../styles/colors";
import { TYPE_MAP } from "../utils/consts";
import { calcSajuAge } from "../utils/utils";
import { useUser } from "../hooks/useUser";
import { useAutoForm } from "../hooks/useAutoForm";
import { useEffect, useState } from "react";
export default function Solution({ data, index }) {
  const { jsonData } = useAutoForm();
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (index > -1) setUserData(jsonData[index]);
  }, []);

  const user = useUser();
  const userBirthday = userData?.birthDay || user.userBirthday;

  const smallFortuneArray = Object.keys(data.list.smallFortune).map(
    (key) => data.list.smallFortune[key]
  );

  const isBigfortuneStarted =
    data.list.bigFortune["1"].number <= calcSajuAge(userBirthday);

  const SOLUTION_LIST = [
    {
      leftType: isBigfortuneStarted
        ? TYPE_MAP[data.list.bigFortune[data.fortune.big]?.sky.tenStar]
        : { alphabet: "", kr: " ", en: "" },

      rightType: isBigfortuneStarted
        ? TYPE_MAP[data.list.bigFortune[data.fortune.big]?.ground.tenStar]
        : { alphabet: "", kr: " ", en: "" },
    },
    {
      leftType:
        TYPE_MAP[
          smallFortuneArray.find(
            (item) => item.year.toString() === moment().format("YYYY")
          )?.sky.tenStar
        ],
      rightType:
        TYPE_MAP[
          smallFortuneArray.find(
            (item) => item.year.toString() === moment().format("YYYY")
          )?.ground.tenStar
        ],
    },
  ];

  const smallFortunePercent =
    ((smallFortuneArray.length -
      (smallFortuneArray.findIndex(
        (item) => item.year.toString() === moment().format("YYYY")
      ) +
        1)) /
      smallFortuneArray.length) *
    100;

  const Item = ({ index, leftType, rightType, style }) => {
    return (
      <>
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <div
            style={{
              borderRadius: 999,
              fontWeight: 600,
              display: "flex",
              fontSize: 20,
              padding: 10,
            }}
          >
            {leftType?.alphabet}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: colors.navy,
              width: "100%",
              fontSize: 13,
              marginTop: index ? 0 : 14,
              ...style,
            }}
          >
            <div
              style={{ display: "flex", width: "100%", position: "relative" }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    borderRadius: 999,
                    border: `1px solid ${colors.primary}`,
                    justifyContent: "center",
                    position: "relative",
                    marginBottom: 4,
                    height: 34,
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {Array(10)
                      .fill("")
                      .map((x, i) => (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            flex: 1,
                            borderLeft: `1px solid ${
                              i === 0 ? "transparent" : colors.navy
                            }`,
                            height: "50%",
                          }}
                        />
                      ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      height: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: colors.primary,
                        height: "100%",
                        width: `${index ? 0 : smallFortunePercent}%`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: 999,
              fontWeight: 600,
              fontSize: 20,
              padding: 10,
            }}
          >
            {rightType?.alphabet}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 14,
            fontSize: 13,
          }}
        >
          <div style={{ display: "flex" }}>
            <span style={{ fontWeight: 600, color: colors.gray }}>
              {leftType.kr}
            </span>
            {leftType?.alphabet && (
              <span style={{ display: "flex", color: colors.gray }}>
                (<span style={{ fontWeight: 600 }}>{leftType?.alphabet}</span>
                {leftType.en})
              </span>
            )}
          </div>
          <div style={{ display: "flex", height: 14 }}>
            <span style={{ fontWeight: 600, color: colors.gray }}>
              {rightType.kr}
            </span>
            {rightType?.alphabet && (
              <span style={{ display: "flex", color: colors.gray }}>
                (<span style={{ fontWeight: 600 }}>{rightType?.alphabet}</span>
                {rightType.en})
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        padding: "0 10px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          height: "100%",
          marginBottom: 40,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <span
          style={{
            color: colors.gray,
            padding: 16,
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          인지
        </span>
      </div>
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          fontSize: 16,
          justifyContent: "space-evenly",
        }}
      >
        {SOLUTION_LIST.map((item, index) => (
          <Item key={index} {...item} index={index} />
        ))}
      </div>
      <div
        style={{
          height: "100%",
          marginBottom: 40,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <span
          style={{
            color: colors.gray,
            padding: 16,
            fontSize: 13,
            fontWeight: 500,
          }}
        >
          행동
        </span>
      </div>
    </div>
  );
}
