import React, { createContext, useContext, useState } from "react";
import * as XLSX from "xlsx";
import moment from "moment";

const AutoFormContext = createContext();

export const AutoFormProvider = ({ children }) => {
  const [jsonData, setJsonData] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      let json = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });
      json = json.slice(1);
      const filtered = json.filter((x) => !x.includes(""));

      const answer = filtered.map((item, index) => {
        const excelDate = item.shift();
        const excelStartDate = moment("1900-01-01");
        const date = moment(excelStartDate.add(excelDate - 2, "days")).format(
          "YYYY.MM.DD"
        );
        const name = item.shift();
        const gender = item.shift();
        const birthDay = item.shift();
        item.splice(0, 2);
        const tester = item.shift();

        const tempArr = item.map((x) => (x === "그렇다" ? "true" : "false"));
        const chunkSize = 20;
        const data = tempArr.reduce((acc, _, i) => {
          if (i % chunkSize === 0) acc.push(tempArr.slice(i, i + chunkSize));
          return acc;
        }, []);

        return {
          tester,
          date,
          name,
          gender,
          birthDay:
            typeof birthDay === "number" && birthDay.toString().length === 8
              ? birthDay
              : 20000101,
          data,
        };
      });

      setJsonData(answer);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <AutoFormContext.Provider
      value={{
        jsonData,
        setJsonData,
        handleFileUpload,
      }}
    >
      {children}
    </AutoFormContext.Provider>
  );
};

export const useAutoForm = () => useContext(AutoFormContext);
