import { useUser } from "../hooks/useUser";
import { colors } from "../styles/colors";
import Icon from "./icon";

export default function PdfTitle() {
  const { evaluationFormType } = useUser();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        background: `linear-gradient(to right, ${colors.gradient1}, ${colors.gradient2})`,
        borderTopLeftRadius: 16,
        width: "100%",
        padding: "20px 30px",
        margin: "10px 36px 60px",
        boxSizing: "border-box",
      }}
    >
      <Icon iconName="Icon4" iconSize={70} />
      <div
        style={{
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <span style={{ fontSize: 24, marginBottom: 16 }}>
          다면적 행동유형 검사
        </span>
        <span style={{ fontSize: 44, fontWeight: 700 }}>
          CRATA
          <span style={{ fontWeight: 400, fontSize: 38, marginLeft: 16 }}>
            분석 보고서
          </span>
        </span>
        <span style={{ fontSize: 24, fontWeight: 600 }}>
          {evaluationFormType}
        </span>
      </div>
    </div>
  );
}
