import { colors } from "../styles/colors";
import { useEvaluation } from "../hooks/useEvaluation";
import { useUser } from "../hooks/useUser";
import { FORM_TYPE_ADULT } from "../utils/consts";
import { useAutoForm } from "../hooks/useAutoForm";
import { useEffect, useState } from "react";

export default function Potential({ index }) {
  const { jsonData } = useAutoForm();
  const { testQuestion } = useEvaluation();
  const { evaluationFormType } = useUser();
  const [question, setQuestion] = useState(testQuestion);

  useEffect(() => {
    if (index > -1) setQuestion(jsonData[index].data);
  }, []);

  const checkEvaluationFormType = (type) =>
    // type === FORM_TYPE_ADULT ? "false" : "true";
    type === FORM_TYPE_ADULT ? "true" : "true";

  const CATEGORY_LIST = [
    {
      leftType: {
        kr: "정보수집방식",
        level: question[0]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: question[0]?.filter((item) => item.value === "잠재역량")
          ?.length,
      },
      rightType: {
        kr: "능력표현방식",
        level: question[1]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: question[1]?.filter((item) => item.value === "잠재역량")
          ?.length,
      },
    },
    {
      leftType: {
        kr: "목표실행방식",
        level: question[2]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: question[2]?.filter((item) => item.value === "잠재역량")
          ?.length,
      },
      rightType: {
        kr: "목적성취방식",
        level: question[3]?.filter(
          (item) =>
            item.value === "잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: question[3]?.filter((item) => item.value === "잠재역량")
          ?.length,
      },
    },
    {
      leftType: {
        kr: "의사결정방식",
        level: question[4]?.filter(
          (item) =>
            item.value === "의사결정방식-잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: question[4]?.filter(
          (item) => item.value === "의사결정방식-잠재역량"
        )?.length,
      },
      rightType: {
        kr: "자기방어방식",
        level: question[4]?.filter(
          (item) =>
            item.value === "자기방어방식-잠재역량" &&
            item.answer === checkEvaluationFormType(evaluationFormType)
        )?.length,
        totalQuestion: question[4]?.filter(
          (item) => item.value === "자기방어방식-잠재역량"
        )?.length,
      },
    },
  ];

  const Item = ({ leftType, rightType }) => {
    return (
      <>
        <div
          style={{
            fontSize: 13,
            fontWeight: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              borderBottom: `1px solid ${colors.primary}`,
              paddingBottom: 6,
            }}
          >
            {leftType.kr}
          </div>
          <div
            style={{
              display: "flex",
              height: 90,
              width: 24,
              flexDirection: "column",
              borderBottomRightRadius: 999,
              borderBottomLeftRadius: 999,
              border: `1px solid ${colors.primary}`,
              borderTop: "none",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: leftType.level ? "flex" : "hidden",
                backgroundColor: colors.primary,
                width: "100%",
                height: `${(leftType.level / leftType.totalQuestion) * 100}%`,
              }}
            />
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {Array(4)
                .fill("")
                .map((x, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "50%",
                      borderTop: `1px solid ${
                        i === 0 ? "transparent" : colors.navy
                      }`,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
        <div
          style={{
            fontSize: 13,
            fontWeight: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              borderBottom: `1px solid ${colors.primary}`,
              paddingBottom: 6,
            }}
          >
            {rightType.kr}
          </div>
          <div
            style={{
              display: "flex",
              height: 90,
              width: 24,
              flexDirection: "column",
              borderBottomRightRadius: 999,
              borderBottomLeftRadius: 999,
              border: `1px solid ${colors.primary}`,
              borderTop: "none",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: rightType.level ? "flex" : "hidden",
                backgroundColor: colors.primary,
                width: "100%",
                height: `${(rightType.level / rightType.totalQuestion) * 100}%`,
              }}
            />
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {Array(4)
                .fill("")
                .map((x, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "50%",
                      borderTop: `1px solid ${
                        i === 0 ? "transparent" : colors.navy
                      }`,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 16,
        justifyContent: "space-between",
      }}
    >
      {CATEGORY_LIST.map((item, i) => (
        <Item key={i} {...item} />
      ))}
    </div>
  );
}
