import { useEffect, useState } from "react";
import { useAutoForm } from "../hooks/useAutoForm";
import { useUser } from "../hooks/useUser";
import { questionList } from "../utils/consts";
import FormResultItem from "./formResultItem";

export default function AutoResult() {
  const { jsonData, setJsonData } = useAutoForm();
  const { evaluationFormType } = useUser();
  const [toggle, setToggle] = useState(0);

  useEffect(() => {
    if (!jsonData || !questionList[evaluationFormType]) return;

    const updatedJsonData = jsonData.map((item) => {
      let tempArr = JSON.parse(
        JSON.stringify(questionList[evaluationFormType])
      );
      item.data.forEach((x, i) => {
        x.forEach((y, j) => {
          tempArr[i][j].answer = y;
        });
      });
      return { ...item, data: tempArr };
    });

    setJsonData(updatedJsonData);
  }, []);

  return (
    <>
      {jsonData?.map(({ name, birthDay, gender }, index) => {
        return (
          <FormResultItem
            index={index}
            key={name + birthDay}
            userBirthday={birthDay}
            userName={name}
            gender={gender}
            toggle={toggle}
            handleDownloadAll={() => setToggle((prev) => prev + 1)}
          />
        );
      })}
    </>
  );
}
