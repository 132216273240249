import { colors } from "../styles/colors";

export default function BottomRightLine({
  width,
  outWidth,
  bottom,
  right,
  strokeWidth,
}) {
  return (
    <div
      style={{
        position: "absolute",
        top: width / 2,
        right: width / 2 - (right * outWidth) / 14,
        zIndex: 30,
      }}
    >
      <svg
        width={(right * outWidth) / 14}
        height={(bottom * outWidth) / 14}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${(right * outWidth) / 14} ${(bottom * outWidth) / 14}`}
      >
        <line
          x1={(right * outWidth) / 14}
          y1="0"
          x2="0"
          y2={(bottom * outWidth) / 14}
          stroke={colors.graphLine}
          strokeWidth={strokeWidth}
        />
      </svg>
    </div>
  );
}
