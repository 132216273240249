import { ReactComponent as Icon1 } from "./1.svg";
import { ReactComponent as Icon2 } from "./2.svg";
import { ReactComponent as Icon3 } from "./3.svg";
import { ReactComponent as Icon4 } from "./4.svg";
import { ReactComponent as Round } from "./round.svg";
import { ReactComponent as RoundFilled } from "./round-filled.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as StarFilled } from "./star-filled.svg";
import { ReactComponent as Select } from "./select.svg";
import { ReactComponent as Triangle } from "./triangle.svg";
import { ReactComponent as Axis } from "./axis.svg";
import { ReactComponent as CurvedArrow } from "./curved-arrow.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as Frontcover } from "./frontcover.svg";
import { ReactComponent as Backcover } from "./backcover.svg";

const IconAssets = {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Round,
  RoundFilled,
  Star,
  StarFilled,
  Select,
  Triangle,
  Axis,
  CurvedArrow,
  Download,
  Home,
  Frontcover,
  Backcover,
};
export default IconAssets;
