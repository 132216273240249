import Content from "./content";

export default function CrataInfo() {
  const Title = ({ children, style }) => {
    return (
      <span style={{ fontWeight: 700, fontSize: 14, lineHeight: 2, ...style }}>
        {children}
      </span>
    );
  };

  const Text = ({ children }) => {
    return <span style={{ fontSize: 13, lineHeight: 1.25 }}>{children}</span>;
  };
  return (
    <Content title="CRATA분석의 개요" colored style={{ height: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title style={{ marginTop: 2 }}>인간유형</Title>
        <Text>
          사람은 기질에 따라 행동하며, 욕구가 성과에 영향을 준다. 기질을
          기반으로 하는 행동방식과 욕구를 분석하여 스스로를 이해하는데 도움을
          준다. 기질의 우선적 작 용은 내적자극형과 외적자극형으로 나뉜다. 기질의
          성격을 5가지로 분류하면 총 10가지 인간유형이 된다.
          <br />
          <span style={{ fontWeight: 600 }}>내적자극형</span>(Internal
          stimulation)은 현재 자신의 마음 변화가 선택의 기준이 된다.
          <br />
          <span style={{ fontWeight: 600 }}>외적자극형</span>
          (External stimulation)은 현재 자신의 상황 변화가 선택의 기준이 된다.
          <br />
          <span style={{ fontWeight: 600 }}>성장형</span>(Growth type)은 성장을
          중요하게 여기며 배움과 발전을 즐긴다. 개인주의 성향을 지닌데다
          독립적이라 타인을 통한 대리 만족이 어려우며 조직 안에서의 관 계도
          약하다. 기혼여성이라도 사회활동을 통해 인정과 성취를 이루고자 한다.
          전체 적으로 새로운 것에 도전하는 성향이 강하며 시작을 즐긴다.
          <br />
          <span style={{ fontWeight: 600 }}>발산형</span>(Divergent type)은
          타인이나 자신이 속한 조직의 성장을 통해 만족을 느낀 다. 자신이 아는
          것을 타인을 위해 사용하는 것을 좋아한다. 대리만족이 가능하고 타인에게
          영향력을 가지는 것에 보람을 느낀다. 호불호가 강해 관심을 가지는 정도
          에 따라 성과 차이가 크다. 어려운 상황에 투입되는 것을 두려워하지 않고
          열정을 보인다.
          <br />
          <span style={{ fontWeight: 600 }}>균형형</span>(Balance type)은
          한쪽으로 치우치는 것을 경계하며 대인관계에서 중간자 또는 중립자로
          대변인의 역할을 자처한다. 어느 한쪽으로 치우치지 않으려다 보니
          경계인으로의 외로움이 있다. 서포트 역할을 하는 2인자로 자신을 드러내는
          것을 불편해하나, 타인을 관찰하거나 함께 하기를 선호한다.
          <br />
          <span style={{ fontWeight: 600 }}>수확형</span>(Harvesting type)은
          눈에 보이는 확실한 것을 선호한다. 결과 지향적이고 확실하고 실질적인
          것을 만들어 내는 능력이 탁월하다. 문제해결 능력이 뛰어나고 현실적인
          사고를 중시한다. 보여지는 모습과 심리적인 부분의 차이가 커서 대인관
          계에서 진솔한 관계형성이 어렵다. 시작을 어려워하나 점진적으로 강해져
          수확을 이룬다.
          <br />
          <span style={{ fontWeight: 600 }}>축적형</span>(Accumulation type)은
          준비를 우선시한다. 타이밍과 스타일을 중요하게 여 기며 스스로에 대해
          충분히 이해한 후 결정하고 행동한다. 자기 우선적이다 보니 이기적으로
          보일 수 있고 결정을 할 때 시간이 오래 걸린다. 스스로 준비 되어지거 나
          완성되어지면 반전의 모습을 가지고 있다. 다른 사람에게 거름 역할을
          하거나 영감을 주는 것을 선호한다.
        </Text>
        <Title style={{ marginTop: 10 }}>정서적 만족 유형</Title>
        <Text>
          정서적 만족 유형은 자신의 욕구를 이해하고 해소되지 않은 욕구로 인해
          현재에 집 중하지 못하게 만드는 원인(미해결과제)을 찾아서 미해결 과제를
          인지하고 해결할 수 있도록 돕는다. 현재 자신의 발전과 성장의 걸림이
          되는 욕구를 찾아서 현재와 미래를 설계하고 발전에 도움을 준다.
        </Text>
        <Title style={{ marginTop: 10 }}>행동유형</Title>
        <Text>
          행동유형은 무의식적으로 타고난 생존의 방식을 분석한다. 성장을 위한
          행동과 조 직생활에서의 문제해결을 위한 행동을 분석한다. 조직구성원 간
          협업을 할 때 상호 간에 일의 방식을 이해하고 오해없는 관계를 만드는데
          도움을 준다. <span style={{ fontWeight: 600 }}>개인성장형</span>
          (Self-growth type)과&nbsp;
          <span style={{ fontWeight: 600 }}>조직성장형</span>(Group growth
          type)이 있고 두가지 유형이 혼합 된 혼합형(Mixed type)도 있다.
        </Text>
        <Title style={{ marginTop: 10 }}>경쟁관계 행동유형</Title>
        <Text>
          경쟁관계 행동유형은 자기결정방식과 자기방어방식이 있다.&nbsp;
          <span style={{ fontWeight: 600 }}>자기결정방식</span>은 자신의 의사를
          결정할 때 수평적 관계에서 어떤 영향을 받는지를 분석하여 스트레스의 이
          유를 찾아, 동료 집단에서 시너지를 내는 환경을 스스로 선택할 수 있도록
          돕기 위 한 분석이다.
          <span style={{ fontWeight: 600 }}>자기방어방식</span>은 자신을
          보호하기 위해 동료 사이에서 거절의 방식을 찾아 관계의 문제점이나
          자신의 방식을 인지하므로 문제 행동을 진단하고 개선할 수 있도록 돕는
          분석이다.
        </Text>
      </div>
    </Content>
  );
}
