import useScreenSize from "../hooks/useScreenSize";
import moment from "moment";
import { colors } from "../styles/colors";
import RoundGraph from "./roundGraph";
import { convertDateToAuraSoma } from "../utils/convertToAuraSoma";
import { PDF_PADDING } from "../utils/consts";
import Icon from "./icon";
import TopLeftLine from "./topLeftLine";
import TopRightLine from "./topRightLine";
import BottomRightLine from "./bottomRightLine";
import BottomLeftLine from "./bottomLeftLine";
import { useEffect, useState } from "react";

export default function Graph({
  graphTitle,
  data,
  colorSize,
  top,
  left,
  right,
  bottom,
  birthDate,
}) {
  const { width: screenWidth } = useScreenSize();
  const width = (screenWidth - PDF_PADDING * 2) * 0.35;
  const outWidth = width * 0.85;
  const borderWidth = 1;
  const auraSoma = convertDateToAuraSoma(moment(birthDate).format("YYYYMMDD"));
  const [iconList, setIconList] = useState([]);

  useEffect(() => {
    let tempList = [
      {
        type: "과제",
        icons: [],
      },
      {
        type: "관계",
        icons: [],
      },
      {
        type: "경험",
        icons: [],
      },
      {
        type: "직관",
        icons: [],
      },
      {
        type: "전술",
        icons: [],
      },
      {
        type: "전략",
        icons: [],
      },
      {
        type: "논리",
        icons: [],
      },
      {
        type: "감성",
        icons: [],
      },
      {
        type: "혼자",
        icons: [],
      },
      {
        type: "그룹",
        icons: [],
      },
      {
        type: "기술",
        icons: [],
      },
      {
        type: "설계",
        icons: [],
      },
    ];
    // yearSky: Round
    if (data?.saju.yearSky.tenStar === "정관")
      tempList[tempList.findIndex((item) => item.type === "과제")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "편관")
      tempList[tempList.findIndex((item) => item.type === "관계")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "상관")
      tempList[tempList.findIndex((item) => item.type === "설계")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "식신")
      tempList[tempList.findIndex((item) => item.type === "기술")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "정재")
      tempList[tempList.findIndex((item) => item.type === "전술")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "편재")
      tempList[tempList.findIndex((item) => item.type === "전략")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "정인")
      tempList[tempList.findIndex((item) => item.type === "직관")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "편인")
      tempList[tempList.findIndex((item) => item.type === "경험")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "비견")
      tempList[tempList.findIndex((item) => item.type === "논리")].icons.push(
        "Round"
      );
    if (data?.saju.yearSky.tenStar === "겁재")
      tempList[tempList.findIndex((item) => item.type === "감성")].icons.push(
        "Round"
      );

    // yearGround: RoundFilled
    if (data?.saju.yearGround.tenStar === "정관")
      tempList[tempList.findIndex((item) => item.type === "과제")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "편관")
      tempList[tempList.findIndex((item) => item.type === "관계")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "상관")
      tempList[tempList.findIndex((item) => item.type === "설계")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "식신")
      tempList[tempList.findIndex((item) => item.type === "기술")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "정재")
      tempList[tempList.findIndex((item) => item.type === "전술")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "편재")
      tempList[tempList.findIndex((item) => item.type === "전략")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "정인")
      tempList[tempList.findIndex((item) => item.type === "직관")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "편인")
      tempList[tempList.findIndex((item) => item.type === "경험")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "비견")
      tempList[tempList.findIndex((item) => item.type === "논리")].icons.push(
        "RoundFilled"
      );
    if (data?.saju.yearGround.tenStar === "겁재")
      tempList[tempList.findIndex((item) => item.type === "감성")].icons.push(
        "RoundFilled"
      );

    // monthSky: Star
    if (data?.saju.monthSky.tenStar === "정관")
      tempList[tempList.findIndex((item) => item.type === "과제")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "편관")
      tempList[tempList.findIndex((item) => item.type === "관계")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "상관")
      tempList[tempList.findIndex((item) => item.type === "설계")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "식신")
      tempList[tempList.findIndex((item) => item.type === "기술")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "정재")
      tempList[tempList.findIndex((item) => item.type === "전술")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "편재")
      tempList[tempList.findIndex((item) => item.type === "전략")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "정인")
      tempList[tempList.findIndex((item) => item.type === "직관")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "편인")
      tempList[tempList.findIndex((item) => item.type === "경험")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "비견")
      tempList[tempList.findIndex((item) => item.type === "논리")].icons.push(
        "Star"
      );
    if (data?.saju.monthSky.tenStar === "겁재")
      tempList[tempList.findIndex((item) => item.type === "감성")].icons.push(
        "Star"
      );

    // monthGround: StarFilled
    if (data?.saju.monthGround.tenStar === "정관")
      tempList[tempList.findIndex((item) => item.type === "과제")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "편관")
      tempList[tempList.findIndex((item) => item.type === "관계")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "상관")
      tempList[tempList.findIndex((item) => item.type === "설계")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "식신")
      tempList[tempList.findIndex((item) => item.type === "기술")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "정재")
      tempList[tempList.findIndex((item) => item.type === "전술")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "편재")
      tempList[tempList.findIndex((item) => item.type === "전략")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "정인")
      tempList[tempList.findIndex((item) => item.type === "직관")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "편인")
      tempList[tempList.findIndex((item) => item.type === "경험")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "비견")
      tempList[tempList.findIndex((item) => item.type === "논리")].icons.push(
        "StarFilled"
      );
    if (data?.saju.monthGround.tenStar === "겁재")
      tempList[tempList.findIndex((item) => item.type === "감성")].icons.push(
        "StarFilled"
      );

    // dayGround: Triangle
    if (data?.saju.dayGround.tenStar === "정관")
      tempList[tempList.findIndex((item) => item.type === "과제")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "편관")
      tempList[tempList.findIndex((item) => item.type === "관계")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "상관")
      tempList[tempList.findIndex((item) => item.type === "설계")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "식신")
      tempList[tempList.findIndex((item) => item.type === "기술")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "정재")
      tempList[tempList.findIndex((item) => item.type === "전술")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "편재")
      tempList[tempList.findIndex((item) => item.type === "전략")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "정인")
      tempList[tempList.findIndex((item) => item.type === "직관")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "편인")
      tempList[tempList.findIndex((item) => item.type === "경험")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "비견")
      tempList[tempList.findIndex((item) => item.type === "논리")].icons.push(
        "Triangle"
      );
    if (data?.saju.dayGround.tenStar === "겁재")
      tempList[tempList.findIndex((item) => item.type === "감성")].icons.push(
        "Triangle"
      );

    setIconList([...tempList]);
  }, [data]);

  const Icons = ({ icons }) => {
    if (icons)
      return (
        <>
          {icons?.map((icon) => (
            <Icon
              key={icon}
              iconName={icon}
              iconSize={10}
              style={{ marginRight: 2 }}
            />
          ))}
        </>
      );
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        fontSize: 13,
        margin: "8px 4px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span style={{ fontWeight: 600 }}>정보수집방식</span>
        <div
          style={{
            display: "flex",
            marginTop: 4,
            fontSize: 12,
            alignItems: "center",
          }}
        >
          <Icons
            icons={iconList.filter((item) => item.type === "경험")[0]?.icons}
          />
          <div style={{ margin: "0 10px 0 2px" }}>경험</div>
          <div style={{ marginRight: 4 }}>직관</div>
          <Icons
            icons={iconList.filter((item) => item.type === "직관")[0]?.icons}
          />
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontWeight: 600 }}>목적성취방식</span>
          <div
            style={{
              display: "flex",
              marginTop: 4,
              fontSize: 12,
              alignItems: "center",
            }}
          >
            <Icons
              icons={iconList.filter((item) => item.type === "과제")[0]?.icons}
            />
            <div style={{ margin: "0 10px 0 2px" }}>과제</div>
            <div style={{ marginRight: 4 }}>관계</div>
            <Icons
              icons={iconList.filter((item) => item.type === "관계")[0]?.icons}
            />
          </div>
        </div>
        <div style={{ padding: 6 }}>
          <div
            style={{
              display: "flex",
              width,
              height: width,
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width,
                height: width,
                zIndex: 6,
                position: "absolute",
              }}
            >
              <Icon iconName={"Axis"} iconSize={width} />
            </div>
            <RoundGraph
              graphTitle={graphTitle}
              outWidth={outWidth}
              colorSize={colorSize}
              borderWidth={borderWidth}
              borderColor={colors[auraSoma?.borderColor]}
              backgroundColor={colors[auraSoma?.backgroundColor]}
            />
            {Boolean(top) && Boolean(left) ? (
              <TopLeftLine
                width={width}
                outWidth={outWidth}
                top={top}
                left={left}
              />
            ) : Boolean(left) ? (
              <div
                style={{
                  width: (left * outWidth) / 14,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - (left * outWidth) / 14,
                  zIndex: 30,
                }}
              />
            ) : (
              <div
                style={{
                  width: 2,
                  height: (top * outWidth) / 14,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  top: top ? width / 2 - (top * outWidth) / 14 : 2,
                  zIndex: 30,
                }}
              />
            )}
            {Boolean(top) && Boolean(right) ? (
              <TopRightLine
                width={width}
                outWidth={outWidth}
                top={top}
                right={right}
              />
            ) : Boolean(right) ? (
              <div
                style={{
                  width: (right * outWidth) / 14 + 2 / 2,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - 2 / 2,
                  zIndex: 30,
                }}
              />
            ) : (
              Boolean(top) && (
                <div
                  style={{
                    width: 2,
                    height: (top * outWidth) / 14 + 2 / 2,
                    backgroundColor: colors.graphLine,
                    position: "absolute",
                    top: top ? width / 2 - (top * outWidth) / 14 : 2,
                    zIndex: 30,
                  }}
                />
              )
            )}
            {Boolean(bottom) && Boolean(right) ? (
              <BottomRightLine
                width={width}
                outWidth={outWidth}
                bottom={bottom}
                right={right}
                strokeWidth={2}
              />
            ) : Boolean(right) ? (
              <div
                style={{
                  width: (right * outWidth) / 14 + 2 / 2,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - 2 / 2,
                  zIndex: 30,
                }}
              />
            ) : (
              Boolean(bottom) && (
                <div
                  style={{
                    width: 2,
                    height: (bottom * outWidth) / 14 + 2 / 2,
                    backgroundColor: colors.graphLine,
                    position: "absolute",
                    top: width / 2 - 2 / 2,
                    zIndex: 30,
                  }}
                />
              )
            )}
            {Boolean(bottom) && Boolean(left) ? (
              <BottomLeftLine
                width={width}
                outWidth={outWidth}
                bottom={bottom}
                left={left}
              />
            ) : Boolean(left) ? (
              <div
                style={{
                  width: (left * outWidth) / 14 + 2 / 2,
                  height: 2,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  left: width / 2 - (left * outWidth) / 14,
                  zIndex: 30,
                }}
              />
            ) : (
              <div
                style={{
                  width: 2,
                  height: (bottom * outWidth) / 14,
                  backgroundColor: colors.graphLine,
                  position: "absolute",
                  top: width / 2,
                  zIndex: 30,
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontWeight: 600 }}>목표실행방식</span>
          <div
            style={{
              display: "flex",
              marginTop: 4,
              fontSize: 12,
              alignItems: "center",
            }}
          >
            <Icons
              icons={iconList.filter((item) => item.type === "전술")[0]?.icons}
            />
            <div style={{ margin: "0 10px 0 2px" }}>전술</div>
            <div style={{ marginRight: 4 }}>전략</div>
            <Icons
              icons={iconList.filter((item) => item.type === "전략")[0]?.icons}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            position: "absolute",
            bottom: outWidth * 0.35,
            left: -45,
            width: 400,
            alignItems: "flex-end",
          }}
        >
          <Icon iconName="CurvedArrow" iconSize={50} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 5,
              marginLeft: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: 600 }}>자기결정방식</span>
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  marginBottom: 4,
                  fontSize: 12,
                  alignItems: "center",
                }}
              >
                <Icons
                  icons={
                    iconList.filter((item) => item.type === "혼자")[0]?.icons
                  }
                />
                <div style={{ margin: "0 10px 0 2px" }}>혼자</div>
                <div style={{ marginRight: 4 }}>그룹</div>
                <Icons
                  icons={
                    iconList.filter((item) => item.type === "그룹")[0]?.icons
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <span style={{ fontWeight: 600 }}>자기방어방식</span>
              <div
                style={{
                  display: "flex",
                  marginTop: 4,
                  fontSize: 12,
                  alignItems: "center",
                }}
              >
                <Icons
                  icons={
                    iconList.filter((item) => item.type === "논리")[0]?.icons
                  }
                />
                <div style={{ margin: "0 10px 0 2px" }}>논리</div>
                <div style={{ marginRight: 4 }}>감성</div>
                <Icons
                  icons={
                    iconList.filter((item) => item.type === "감성")[0]?.icons
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ fontWeight: 600 }}>능력표현방식</span>
          <div
            style={{
              display: "flex",
              marginTop: 4,
              fontSize: 12,
              alignItems: "center",
            }}
          >
            <Icons
              icons={iconList.filter((item) => item.type === "기술")[0]?.icons}
            />
            <div style={{ margin: "0 10px 0 2px" }}>기술</div>
            <div style={{ marginRight: 4 }}>설계</div>
            <Icons
              icons={iconList.filter((item) => item.type === "설계")[0]?.icons}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
