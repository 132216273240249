import { useNavigate } from "react-router-dom";
import { colors } from "../styles/colors";
import { useUser } from "../hooks/useUser";
import useScreenSize from "../hooks/useScreenSize";
import PageTitle from "../components/pageTitle";
import Question from "../components/question";
import { useEvaluation } from "../hooks/useEvaluation";
import {
  FORM_TYPE_ADULT,
  FORM_TYPE_STUDENT,
  FORM_TYPE_YOUTH,
  FORM_TYPE_CHILD,
  FORM_TYPE_BABY,
} from "../utils/consts";
import { useRef } from "react";

export default function EvaluationForm() {
  const navigate = useNavigate();
  const { width, maxWidth } = useScreenSize();
  const { evaluationFormType } = useUser();
  const { testQuestion, setTestQuestion } = useEvaluation();
  const questionRefs = useRef([]);
  const evaluationFormDesc = {
    [FORM_TYPE_ADULT]: (
      <span>
        개인의 성격과 기질을 기반으로 한 다면적 행동 유형 분석 프로그램인
        크라타행동유형분석지는 조직행동론의 이론을 바탕으로 개인행동, 집단행동,
        조직행동 세 가지로 행동을 분류하고, 개인의 욕구를 분석하여 효과적인
        생산성과 현명한 소비를 할 수 있는 욕구를 분석한 분석지이다. 개인행동의
        10가지유형과 집단방식의 의사결정방식&자기방어방식 조직행동의
        정보처리방식,능력표현방식,목표실행방식,목적 성취방식으로 분류하여 자신의
        기본적인 행동방식과 자가분석을 통한 역량분석을 직관적으로 나타낸
        분석지이다. 환경적 욕구와 심리적 욕구의 만족에 필요한 요소를 찾아주어
        행동수정의 동기부여의 분석하는 다면적 행동유형검사지이다.
        <br />
        <br />
        <br />
        <span>대상</span> | 대학생 및 성인
        <br />
        <span>소요시간</span> | 약 20~25분
        <br />
        <span>문항수</span> | 100문항
        <br />
        <br />
        <br />
        소개
        <br />
        현장지식 데이터를 기반으로 한 크라타분석지는 사람의 행동패턴을 분석하여
        스스로 세운 목적과 목표를 이룰 수 있도록 돕는 분 석지입니다. 다면적
        행동유형 분석지인 크라타행동유형분석은 경영학의 조직행동론의 이론을
        기반하고 있습니다. 행동을 크게 세 가지로 분류하여 자기 이해를 돕고
        타인의 이해와 팀에 대한 이해 조직에 대한 이해를 도와 조직의 성과를
        높이기 위한 컨설팅과 솔루션 그리고 코칭이 기본입니다. 개인행동,
        집단행동, 조직행동으로 분류하여 각 상황에 맞는 행동패턴을 분석합니다. 본
        검사는 개인 및 단체로 검사가 가능하며, 검사를 통해 조직성과와 개인성장
        모두를 높이기 위한 솔루션을 제공한다. 성과의 방해되는 요인을 분석하고
        개인의 강점이 발휘 될 수 있는 직무나 환경을 분석하여 인적자원 개발을
        위한 자료로 사용된다.
      </span>
    ),
    [FORM_TYPE_STUDENT]: (
      <span>
        청소년자기보고검사서
        <br />
        다음 문항은 평상시에 자신이 행동하는 방식에 대한 질문입니다. 본
        질문지에서 자신의 행동을 가장 잘 표현한 것을 빠르게 선택하여 답하시면
        됩니다.
        <br />
        <br />
        <br />
        대상 | 중3학년~고3학년에 해당하는 청소년
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        <br />
        소개
        <br />
        기질적 성격에 의한 행동방식, 생존을 위한 행동방식, 정서적 욕구를
        파악함으로써 학습자의 자신의 효과적인 학습방법과 학교생활의 교우 간의
        상호 이해를 돕기 위한 검사이다. 경쟁 관계에서 학업 성과의 솔루션을
        제공한다. 본 검사는 성격 5 이론의 취약점인 성격의 내면적 구조나 역동의
        동기를 칼 융의 분석 심리학 이론과 성격유형 이론으로 보완한 실용적인
        검사이다. 개인 및 단체로 검사가 가능하며, 검사를 통해 상담, 학업 코칭,
        진로 계발, 교우관계 및 학업성취를 위한 솔루션, 학업 스트레스의 원인과
        갈등 해결, 진로나 학업방식및 디브리핑 워크숍 등의 다양한 상황에서 필요한
        정보를 제공받 을 수 있다.
      </span>
    ),
    [FORM_TYPE_YOUTH]: (
      <sapn>
        아동관찰검사서
        <br />
        다음 문항은 평상시에 자신이 행동하는 방식에 대한 질문입니다. 본
        질문지에서 자신의 행동을 가장 잘 표현한 것을 빠르게 선택하여 답하세요.
        <br />
        <br />
        대상 | 초등4학년~중2학년에 해당하는 아동
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        소개
        <br />
        현장지식 데이터를 기반으로 한 크라타분석지는 사람의 행동패턴을 분석하여
        스스로 세운 목적과 목표를 이룰 수 있도록 돕는 분 석지입니다. 다면적
        행동유형 분석지인 크라타행동유형분석은 경영학의 조직행동론의 이론을
        기반하고 있습니다. 행동을 크게 세 가지로 분류하여 자기 이해를 돕고
        타인의 이해와 팀에 대한 이해 조직에 대한 이해를 도와 조직의 성과를
        높이기 위한 컨설팅과 솔루션 그리고 코칭이 기본입니다. 개인행동,
        집단행동, 조직행동으로 분류하여 각 상황에 맞는 행동패턴을 분석합니다. 본
        검사는 개인 및 단체로 검사가 가능하며, 검사를 통해 조직성과와 개인성장
        모두를 높이기 위한 솔루션을 제공한다. 성과의 방해되는 요인을 분석하고
        개인의 강점이 발휘 될 수 있는 직무나 환경을 분석하여 인적자원 개발을
        위한 자료로 사용된다.
      </sapn>
    ),
    [FORM_TYPE_CHILD]: (
      <sapn>
        아동관찰검사서
        <br />
        다음 문항은 평상시에 귀하의 자녀가 부모에게 보이는 행동에 관한
        질문입니다. 각 문항을 본 질문지에서 묻는 자녀의 행동을 가장 잘 표현한
        것을 직관적으로 답하시면 됩니다.
        <br />
        <br />
        대상 | 아동(만5세~초3)의 부모님
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        소개
        <br />
        현장지식 데이터를 기반으로 한 크라타분석지는 사람의 행동패턴을 분석하여
        스스로 세운 목적과 목표를 이룰 수 있도록 돕는 분 석지입니다. 다면적
        행동유형 분석지인 크라타행동유형분석은 경영학의 조직행동론의 이론을
        기반하고 있습니다. 행동을 크게 세 가지로 분류하여 자기 이해를 돕고
        타인의 이해와 팀에 대한 이해 조직에 대한 이해를 도와 조직의 성과를
        높이기 위한 컨설팅과 솔루션 그리고 코칭이 기본입니다. 개인행동,
        집단행동, 조직행동으로 분류하여 각 상황에 맞는 행동패턴을 분석합니다. 본
        검사는 개인 및 단체로 검사가 가능하며, 검사를 통해 조직성과와 개인성장
        모두를 높이기 위한 솔루션을 제공한다. 성과의 방해되는 요인을 분석하고
        개인의 강점이 발휘 될 수 있는 직무나 환경을 분석하여 인적자원 개발을
        위한 자료로 사용된다.
      </sapn>
    ),
    [FORM_TYPE_BABY]: (
      <sapn>
        영유아 관찰검사서
        <br />
        다음 문항은 평상시에 귀하의 자녀가 부모에게 보이는 행동에 관한
        질문입니다. 각 문항을 본 질문지에서 묻는 자녀의 행동을 가장 잘 표현한
        것을 직관적으로 답하시면 됩니다.
        <br />
        <br />
        대상 | 유아(만3세~만4세)의 부모님
        <br />
        소요시간 | 약 20~25분
        <br />
        문항 수 | 100문항
        <br />
        <br />
        소개
        <br />
        현장지식 데이터를 기반으로 한 크라타분석지는 사람의 행동패턴을 분석하여
        스스로 세운 목적과 목표를 이룰 수 있도록 돕는 분 석지입니다. 다면적
        행동유형 분석지인 크라타행동유형분석은 경영학의 조직행동론의 이론을
        기반하고 있습니다. 행동을 크게 세 가지로 분류하여 자기 이해를 돕고
        타인의 이해와 팀에 대한 이해 조직에 대한 이해를 도와 조직의 성과를
        높이기 위한 컨설팅과 솔루션 그리고 코칭이 기본입니다. 개인행동,
        집단행동, 조직행동으로 분류하여 각 상황에 맞는 행동패턴을 분석합니다. 본
        검사는 개인 및 단체로 검사가 가능하며, 검사를 통해 조직성과와 개인성장
        모두를 높이기 위한 솔루션을 제공한다. 성과의 방해되는 요인을 분석하고
        개인의 강점이 발휘 될 수 있는 직무나 환경을 분석하여 인적자원 개발을
        위한 자료로 사용된다.
      </sapn>
    ),
  };

  const handleAnswer = ({ index, value, parentIndex }) => {
    let tempArr = JSON.parse(JSON.stringify(testQuestion));
    tempArr[parentIndex][index].answer = value;
    setTestQuestion(tempArr);
  };
  // const onSubmit = () => {
  //   const hasEmptyAnswer = testQuestion
  //     ?.flatMap((group) => group)
  //     ?.some((question) => question.answer === "");
  //   navigate("/result");
  // };
  const onSubmit = () => {
    const firstEmptyIndex = testQuestion
      ?.flatMap((group, groupIndex) =>
        group.map((question, questionIndex) => ({
          groupIndex,
          questionIndex,
          isEmpty: question.answer === "",
        }))
      )
      .find((q) => q.isEmpty);

    if (firstEmptyIndex) {
      alert("모든 질문에 답변을 입력하세요.");

      const firstEmptyElement = document.getElementById(
        `question-${firstEmptyIndex.groupIndex}-${firstEmptyIndex.questionIndex}`
      );

      if (firstEmptyElement) {
        firstEmptyElement.scrollIntoView({ block: "center" });

        setTimeout(() => {
          const firstRadio = firstEmptyElement.querySelector(
            "input[type='radio']"
          );
          if (firstRadio) {
            firstRadio.focus();
          }
        }, 50);
      }

      return;
    }

    window.scrollTo(0, 0);
    navigate("/result");
  };

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width, maxWidth, height: "100%", marginTop: 60 }}>
        <PageTitle title="진단" />
        <div
          style={{
            backgroundColor: colors.tableBackground,
            marginTop: 20,
            width: "100%",
            padding: 20,
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              border: `1px solid ${colors.lightGray}`,
              padding: 20,
              borderRadius: 8,
              fontWeight: 500,
              lineHeight: 1.25,
            }}
          >
            {evaluationFormDesc[evaluationFormType]}
          </div>
        </div>
        {testQuestion?.map((item, index) => (
          <div key={index} ref={(el) => (questionRefs.current[index] = el)}>
            <Question
              data={item}
              parentIndex={index}
              onClick={handleAnswer}
              part={index + 1}
            />
          </div>
        ))}
        <div
          style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <button
            onClick={onSubmit}
            type="submit"
            style={{
              background: "none",
              border: "none",
              font: "inherit",
              cursor: "pointer",
              width: 238,
              backgroundColor: colors.navy,
              color: "#fff",
              fontWeight: 700,
              borderRadius: 8,
              height: 36,
              margin: "60px 0",
            }}
          >
            완료
          </button>
        </div>
      </div>
    </div>
  );
}
