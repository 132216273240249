import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import moment from "moment";
import { colors } from "../styles/colors";
import Icon from "../components/icon";
import {
  FORM_TYPE_ADULT,
  FORM_TYPE_BABY,
  FORM_TYPE_CHILD,
  FORM_TYPE_STUDENT,
} from "../utils/consts";

const Input = (props) => {
  return (
    <input
      {...props}
      style={{
        borderRadius: 8,
        padding: 8,
        border: `1px solid ${colors.navy}`,
        marginTop: 4,
        width: 220,
        height: 18,
        fontSize: 16,
        fontFamily: "NanumSquare",
        outline: "none",
        boxShadow: "none",
        WebkitBoxShadow: "none",
        MozBoxShadow: "none",
        MsBoxShadow: "none",
        appearance: "none",
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        backgroundColor: "#fff",
        color: "#000",
        textAlign: "left",
      }}
    />
  );
};

const Title = ({ children, style }) => {
  return <div style={{ fontWeight: 700, ...style }}>{children}</div>;
};

const InputTitle = ({ children }) => {
  return <div style={{ fontSize: 14, marginTop: 12 }}>{children}</div>;
};

const Select = ({ children, value, ...rest }) => {
  return (
    <div
      style={{
        borderRadius: 8,
        padding: 1,
        border: `1px solid ${colors.navy}`,
        marginTop: 4,
        width: 238,
        height: 36,
        fontSize: 14,
        fontFamily: "NanumSquare",
        position: "relative",
        boxSizing: "border-box",
      }}
    >
      <select
        {...rest}
        style={{
          border: "none",
          fontSize: 16,
          width: "100%",
          color: value ? "#000" : "#a9a9a9",
          outline: "none",
          boxShadow: "none",
          WebkitBoxShadow: "none",
          MozBoxShadow: "none",
          MsBoxShadow: "none",
          appearance: "none",
          WebkitAppearance: "none",
          MozAppearance: "none",
          backgroundColor: "transparent",
          padding: 8,
          borderRadius: 8,
        }}
      >
        {children}
      </select>
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: "12px",
          transform: "translateY(-50%) rotate(180deg)",
          pointerEvents: "none",
        }}
      >
        <Icon iconName={"Select"} iconSize={12} style={{ marginRight: 2 }} />
      </div>
    </div>
  );
};
export default function Login() {
  const {
    userName,
    setUserName,
    userType,
    setUserType,
    customUserType,
    setCustomUserType,
    evaluationFormType,
    setEvaluationFormType,
    userBirthday,
    setUserBirthday,
    gender,
    setGender,
    tester,
    setTester,
    customTester,
    setCustomTester,
  } = useUser();
  const navigate = useNavigate();
  const [inputOpacity, setInputOpacity] = useState(0);
  const [isAgreed, setIsAgreed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (
        userName &&
        userType &&
        evaluationFormType &&
        userBirthday &&
        gender &&
        tester
      ) {
      } else throw new Error("값을 모두 입력하세요.");
      if (!isAgreed)
        throw new Error("동의하지 않으시는 경우 저작물의 이용이 불가능합니다.");
      else navigate("/form");
    } catch (e) {
      alert(e.message);
    }
  };

  useEffect(() => {
    setCustomUserType("");
  }, [userType]);

  useEffect(() => {
    setCustomTester("");
  }, [tester]);

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "40px 16px 0",
          width: "100%",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Title>개인정보</Title>
          <div>
            <InputTitle>대상자 이름</InputTitle>
            <Input
              type="text"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              placeholder="이름을 입력해주세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <InputTitle>개인고유번호</InputTitle>
            <div style={{ opacity: inputOpacity, zIndex: 99 }}>
              <Input
                type="date"
                onChange={(e) => {
                  setUserBirthday(e.target.value);
                }}
                value={userBirthday}
                onFocus={() => setInputOpacity(1)}
                onBlur={() => {
                  if (!userBirthday) setInputOpacity(0);

                  if (
                    !moment(userBirthday).isBetween("1919-01-01", "2100-12-06")
                  ) {
                    setUserBirthday("");
                    alert(
                      "1919.01.01 ~ 2100.12.06 사이의 날짜를 입력해주세요."
                    );
                  }
                }}
                max={"2100-12-06"}
                min={"1919-01-01"}
              />
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                pointerEvents: "none",
                color: "#a9a9a9",
                opacity: 1 - inputOpacity,
                width: 220,
                backgroundColor: "#fff",
                border: `1px solid ${colors.navy}`,
                borderRadius: 8,
                padding: 8,
              }}
            >
              생년월일을 입력해주세요
            </div>
          </div>
          <div>
            <InputTitle>규준 집단</InputTitle>
            <Select
              onChange={(e) => setUserType(e.target.value)}
              value={userType}
              defaultValue=""
            >
              <option value="" hidden>
                규준집단을 선택해주세요
              </option>
              <option value={"성인"}>성인</option>
              <option value={"대학생"}>대학생</option>
              <option value={"고등학생"}>고등학생</option>
              <option value={"중학생"}>중학생</option>
              <option value={"초등학생"}>초등학생</option>
              <option value={"영유아"}>영유아</option>
              <option value={"기타(직접입력)"}>기타(직접입력)</option>
            </Select>
          </div>
          {userType === "기타(직접입력)" && (
            <Input
              value={customUserType}
              onChange={(e) => setCustomUserType(e.target.value)}
            />
          )}
          <div>
            <InputTitle>성별</InputTitle>
            <div style={{ display: "flex", fontSize: 14 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="MALE"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "MALE"}
                  style={{
                    accentColor: colors.navy,
                    width: 16,
                    height: 16,
                    margin: "4px 4px 4px 0",
                  }}
                />
                <label htmlFor="male">남</label>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="FEMALE"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "FEMALE"}
                  style={{
                    accentColor: colors.navy,
                    width: 16,
                    height: 16,
                    margin: "4px 4px 4px 16px",
                  }}
                />
                <label htmlFor="female">여</label>
              </div>
            </div>
          </div>
          <Title style={{ marginTop: 24 }}>진단정보</Title>
          <InputTitle>진단지</InputTitle>
          <Select
            onChange={(e) => setEvaluationFormType(e.target.value)}
            value={evaluationFormType}
            defaultValue=""
            // defaultValue={FORM_TYPE_ADULT}
          >
            <option value="" hidden>
              진단지를 선택해주세요
            </option>
            <option value={FORM_TYPE_ADULT}>{FORM_TYPE_ADULT}</option>
            {/* <option value={FORM_TYPE_STUDENT}>{FORM_TYPE_STUDENT}</option> */}
            <option value={FORM_TYPE_CHILD}>{FORM_TYPE_CHILD}</option>
            <option value={FORM_TYPE_BABY}>{FORM_TYPE_BABY}</option>
          </Select>
          <div>
            <InputTitle>상담사</InputTitle>
            <Select
              onChange={(e) => setTester(e.target.value)}
              value={tester}
              defaultValue=""
            >
              <option value="" hidden>
                상담사를 선택해주세요
              </option>
              <option value={"염청하"}>염청하</option>
              <option value={"기타(직접입력)"}>기타(직접입력)</option>
            </Select>
            {tester === "기타(직접입력)" && (
              <Input
                value={customTester}
                onChange={(e) => setCustomTester(e.target.value)}
              />
            )}
          </div>
          <div
            style={{
              width: 250,
              fontSize: 13,
              marginTop: 16,
              lineHeight: 1.25,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <span>
              <span style={{ color: colors.navy }}>(필수)</span> 개인정보
              수집·이용 동의서(크라타 진단)
              <br />
              <br />
              CRATA LAB은 '개인정보 보호법'에 따라 본인의 동의를 얻어 개인
              맞춤형 서비스의 제공을 위해 개인정보를 수집·이용합니다.
              <br />
              1. 개인식별 및 서비스제공 -수집항목: 성함, 생년월일, 태어난 시간,
              성별, 상담내용,주소
              <br />
              2. 고객상담 -수집항목: 전화번호
              <br />
              3. 서비스 변경 및 고지사항 전달 -수집항목: 전화번호
              <br />
              4. 보유·이용기간 -회원 수신 철회요구 시
              <br />
              <br />
              개인정보의 수집·이용에 관한 동의는 저작물(CRATA)의 DB 구축과
              저작물의 전송·출력에 필수적이므로, 위 사항에 동의하셔야만 저작물을
              이용할 수 있습니다.
              <br />
              귀하는 개인 정보의 수집·이용에 관한 동의에 거부하실 수 있으며, 단
              동의하지 않으시는 경우 저작물의 이용이 불가능합니다. 개인정보
              제공에 동의합니다.
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 14,
              }}
            >
              <input
                id="agree"
                type="checkbox"
                style={{ accentColor: colors.navy }}
                checked={isAgreed}
                onChange={() => setIsAgreed((prev) => !prev)}
              />
              <label htmlFor="agree">동의합니다.</label>
            </div>
          </div>
          <button
            type="submit"
            style={{
              background: "none",
              border: "none",
              font: "inherit",
              cursor: "pointer",
              width: 238,
              backgroundColor: colors.navy,
              color: "#fff",
              fontWeight: 700,
              borderRadius: 8,
              height: 36,
              marginTop: 60,
              marginBottom: 40,
            }}
          >
            완료
          </button>
        </form>
      </div>
    </div>
  );
}
