export const MAX_SCREEN_WIDTH = 720;
export const PDF_PADDING = 60;
export const DATE_FORMAT = "YYYY.MM.DD.";
export const BE_DATE_FORMAT = "YYYY.MM.DD.";
export const FORM_TYPE_ADULT = "대학생&성인용";
export const FORM_TYPE_STUDENT = "중 · 고등학생용";
export const FORM_TYPE_CHILD = "초등저학년용";
export const FORM_TYPE_BABY = "영유아용";
export const TYPE_MAP = {
  정인: { alphabet: "I", kr: "직관형", en: "ntutive type" },
  편인: { alphabet: "E", kr: "경험형", en: "xperiential type" },
  상관: { alphabet: "D", kr: "설계형", en: "esign type" },
  식신: { alphabet: "T", kr: "기술형", en: "echnical type" },
  정재: { alphabet: "T", kr: "전술형", en: "actical type" },
  편재: { alphabet: "S", kr: "전략형", en: "trategic type" },
  정관: { alphabet: "T", kr: "과제형", en: "ask type" },
  편관: { alphabet: "R", kr: "관계형", en: "elational type" },
  비견: { alphabet: "L", kr: "논리형", en: "ogical type" },
  겁재: { alphabet: "E", kr: "감성형", en: "motional type" },
};
export const PERSON_TYPE_MAP = {
  IG: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 자기중심적인 기질을 가지고 있어서 자신의 발전을 위해 배우고 성장하는 것을 좋아하여 대리만족이 안 된다. 새로운 것을 도전하는 힘과 시작하는 추진력이 강하다. 경쟁심이 강하고 자존심이 강하다.",
  EG: "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 자기중심적인 기질을 가지고 있어서 자신의 발전을 위해 배우고 성장하는 것을 좋아하고 주변에 인정받고자하는 욕구가 강하고 대리만족은 어렵다. 주변사람들에게 영향을 많이 받는다.",
  ID: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 자신이 속한 조직과 주변을 성장시키는 기질을 가지고 있어서 자신의 가치를 인정받는 것을 위해 정보와 지식을 나누고 그것으로 도움을 주는 것을 좋아하고 대리만족이 가능하다. 성장하고 키워야하는 조직에서 능력발휘가 뛰어나다.",
  ED: "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 자신이 속한 조직과 주변을 성장시키는 기질을 가지고 있어서 자신의 가치를 인정받는 것을 위해 정보와 지식을 나누고 그것으로 도움을 주는 것을 좋아하고 대리만족이 가능하다. 어려움이나 개척이 필요로 하는 조직에서 능력발휘가 뛰어나다.",
  IB: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 한쪽으로 치우치지 않는 객관적인 태도로 스스로 2인자로 돕는 역할을 한다. 자신의 말의 신뢰를 받고 싶어 하고 중간자로 서로를 연결하고 성장을 돕는 능력이 뛰어나다.",
  EB: "자신에게 주어진 상황이 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 관계를 중요하게 생각하고 함께 시너지를 내는 협업을 이끌어 낸다. 서로간의 다리 역할을 하면서 2인자로 자신이 드러나는 것을 좋아하지 않는다.",
  IH: "자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 결과중심적이고 명확하고 확실한 것을 선호한다. 현실적으로 눈으로 보이는 것을 중요하게 여기고 문제해결 능력이 뛰어나서 자신이 잘하는 것을 찾아 현실적으로 활용하는 능력이 뛰어나다.",
  EH: "자신에게 주어진 상황을 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 답이 확실하고 현실적인 문제를 적극적으로 해결하고 답을 찾는 능력이 뛰어나서 해결사 역할을 한다. 현실에서 자신의 능력을 최대한 발휘할 수 있는 상황과 기회를 만들어 낸다.",
  IA: "자신의 마음이 우선이라 자신의 마음이 우선이라 자신의 마음이 흐르는 대로 행동한다. 자신의 준비가 우선이기 때문에 자기의 스타일과 속도대로 움직이기 때문에 이기적으로 나타나기도 한다. 자신이 아는 것을 창의적이고 감각적으로 활용하는 능력이 뛰어나서 대기만성형이다.",
  EA: "자신에게 주어진 상황이 우선으로 자신의 마음보다는 상황에 맞춰 행동하거나 선택의 기준이 된다. 자신의 준비가 되어야 밖으로 나타내지는데 자기의 스타일과 속도를 이해하고 움직여야 자신의 능력이 나온다. 천천히 드러내기 때문에 처음에는 수동적으로 보일 수 있지만 점차 자신이 원하는 것이 무엇인지 찾는 인생을 자신을 찾는 여정으로 여긴다.",
};
